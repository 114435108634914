import React from "react";
import "./IntercityLink.css";
import { Link } from "react-router-dom";

const IntercityLink = () => {
  const data = {
    OtherTaxi: {
      id: 10,
      title: "Other Taxi Services",
      links: [
        {
          name: "Taxi service in New Delhi",
          src: "/cab/delhi-city-cabs",
        },
        {
          name: "Taxi service in Ghaziabad",
          src: "/cab/ghaziabad-city-cabs",
        },
        {
          name: "Taxi service in Noida",
          src: "/cab/noida-city-cabs",
        },
        {
          name: "Taxi service in Bahadurgarh",
          src: "/cab/bahadurgarh-city-cabs",
        },
        {
          name: "Taxi service in Bagpat",
          src: "/cab/bagpat-city-cabs",
        },
        {
          name: "Taxi service in Baghpat",
          src: "/cab/baghpat-city-cabs",
        },
        {
          name: "Taxi service in Gurgaon",
          src: "/cab/gurgaon-city-cabs",
        },
        {
          name: "Taxi service in Gurugram",
          src: "/cab/gurugram-city-cabs",
        },
        {
          name: "Taxi service in Faridabad",
          src: "/cab/faridabad-city-cabs",
        },
        {
          name: "Taxi service in Basai",
          src: "/cab/basai-city-cabs",
        },
        {
          name: "Taxi service in Dadri",
          src: "/cab/dadri-city-cabs",
        },
        {
          name: "Taxi service in Greater Noida",
          src: "/cab/greater-noida-city-cabs",
        },
        {
          name: "Taxi service in Kharkhoda",
          src: "/cab/kharkhoda-city-cabs",
        },
        {
          name: "Taxi service in Modinagar",
          src: "/cab/modinagar-city-cabs",
        },
        {
          name: "Taxi service in Sonipat",
          src: "/cab/sonipat-city-cabs",
        },
        {
          name: "Taxi service in Murthal",
          src: "/cab/murthal-city-cabs",
        },
        {
          name: "Taxi service in Manesar",
          src: "/cab/manesar-city-cabs",
        },
        { name: "Taxi service in Barau", src: "/cab/barau-city-cabs" },
        { name: "Taxi service in Baraut", src: "/cab/baraut-city-cabs" },
        {
          name: "Taxi service in Gautam Buddha Nagar",
          src: "/cab/gautam-buddha-nagar-city-cabs",
        },
        {
          name: "Taxi service in Shamshabad",
          src: "/cab/shamshabad-city-cabs",
        },
        { name: "Taxi service in Kagarol", src: "/cab/kagarol-city-cabs" },
        { name: "Taxi service in Sadabad", src: "/cab/sadabad-city-cabs" },
        { name: "Taxi service in Bisawar", src: "/cab/bisawar-city-cabs" },
        { name: "Taxi service in Tundla", src: "/cab/tundla-city-cabs" },
        { name: "Taxi service in Rajakhera", src: "/cab/rajakhera-city-cabs" },
        {
          name: "Taxi service in Fatehpur Sikri",
          src: "/cab/fatehpur-sikri-city-cabs",
        },
        { name: "Taxi service in Firozabad", src: "/cab/firozabad-city-cabs" },
        { name: "Taxi service in Jalesar", src: "/cab/jalesar-city-cabs" },
        { name: "Taxi service in Hathras", src: "/cab/hathras-city-cabs" },
        { name: "Taxi service in Mathura", src: "/cab/mathura-city-cabs" },
        { name: "Taxi service in Bharatpur", src: "/cab/bharatpur-city-cabs" },
        { name: "Taxi service in Dholpur", src: "/cab/dholpur-city-cabs" },
        { name: "Taxi service in Vrindavan", src: "/cab/vrindavan-city-cabs" },
        { name: "Taxi service in Ambah", src: "/cab/ambah-city-cabs" },
        {
          name: "Taxi service in Shikohabad",
          src: "/cab/shikohabad-city-cabs",
        },
        { name: "Taxi service in Holipura", src: "/cab/holipura-city-cabs" },
        { name: "Taxi service in Sasni", src: "/cab/sasni-city-cabs" },
        { name: "Taxi service in Iglas", src: "/cab/iglas-city-cabs" },
        { name: "Taxi service in Govardhan", src: "/cab/govardhan-city-cabs" },
        {
          name: "Taxi service in Shekhawati",
          src: "/cab/shekhawati-city-cabs",
        },
        { name: "Taxi service in Kukas", src: "/cab/kukas-city-cabs" },
        {
          name: "Taxi service in Bhambhoria",
          src: "/cab/bhambhoria-city-cabs",
        },
        { name: "Taxi service in Begas", src: "/cab/begas-city-cabs" },
        { name: "Taxi service in Bassi", src: "/cab/bassi-city-cabs" },
        { name: "Taxi service in Achrol", src: "/cab/achrol-city-cabs" },
        { name: "Taxi service in Chomu", src: "/cab/chomu-city-cabs" },
        {
          name: "Taxi service in Asalpur Jobner",
          src: "/cab/asalpur-jobner-city-cabs",
        },
        { name: "Taxi service in Chaksu", src: "/cab/chaksu-city-cabs" },
        { name: "Taxi service in Pawta", src: "/cab/pawta-city-cabs" },
        { name: "Taxi service in Jobner", src: "/cab/jobner-city-cabs" },
        {
          name: "Taxi service in Alila Fort Bishangarh",
          src: "/cab/alila-fort-bishangarh-city-cabs",
        },
        {
          name: "Taxi service in Mojamabaad",
          src: "/cab/mojamabaad-city-cabs",
        },
        { name: "Taxi service in Renwal", src: "/cab/renwal-city-cabs" },
        {
          name: "Taxi service in Mehandipur Balaji",
          src: "/cab/mehandipur-balaji-city-cabs",
        },
        { name: "Taxi service in Dausa", src: "/cab/dausa-city-cabs" },
        {
          name: "Taxi service in Virat Nagar",
          src: "/cab/virat-nagar-city-cabs",
        },
        { name: "Taxi service in Shahpura", src: "/cab/shahpura-city-cabs" },
        { name: "Taxi service in Ringas", src: "/cab/ringas-city-cabs" },
        {
          name: "Taxi service in Khatu Shyam",
          src: "/cab/khatu-shyam-city-cabs",
        },
        {
          name: "Taxi service in Pimpri Chinchwad",
          src: "/cab/pimpri-chinchwad-city-cabs",
        },
        {
          name: "Taxi service in Induri",
          src: "/cab/induri-city-cabs",
        },
        {
          name: "Taxi service in Talegaon",
          src: "/cab/talegaon-city-cabs",
        },
        {
          name: "Taxi service in Shikrapur",
          src: "/cab/shikrapur-city-cabs",
        },
        {
          name: "Taxi service in Lavasa",
          src: "/cab/lavasa-city-cabs",
        },
        {
          name: "Taxi service in Bhor",
          src: "/cab/bhor-city-cabs",
        },
        {
          name: "Taxi service in Jejuri",
          src: "/cab/jejuri-city-cabs",
        },
        {
          name: "Taxi service in Thane",
          src: "/cab/thane-city-cabs",
        },
        {
          name: "Taxi service in Shirur",
          src: "/cab/shirur-city-cabs",
        },
        {
          name: "Taxi service in Panvel",
          src: "/cab/panvel-city-cabs",
        },
        {
          name: "Taxi service in Panchgani",
          src: "/cab/panchgani-city-cabs",
        },
        {
          name: "Taxi service in New Panvel",
          src: "/cab/new-panvel-city-cabs",
        },
        {
          name: "Taxi service in Navi Mumbai",
          src: "/cab/navi-mumbai-city-cabs",
        },
        {
          name: "Taxi service in Mira Bhayandar",
          src: "/cab/mira-bhayandar-city-cabs",
        },
        {
          name: "Taxi service in Imagica",
          src: "/cab/imagica-city-cabs",
        },
        {
          name: "Taxi service in Dombivli",
          src: "/cab/dombivli-city-cabs",
        },
        {
          name: "Taxi service in Andheri",
          src: "/cab/andheri-city-cabs",
        },
        {
          name: "Taxi service in Shirwal",
          src: "/cab/shirwal-city-cabs",
        },
        {
          name: "Taxi service in Aamby Valley",
          src: "/cab/aamby-valley-city-cabs",
        },
        {
          name: "Taxi service in Raigad",
          src: "/cab/raigad-city-cabs",
        },
        {
          name: "Taxi service in Lonavala",
          src: "/cab/lonavala-city-cabs",
        },
        {
          name: "Taxi service in Manchar",
          src: "/cab/manchar-city-cabs",
        },
        {
          name: "Taxi service in Khandala",
          src: "/cab/khandala-city-cabs",
        },
        {
          name: "Taxi service in Kedagaon",
          src: "/cab/kedagaon-city-cabs",
        },
        {
          name: "Taxi service in Imagica Adlab",
          src: "/cab/imagica-adlab-city-cabs",
        },
        {
          name: "Taxi service in Khopoli",
          src: "/cab/khopoli-city-cabs",
        },
        {
          name: "Taxi service in Shirur",
          src: "/cab/shirur-city-cabs",
        },
        {
          name: "Taxi service in Imagica",
          src: "/cab/imagica-city-cabs",
        },
        {
          name: "Taxi service in Panchgani",
          src: "/cab/panchgani-city-cabs",
        },
        {
          name: "Taxi service in Pawna Lake",
          src: "/cab/pawna-lake-city-cabs",
        },
        {
          name: "Taxi service in Bhiwandi",
          src: "/cab/bhiwandi-city-cabs",
        },
        {
          name: "Taxi service in Kalyan",
          src: "/cab/kalyan-city-cabs",
        },
        {
          name: "Taxi service in Ambernath",
          src: "/cab/ambernath-city-cabs",
        },
        {
          name: "Taxi service in Vasai ",
          src: "/cab/vasai-city-cabs",
        },
        {
          name: "Taxi service in Ltt ",
          src: "/cab/ltt-city-cabs",
        },
        { name: "Taxi service in Rupnagar", src: "/cab/rupnagar-city-cabs" },
        { name: "Taxi service in Hoskote", src: "/cab/hoskote-city-cabs" },
        {
          name: "Taxi service in Nelamangala",
          src: "/cab/nelamangala-city-cabs",
        },
        {
          name: "Taxi service in Bangalore Rural",
          src: "/cab/bangalore-rural-city-cabs",
        },
        { name: "Taxi service in Hosur", src: "/cab/hosur-city-cabs" },
        {
          name: "Taxi service in Doddaballapur",
          src: "/cab/doddaballapur-city-cabs",
        },
        { name: "Taxi service in Malur", src: "/cab/malur-city-cabs" },
        { name: "Taxi service in Magadi", src: "/cab/magadi-city-cabs" },
        {
          name: "Taxi service in Ramanagara",
          src: "/cab/ramanagara-city-cabs",
        },
        {
          name: "Taxi service in Nandi Hills",
          src: "/cab/nandi-hills-city-cabs",
        },
        {
          name: "Taxi service in Kanakapura",
          src: "/cab/kanakapura-city-cabs",
        },
        {
          name: "Taxi service in Chikkaballapur",
          src: "/cab/chikkaballapur-city-cabs",
        },
        {
          name: "Taxi service in Denkanikottai",
          src: "/cab/denkanikottai-city-cabs",
        },
        {
          name: "Taxi service in Channapatna",
          src: "/cab/channapatna-city-cabs",
        },
        {
          name: "Taxi service in Sidlaghatta",
          src: "/cab/sidlaghatta-city-cabs",
        },
        {
          name: "Taxi service in Shoolagiri",
          src: "/cab/shoolagiri-city-cabs",
        },
        { name: "Taxi service in Kodagu", src: "/cab/kodagu-city-cabs" },
        { name: "Taxi service in Kolar", src: "/cab/kolar-city-cabs" },
        { name: "Taxi service in Anna Road", src: "/cab/anna-road-city-cabs" },
        { name: "Taxi service in Ambattur", src: "/cab/ambattur-city-cabs" },
        { name: "Taxi service in Tambaram", src: "/cab/tambaram-city-cabs" },
        { name: "Taxi service in Minjur", src: "/cab/minjur-city-cabs" },
        { name: "Taxi service in Ponneri", src: "/cab/ponneri-city-cabs" },
        {
          name: "Taxi service in Sriperumbudur",
          src: "/cab/sriperumbudur-city-cabs",
        },
        {
          name: "Taxi service in Tiruvallur",
          src: "/cab/tiruvallur-city-cabs",
        },
        {
          name: "Taxi service in Gummidipoondi",
          src: "/cab/gummidipoondi-city-cabs",
        },
        {
          name: "Taxi service in Uthukottai",
          src: "/cab/uthukottai-city-cabs",
        },
        {
          name: "Taxi service in Mahabalipuram",
          src: "/cab/mahabalipuram-city-cabs",
        },
        {
          name: "Taxi service in Chengalpattu",
          src: "/cab/chengalpattu-city-cabs",
        },
        {
          name: "Taxi service in Chinglepet",
          src: "/cab/chinglepet-city-cabs",
        },
        {
          name: "Taxi service in Arambakkam",
          src: "/cab/arambakkam-city-cabs",
        },
        { name: "Taxi service in Arakkonam", src: "/cab/arakkonam-city-cabs" },
        {
          name: "Taxi service in Varadaiahpalem",
          src: "/cab/varadaiahpalem-city-cabs",
        },
        {
          name: "Taxi service in Kanchipuram",
          src: "/cab/kanchipuram-city-cabs",
        },
        { name: "Taxi service in Tiruttani", src: "/cab/tiruttani-city-cabs" },
        {
          name: "Taxi service in Sullurpeta",
          src: "/cab/sullurpeta-city-cabs",
        },
        {
          name: "Taxi service in Madurantakam",
          src: "/cab/madurantakam-city-cabs",
        },
        { name: "Taxi service in Dehradun", src: "" },
        { name: "Taxi service in Mussoorie", src: "/cab/mussoorie-city-cabs" },
        { name: "Taxi service in Dhanaulti", src: "/cab/dhanaulti-city-cabs" },
        { name: "Taxi service in Dhanolti", src: "/cab/dhanolti-city-cabs" },
        { name: "Taxi service in Kalsi", src: "/cab/kalsi-city-cabs" },
        {
          name: "Taxi service in Narendra Nagar",
          src: "/cab/narendra-nagar-city-cabs",
        },
        {
          name: "Taxi service in Vikasnagar",
          src: "/cab/vikasnagar-city-cabs",
        },
        { name: "Taxi service in Kanatal", src: "/cab/kanatal-city-cabs" },
        { name: "Taxi service in Rishikesh", src: "/cab/rishikesh-city-cabs" },
        { name: "Taxi service in New Tehri", src: "/cab/new-tehri-city-cabs" },
        { name: "Taxi service in Tehri", src: "/cab/tehri-city-cabs" },
        { name: "Taxi service in Neelkanth", src: "/cab/neelkanth-city-cabs" },
        { name: "Taxi service in Haridwar", src: "/cab/haridwar-city-cabs" },
        {
          name: "Taxi service in Paonta Sahib",
          src: "/cab/paonta-sahib-city-cabs",
        },
        {
          name: "Taxi service in Jwalapur",
          src: "/cab/jwalapur-city-cabs",
        },
        {
          name: "Taxi service in Chakrata",
          src: "/cab/chakrata-city-cabs",
        },
        {
          name: "Taxi service in Bhagwanpur",
          src: "/cab/bhagwanpur-city-cabs",
        },
        {
          name: "Taxi service in Roorkee",
          src: "/cab/roorkee-city-cabs",
        },
        {
          name: "Taxi service in Naugaon",
          src: "/cab/naugaon-city-cabs",
        },
        {
          name: "Taxi service in Uttarkashi",
          src: "/cab/uttarkashi-city-cabs",
        },
        {
          name: "Taxi service in Saharanpur",
          src: "/cab/saharanpur-city-cabs",
        },
      ],
    },
  };
  return (
    <section className="single-section small-section bg-inner">
      <div className="container">
        <div className="col-xl-12 col-lg-12">
          <div className="description-section tab-section">
            <div className="description-details">
              <div className="desc-box">
                <div className="outstation-grid">
                  {Object.values(data).map(({ id, title, links }) => (
                    <div key={id}>
                      <h2 className="heading">{title}</h2>
                      <div className="menu-part mt-0 about" id="highlight">
                        <div className="about-sec">
                          <ul className="destination">
                            {links.map((link) => (
                              <li key={link.src}>
                                <span className="arrow">
                                  <i className="fas fa-check"></i>
                                </span>
                                <Link className="link" to={link.src}>{link.name}</Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IntercityLink;
