export const agraTaxiData = {
  pageTitle: "Book Agra Airport Cabs",
  mainHeading: "Book Agra Airport Cabs on Sewak Travels",
  subHeading: "Enjoy Seamless Airport Pickup & Drop Services in Agra.",
  airportInfo: `
      Agra Airport, also known as Kheria Air Force Station, plays a crucial role in both civilian air travel and as a significant military base for the Indian Air Force.
      Established on 15th August 1947, this historic airbase has been a gateway to the iconic Taj Mahal for decades.
      Conveniently located just 8 km from Agra Railway Bridge and 10 km from the bus stand, Agra Airport provides easy access for tourists looking to explore the wonders of Agra.
    `,
  serviceInfo: `
      At Sewak Travels, we make your travel experience smoother with our reliable Agra Airport cab service.
      Whether you’re flying in for a quick visit or heading out, we ensure your journey to and from Agra Airport is stress-free and on time.
    `,
  distanceHeading: "Agra to Agra Airport Cab Distance",
  distanceInfo: `
      The distance from Agra Airport to the city center is approximately 8 km, depending on the route and any stops made.
      For a comfortable and stress-free journey, simply book an Agra Airport taxi through Sewak Travel's user-friendly online platform.
      Whether you're heading to or from the airport, we ensure a hassle-free experience, making your travel convenient and reliable.
      Skip the wait and book your airport taxi in Agra directly with Sewak Travels for a seamless ride!
    `,
  bookingHeading: "Online Agra Airport Cab Booking with Sewak Travels",
  bookingInfo: `
      Welcome to Sewak Travels, where we offer reliable, comfortable, and on-time Online Agra Airport cab booking tailored to your needs.
      Whether you’re arriving in or departing from Agra, our Agra Airport taxi service ensures a seamless travel experience from start to finish.
      With a fleet of modern vehicles and professional drivers, we provide the highest standard of airport cab services in Agra.
    `,
  img: "/assets/img/LocationDifferent/agra-airport.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book an Agra Cab?",
  whyChooseSubHeading:
    "Book your Agra Cabs effortlessly: When you choose Sewak for your cab service in Agra, you get:",
  whyChooseInfo: [
    {
      title: "24/7 Booking & Travel Assistance",
      detail:
        "Our Agra Airport taxi service operates 24/7, ensuring you can book a ride at any hour.",
    },
    {
      title: "Outstation Cabs",
      detail:
        "Need to travel outside Agra? We offer outstation cabs from Agra Airport, making us your ideal choice for longer journeys.",
    },
    {
      title: "Competitive Rates",
      detail:
        "Enjoy transparent pricing with no hidden charges. Our budget-friendly prices ensure the best cab service in Agra without compromising quality or comfort.",
    },
    {
      title: "Prepaid and Hassle-Free",
      detail:
        "Book your prepaid taxi Agra Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "Professional Drivers",
      detail:
        "Sewak's drivers are skilled professionals dedicated to passenger safety and comfort, with excellent navigation skills and local knowledge.",
    },
    {
      title: "Comfortable Fleet",
      detail:
        "Choose from a range of vehicles to suit your travel preferences, from economy to luxury options.",
    },
    {
      title: "Diverse Agra Taxi Options",
      detail:
        "We offer 4-seater sedans, 7-seater SUVs, and luxury 7-seater Prime SUVs for different travel needs. We also have options for Agra sharing cabs.",
    },
  ],
  howToBookHeading: "How to Book an Agra Airport Taxi?",
  howToBookInfo: `
      Sewak Travels takes the hassle out of booking an Agra Cab by offering premium services at affordable Agra taxi rates, complete with a pickup-drop facility and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit https://sewaktravels.com/, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, proceed to payment, and you're all set! Now that you're acquainted with our Agra taxi service, why wait any longer?
    `,
  taxiServicesHeading: "Various Taxi Service In Agra Airport by Sewak Travels",
  taxiServices: [
    {
      title: "Outstation Cabs from Agra Airport",
      description:
        "Sewak Travels offers reliable outstation cabs from Agra Airport, ensuring fast and comfortable rides for tourists and locals alike.",
    },
    {
      title: "Local Taxis from Agra Airport",
      description:
        "Need a ride within Agra? We make it easy to book local taxis from Agra Airport with just a few clicks.",
    },
    {
      title: "Corporate Airport Transfers",
      description:
        "We cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote.",
    },
  ],
  highlights: [
    {
      title: "Airport Taxi at Per Km Rates",
      detail:
        "Pay only for the distance you travel with our transparent pricing model.",
    },
    {
      title: "Prepaid Taxi Service",
      detail:
        "Avoid last-minute hassles by booking your prepaid taxi at Agra Airport with Sewak Travels.",
    },
    {
      title: "Taxi Service in Agra",
      detail:
        "We not only cover the airport but also provide reliable taxi services across Agra for sightseeing or city tours",
    },
  ],
  easyBookingHeading: "Easy Agra Airport Cab Booking for Every Need",
  easyBookingInfo: `
    Whether you’re visiting Agra for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Agra Airport taxi contact number and online platforms make it easy for you to book a cab within minutes.
  `,
  easyBookingPoints: [
    "- Affordable Agra Airport Taxi Fare",
    "- Quick & Reliable Airport Taxi Service",
    "- Car Rental Services for Agra Airport",
  ],
  bookingCallToAction: `
    Book your Agra Airport cab now with Sewak Travels and enjoy hassle-free, comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Agra Airport taxi booking. Let Sewak Travels make your airport commute effortless!
  `,
  thingsToKnowHeading: "Things To Know About Agra",
  thingsToKnowInfo: `
    Agra, located in Uttar Pradesh, India, is renowned worldwide for being home to the iconic Taj Mahal, one of the Seven Wonders of the World. This historic city, nestled along the banks of the Yamuna River, has a rich heritage dating back to the Mughal era. Agra was once the capital of the Mughal Empire and flourished under emperors like Akbar, Jahangir, and Shah Jahan, who built magnificent monuments that continue to attract millions of visitors. The Taj Mahal, a UNESCO World Heritage site, stands as a symbol of eternal love, built by Shah Jahan in memory of his wife Mumtaz Mahal. In addition to this, Agra boasts other architectural marvels like the Agra Fort, a grand fortress with intricate Mughal designs, and Fatehpur Sikri, a short drive from Agra, known for its majestic palaces and mosques. Agra’s vibrant culture reflects its historical significance, with traditional Mughlai cuisine and local craftsmanship, particularly marble inlay work, remaining popular. The city is well-connected by road, rail, and air, making it a must-visit destination for history enthusiasts and tourists eager to explore India’s rich cultural heritage.
  `,
  faqHeading: "Frequently Asked Questions About Agra Airport Cab Booking",
  faqs: [
    {
      question: "How can I book an Agra Airport cab?",
      answer:
        "You can book an Agra Airport taxi easily by visiting our website sewaktravels.com and selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Agra?",
      answer:
        "Sewak Travels provides intra-city cab transfers which allow users to book cabs to and from the Agra Airport in Agra.",
    },
    {
      question:
        "What types of vehicles do you offer for Agra Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Agra Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Agra Airport cabs?",
      answer:
        "Our Agra Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Absolutely! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question: "Can I book a cab for outstation travel from Agra Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Agra Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with your booking or any inquiries you may have.",
    },
    {
      question: "Do you offer local taxi services in Agra?",
      answer:
        "Yes, we provide local taxi services in Agra, perfect for sightseeing and city tours.",
    },
    {
      question: "How do I contact you for more information?",
      answer:
        "You can reach us at +91-837-782-8828 for any inquiries regarding our Agra Airport taxi service or bookings.",
    },
  ],
};
export const ahmedabadTaxiData = {
  pageTitle: "Book Ahmedabad Airport Cabs",
  mainHeading: "Book Ahmedabad Airport Cabs on Sewak Travels",
  subHeading: "Enjoy Seamless Airport Pickup & Drop Services in Ahmedabad.",
  airportInfo:
    "Sardar Vallabhbhai Patel International Airport (SVPIA), located in Ahmedabad, is one of the busiest airports in India. Opened in 1937, the airport is named after the country’s first Deputy Prime Minister and is a vital hub for both domestic and international travelers. Conveniently located just 15 km from the city center, it provides easy access for tourists looking to explore the vibrant culture, architecture, and history of Ahmedabad.",
  serviceInfo:
    "At Sewak Travels, we ensure your travel experience is smooth with our reliable Ahmedabad Airport cab service. Whether you’re arriving for a business trip or a leisurely visit, we guarantee a stress-free journey to and from Ahmedabad Airport.",
  distanceHeading: "Ahmedabad to Ahmedabad Airport Cab Distance",
  distanceInfo:
    "The distance from Ahmedabad Airport to the city center is approximately 15 km, varying based on your route and any stops along the way. For a comfortable and convenient journey, simply book an Ahmedabad Airport taxi through Sewak Travels' user-friendly online platform. Whether you're heading to or from the airport, we make your travel seamless and reliable. Book your airport taxi in Ahmedabad today for a hassle-free ride!",
  bookingHeading: "Online Ahmedabad Airport cab Booking with Sewak Travels",
  bookingInfo:
    "Welcome to Sewak Travels, where we offer dependable, comfortable, and timely Ahmedabad Airport cab services tailored to your travel needs. Whether you’re arriving in or departing from Ahmedabad, our Ahmedabad Airport taxi service ensures a smooth travel experience from start to finish. With a fleet of modern vehicles and professional drivers, we deliver the highest standard of airport cab services in Ahmedabad.",
  img: "/assets/img/LocationDifferent/ahmedabad-airport.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book an Ahmedabad Cab?",
  whyChooseSubHeading:
    "Book your Ahmedabad Cabs effortlessly: When you choose Sewak for your cab service in Ahmedabad, you get:",
  whyChooseInfo: [
    {
      title: "24/7 Booking & Travel Assistance:",
      detail: "Our Ahmedabad Airport taxi service operates around the clock",
    },
    {
      title: "Outstation Cabs:",
      detail:
        "Need to travel outside Ahmedabad? We offer outstation cabs from Ahmedabad Airport",
    },
    {
      title: "Competitive Rates:",
      detail: "With Ahmedabad Airport taxi per km rates",
    },
    {
      title: "Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi at Ahmedabad Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "Professional Drivers:",
      detail:
        "Sewak's drivers are skilled professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title: "Diverse Ahmedabad Taxi Options:",
      detail: "When selecting our Ahmedabad cab service for your journey",
    },
  ],
  howToBookHeading: "How to Book an Ahmedabad Airport Taxi?",
  howToBookInfo:
    "Sewak Travels takes the hassle out of booking in Ahmedabad cab by offering premium services at affordable Ahmedabad taxi rates, complete with a pickup-drop facility and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit https://sewaktravels.com/, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, proceed to payment, and you're all set! Now that you're acquainted with our Ahmedabad taxi service, why wait any longer?",
  taxiServicesHeading:
    "Various Ahmedabad Airport Cab Services by Sewak Travels",
  taxiServices: [
    {
      title: "Outstation Cabs from Ahmedabad Airport",
      description:
        "Sewak Travels offers reliable outstation cabs from Ahmedabad Airport",
    },
    {
      title: "Local Taxis from Ahmedabad Airport",
      description:
        "Need a ride within Ahmedabad? We make it easy to book local taxis from Ahmedabad Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "Corporate Airport Transfers",
      description:
        "We also cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "Airport Taxi at Per Km Rates",
      detail:
        "Pay only for the distance you travel with our transparent pricing model.",
    },
    {
      title: "Prepaid Taxi Service",
      detail:
        "Avoid last-minute hassles by booking your prepaid taxi at Ahmedabad Airport with Sewak Travels.",
    },
    {
      title: "Taxi Service in Ahmedabad",
      detail:
        "We not only cover the airport but also provide reliable taxi services across Ahmedabad for sightseeing or city tours.",
    },
  ],
  easyBookingHeading: "Easy Ahmedabad Airport Cab Booking for Every Need",
  easyBookingInfo:
    "Whether you’re visiting Ahmedabad for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Ahmedabad Airport taxi contact number and online platforms make it easy for you to book a cab within minutes.",
  easyBookingPoints: [
    "- Affordable Ahmedabad Airport Taxi Fare",
    "- Quick & Reliable Airport Taxi Service",
    "- Car Rental Services for Ahmedabad Airport",
  ],
  bookingCallToAction:
    "Book your Ahmedabad Airport cab now with Sewak Travels and enjoy hassle-free, comfortable rides at affordable prices. Call us at [Insert Contact Number] or visit our website for quick and easy Ahmedabad Airport taxi booking. Let Sewak Travels make your airport commute effortless!",
  thingsToKnowHeading: "Things To Know About Ahmedabad",
  thingsToKnowInfo:
    "Ahmedabad, located in the western Indian state of Gujarat, is a city known for its rich history and vibrant culture. Established in the 15th century, it became a prominent center for trade and commerce, especially during the Mughal era. The city boasts numerous architectural marvels, including the stunning Sabarmati Ashram, which was once the residence of Mahatma Gandhi, and the exquisite Adalaj Stepwell, showcasing intricate carvings. Ahmedabad is also famous for its textile industry and is recognized as a UNESCO World Heritage City for its historic sites and unique urban planning. The city’s culinary scene is renowned for its street food, especially dishes like dhokla and khaman. With its modern amenities, educational institutions, and thriving business environment, Ahmedabad continues to attract tourists and professionals alike, making it an essential destination in India.",
  faqHeading: "Frequently Asked Questions About Ahmedabad Airport Cab Booking",
  faqs: [
    {
      question: "How can I book an Ahmedabad Airport cab?",
      answer:
        "You can easily book an Ahmedabad Airport taxi by visiting our website sewaktravels.com and selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Ahmedabad?",
      answer:
        "Sewak Travels provides intra-city cab transfers which allow users to book cabs to and from the Agra Airport in Agra",
    },
    {
      question:
        "What types of vehicles do you offer for Ahmedabad Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Ahmedabad Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Ahmedabad Airport cabs?",
      answer:
        "Our Ahmedabad Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Absolutely! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question:
        "Can I book a cab for outstation travel from Ahmedabad Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Ahmedabad Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with your booking or any inquiries you may have.",
    },
    {
      question: "Do you offer local taxi services in Ahmedabad?",
      answer:
        "Yes, we provide local taxi services in Ahmedabad, perfect for sightseeing and city tours.",
    },
    {
      question: "How do I contact you for more information?",
      answer:
        "You can reach us at +91-837-782-8828 for any inquiries regarding our Ahmedabad Airport taxi service or bookings.",
    },
  ],
};
export const ajmerTaxiData = {
  pageTitle: "Book Ajmer Airport Cabs",
  mainHeading: "Book Ajmer Airport Cabs on Sewak Travels",
  subHeading: "Enjoy Easy Airport Pickup & Drop Services in Ajmer.",
  airportInfo:
    "Ajmer Airport, also known as Kishangarh Airport, is important for air travel and local connections. Located just 30 km from Ajmer city, it is a great starting point for tourists exploring Ajmer’s historical and cultural sites, such as the famous Ajmer Sharif Dargah and Ana Sagar Lake.",
  serviceInfo:
    "At Sewak Travels, we aim to make your travel easy with our dependable Ajmer Airport cab service. Whether you’re arriving for a short visit or heading out, we ensure your journey to and from Ajmer Airport is smooth and timely.",
  distanceHeading: "Ajmer to Ajmer Airport Cab Distance",
  distanceInfo:
    "The distance from Ajmer Airport to the city center is about 30 km, depending on the route and any stops along the way. For a relaxing and enjoyable journey, simply book an Ajmer Airport taxi through Sewak Travels’ easy online platform. Whether going to or from the airport, we guarantee a trouble-free experience, making your travel convenient and reliable. Skip the waiting and book your airport taxi in Ajmer directly with Sewak Travels for a hassle-free ride!",
  bookingHeading: "Online Ajmer Airport Cab Booking with Sewak Travels",
  bookingInfo:
    "Welcome to Sewak Travels! We offer dependable, comfortable, and timely online Ajmer Airport cab booking to fit your needs. Whether arriving in or leaving Ajmer, our Ajmer Airport taxi service guarantees a smooth travel experience from start to finish. With a fleet of modern cars and skilled drivers, we provide top-quality airport cab services in Ajmer.",
  img: "/assets/img/LocationDifferent/Ajmer-airport.webp",
  whyChooseHeading: "Why Choose Sewak Travels for Your Ajmer Cab?",
  whyChooseSubHeading:
    "Booking your Ajmer cabs is easy: When you choose Sewak for your cab service in Ajmer, you get:",
  whyChooseInfo: [
    {
      title: "24/7 Booking & Support:",
      detail: "Our Ajmer Airport taxi service operates 24/7",
    },
    {
      title: "Outstation Cabs:",
      detail:
        "Need to travel outside Ajmer? We provide outstation cabs from Ajmer Airport",
    },
    {
      title: "Competitive Rates:",
      detail: "With Ajmer Airport taxi per km rates",
    },
    {
      title: "Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi for Ajmer Airport in advance or upon arrival with flexible payment options.",
    },
    {
      title: "Professional Drivers:",
      detail:
        "Sewak's drivers are trained professionals committed to passenger safety and comfort. Chosen through a strict process",
    },
    {
      title: "Comfortable Vehicles:",
      detail: "Choose from a variety of cars that suit your travel needs",
    },
    {
      title: "Different Ajmer Taxi Choices:",
      detail: "With our Ajmer cab service",
    },
  ],
  howToBookHeading: "How to Book an Ajmer Airport Taxi?",
  howToBookInfo:
    "Sewak Travels simplifies the booking process by offering excellent services at reasonable Ajmer taxi rates, including pickup and drop-off and quick customer support. With just a click on Sewak Travels, you can access everything you need. Simply visit https://sewaktravels.com/, click on the cabs icon, choose your preference (Outstation, local or hourly, corporate, and airport cab service), select your destination and date, choose your vehicle and rates according to your needs, fill in the required details, make the payment, and you’re all set! Now that you know about our Ajmer taxi service, why wait any longer?",
  taxiServicesHeading:
    "Various Taxi Services Available at Ajmer Airport by Sewak Travels",
  taxiServices: [
    {
      title: "Outstation Cabs from Ajmer Airport",
      description:
        "Sewak Travels provides trustworthy outstation cabs from Ajmer Airport",
    },
    {
      title: "Local Taxis from Ajmer Airport",
      description:
        "Need a ride within Ajmer? We make booking local taxis from Ajmer Airport simple with just a few clicks. Our local cab package is a great deal",
    },
    {
      title: "Corporate Airport Transfers",
      description:
        "We also serve businesses with corporate airport transfer services and employee transport. Contact us for a custom quote and enjoy reliable service for your company's needs.",
    },
  ],
  highlights: [
    {
      title: "Airport Taxi at Per Km Rates",
      detail:
        "Pay only for the distance you travel with our transparent pricing model.",
    },
    {
      title: "Prepaid Taxi Service",
      detail:
        "Avoid last-minute hassles by booking your prepaid taxi at Ajmer Airport with Sewak Travels.",
    },
    {
      title: "Taxi Service in Ajmer",
      detail:
        "We not only cover the airport but also provide reliable taxi services across Ajmer for sightseeing or city tours.",
    },
  ],
  easyBookingHeading: "Easy Ajmer Airport Cab Booking for Every Need",
  easyBookingInfo:
    "Whether you’re visiting Ajmer for business or leisure or going on an outstation trip, Sewak Travels offers the most convenient and reliable cab booking options. Our Ajmer Airport taxi contact number and online platforms make it simple for you to book a cab in just minutes.",
  easyBookingPoints: [
    "- Affordable Ajmer Airport Taxi Fare",
    "- Quick & Reliable Airport Taxi Service",
    "- Car Rental Services for Ajmer Airport",
  ],
  bookingCallToAction:
    "Book your Ajmer Airport cab now with Sewak Travels and enjoy hassle-free, comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for fast and easy Ajmer Airport taxi booking. Let Sewak Travels make your airport commute easy!",
  thingsToKnowHeading: "Things to Know About Ajmer",
  thingsToKnowInfo:
    "Ajmer, located in Rajasthan, India, is famous for its rich heritage and historical importance. It is home to the well-known Ajmer Sharif Dargah, a sacred shrine for Muslims that attracts millions of visitors every year. Ajmer's history goes back to the 7th century when it was founded by the Chauhan dynasty. The city is also noted for its beautiful architecture, including the Ajmer Fort and Ana Sagar Lake, which add to its scenic beauty. Ajmer serves as a base for visiting nearby attractions, such as Pushkar, known for its holy lake and vibrant culture. With a mix of tradition, spirituality, and history, Ajmer is a must-visit place for anyone traveling in India.",
  faqHeading: "Frequently Asked Questions About Ajmer Airport Cab Booking",
  faqs: [
    {
      question: "How can I book an Ajmer Airport cab?",
      answer:
        "You can easily book an Ajmer Airport taxi by visiting our website sewaktravels.com, selecting the cab option, choosing your preferences, and completing your booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Ajmer?",
      answer:
        "Sewak Travels offers intra-city cab transfers, allowing users to book cabs to and from Ajmer Airport in Ajmer.",
    },
    {
      question:
        "What types of vehicles do you offer for Ajmer Airport cab services?",
      answer:
        "We have various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for any group size.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we provide a prepaid taxi service at Ajmer Airport, allowing you to book your ride in advance without any last-minute stress.",
    },
    {
      question: "What are the rates for Ajmer Airport cabs?",
      answer:
        "Our Ajmer Airport taxi fares are competitive and based on a per km rate, ensuring clarity with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Yes! All our drivers are professional and experienced, ensuring your safety and comfort throughout your ride.",
    },
    {
      question: "Can I book a cab for outstation travel from Ajmer Airport?",
      answer:
        "Yes, we offer reliable outstation cabs from Ajmer Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with your booking or any questions you may have.",
    },
    {
      question: "Do you offer local taxi services in Ajmer?",
      answer:
        "Yes, we provide local taxi services in Ajmer, perfect for sightseeing and city tours.",
    },
    {
      question: "How do I contact you for more information?",
      answer:
        "You can reach us at +91-837-782-8828 for any inquiries regarding our Ajmer Airport taxi service or bookings.",
    },
  ],
};
export const akolaTaxiData = {
  pageTitle: "Book Akola Airport Cabs",
  mainHeading: "Book Akola Airport Cabs on Sewak Travels",
  subHeading: "Enjoy Seamless Airport Pickup & Drop Services in Akola",
  airportInfo:
    "Akola Airport also known as Shioni Airport is an important entry point to the city of Akola in Maharashtra. Located just about 10 km from the center of Akola city, this airport connects travelers to various destinations within India. Whether you are traveling to Akola for work, a vacation, or to visit family, you will find that the city offers a rich cultural heritage and serves as an important trading hub in the region.\n",
  serviceInfo:
    "At Sewak Travels, we aim to make your journey to and from Akola Airport as comfortable as possible. Our reliable Akola Airport cab service makes that whether you are arriving or departing, you can count on timely rides. With us, you can focus on enjoying your trip, knowing that your transportation needs are taken care of.\n",
  distanceHeading: "Akola to Akola Airport Cab Distance",
  distanceInfo:
    "The distance from Akola Airport to the city center is around 10 km. This distance may vary slightly depending on the specific route taken and any stops along the way. With Sewak Travels user-friendly online platform, booking a taxi to or from Akola Airport has never been easier. We are dedicated to providing a smooth travel experience, so you can enjoy top-notch Akola Airport cab services with just a few clicks!\n",
  bookingHeading: "Online Akola Airport cab Booking with Sewak Travels",
  bookingInfo:
    "Welcome to Sewak Travels, where we offer reliable, comfortable, and on-time Online Akola Airport cab booking tailored to your needs. Whether you’re arriving in or departing from Akola, our Akola Airport taxi service ensures a seamless travel experience from start to finish. With a fleet of modern vehicles and professional drivers, we provide the highest standard of airport cab services in Akola.\n",
  img: "/assets/img/LocationDifferent/Akola-airport.webp",
  whyChooseHeading: "Why Choose Sewak Travels for Your Akola Cab Service?",
  whyChooseSubHeading:
    "Book your Akola Cabs effortlessly: When you choose Sewak for your cab service in Akola, you get:",
  whyChooseInfo: [
    {
      title: "- 24/7 Booking & Travel Assistance:",
      detail: "Our Akola Airport taxi service operates 24/7",
    },
    {
      title: "- Outstation Cabs:",
      detail:
        "Need to travel outside Akola? We offer outstation cabs from Akola Airport",
    },
    {
      title: "- Competitive Rates:",
      detail: "With Akola Airport taxi per km rates",
    },
    {
      title: "- Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi at Akola Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "- Professional Drivers:",
      detail:
        "Sewak's drivers are skilled professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "- Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title:
        "- Diverse Akola Taxi Options: When selecting our Akola cab service for your journey",
      detail: "you'll access three types of cabs: 4-seater sedans (Dzire",
    },
  ],
  howToBookHeading: "How to Book an Akola Airport Taxi?",
  howToBookInfo:
    "Sewak Travels takes the hassle out of booking an Akola Cab by offering premium services at affordable Akola taxi rates, complete with a pickup-drop facility and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit https://sewaktravels.com/, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, proceed to payment, and you're all set! Now that you're acquainted with our Akola taxi service, why wait any longer?\n",
  taxiServicesHeading: "Various Taxi Service In Akola Airport by Sewak Travels",
  taxiServices: [
    {
      title: "- Outstation Cabs from Akola Airport:",
      description:
        "Sewak Travels offers reliable outstation cabs from Akola Airport",
    },
    {
      title: "-Local Taxis from Akola Airport:",
      description:
        "Need a ride within Akola? We make it easy to book local taxis from Akola Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "- Corporate Airport Transfers:",
      description:
        "We also cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "- Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "- Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "- Taxi Service in Akola:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: "Easy Akola Airport Cab Booking for Every Need",
  easyBookingInfo:
    "Whether you're visiting Akola for business or pleasure, Sewak Travels ensures the most convenient and dependable cab booking options for you. Our Akola Airport taxi contact number and user-friendly online booking platforms allow you to arrange your ride in just a few minutes.\n",
  easyBookingPoints: [
    "- Affordable Akola Airport Taxi Fare",
    "- Quick & Reliable Airport Taxi Service",
    "- Car Rental Services for Akola Airport",
  ],
  bookingCallToAction:
    "Book your Akola Airport cab now with Sewak Travels and enjoy hassle-free, comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Akola Airport taxi booking. Let Sewak Travels make your airport commute effortless!",
  thingsToKnowHeading: "Things to Know About Akola",
  thingsToKnowInfo:
    "Akola is located in Maharashtra. It is a lively city that has a vibrant culture, rich history, and significant economic importance as a major hub for cotton and oilseed trading. It also serves as a gateway to nearby tourist destinations such as Shegaon, which is famous for the Gajanan Maharaj Temple, and the ancient forts of Narnala and Akot. Visitors to Akola can explore the lively markets, indulge in local delicacies, and experience the warmth of Marathi culture firsthand.The best time to visit Akola is between November and February when the weather is cooler and perfect for sightseeing. If you're planning to travel to nearby attractions or simply need a taxi service in Akola, Sewak Travels is your trusted choice for convenient and reliable transportation.\n",
  faqHeading: "Frequently Asked Questions About Akola Airport Cab Booking",
  faqs: [
    {
      question: "How can I book an Akola Airport cab?",
      answer:
        "You can book an Akola Airport taxi easily by visiting our website sewaktravels.com and selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Akola?",
      answer:
        "Sewak Travels provides intra-city cab transfers which allow users to book cabs to and from the Akola Airport in Akola.",
    },
    {
      question:
        "What types of vehicles do you offer for Akola Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Akola Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Akola Airport cabs?",
      answer:
        "Our Akola Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Absolutely! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question: "Can I book a cab for outstation travel from Akola Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Akola Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with your booking or any inquiries you may have.",
    },
    {
      question: "Do you offer local taxi services in Akola?",
      answer:
        "Yes, we provide local taxi services in Akola, perfect for sightseeing and city tours.",
    },
    {
      question: "How do I contact you for more information?",
      answer:
        "You can reach us at +91-837-782-8828 for any inquiries regarding our Akola Airport taxi service or bookings.",
    },
  ],
};
export const allahabadTaxiData = {
  pageTitle: "Book Allahabad Airport Cabs",
  mainHeading: "Book Allahabad Airport Cabs on Sewak Travels",
  subHeading: "Enjoy Seamless Airport Pickup & Drop Services in Allahabad.",
  airportInfo:
    "Allahabad Airport, also known as Bamrauli Airport, serves as an essential hub for both civilian air travel and military operations by the Indian Air Force. Established in 1919, this historic airbase plays a key role in connecting travelers to the culturally rich city of Allahabad. Located just 12 km from the Allahabad Railway Junction and 10 km from the city bus stand, Bamrauli Airport offers easy access for visitors eager to explore the city's heritage.\n",
  serviceInfo:
    "At Sewak Travels, we provide a smooth travel experience with our reliable Allahabad Airport cab service. Whether you're flying in for a short stay or heading out, we ensure a smooth, stress-free journey to and from Allahabad Airport, always on time.\n",
  distanceHeading: "Allahabad to Allahabad Airport Cab Distance",
  distanceInfo:
    "The distance from Allahabad Airport to the city center is about 12 km. To make your journey easy, just book an Allahabad Airport taxi through our simple online platform. Whether you’re going to or from the airport, we promise a hassle-free experience. Avoid waiting and book your airport cab in Allahabad directly with Sewak Travels for a smooth ride!\n",
  bookingHeading: "Online Allahabad Airport cab Booking with Sewak Travels",
  bookingInfo:
    "Welcome to Sewak Travels, where we offer trustworthy and comfortable Online Allahabad Airport cab booking. Whether you are arriving or leaving Allahabad, our Allahabad Airport taxi service guarantees a seamless travel experience. We have modern vehicles and skilled drivers to provide the best airport cab services in Allahabad.\n",
  img: "/assets/img/LocationDifferent/Allahabad-airport.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book an Allahabad Cab?",
  whyChooseSubHeading:
    "Book your Allahabad Cabs effortlessly: When you choose Sewak for your cab service in Allahabad, you get:",
  whyChooseInfo: [
    {
      title: "- 24/7 Booking & Travel Assistance:",
      detail: "Our Allahabad Airport taxi service operates 24/7",
    },
    {
      title: "- Outstation Cabs:",
      detail:
        "Need to travel outside Allahabad? We offer outstation cabs from Allahabad Airport",
    },
    {
      title: "- Competitive Rates:",
      detail: "With Allahabad Airport taxi per km rates",
    },
    {
      title: "- Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi at Allahabad Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "- Professional Drivers:",
      detail:
        "Sewak's drivers are skilled professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "- Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title: "- Diverse Allahabad Taxi Options:",
      detail: "When selecting our Allahabad cab service for your journey",
    },
  ],
  howToBookHeading: "How to Book an Allahabad Airport Taxi",
  howToBookInfo:
    "Sewak Travels takes the hassle out of booking an Allahabad Cab by offering premium services at affordable Allahabad taxi rates, complete with a pickup-drop facility and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit https://sewaktravels.com/, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, proceed to payment, and you're all set! Now that you're acquainted with our Allahabad taxi service, why wait any longer?\n",
  taxiServicesHeading:
    "Various Taxi Services from Allahabad Airport by Sewak Travels",
  taxiServices: [
    {
      title: "- Outstation Cabs from Allahabad Airport",
      description:
        "Sewak Travels offers reliable outstation cabs from Allahabad Airport for quick and comfortable rides for tourists and locals. Whether you need an airport pickup or drop",
    },
    {
      title: "- Local Taxis from Allahabad Airport",
      description:
        "Need a ride within Allahabad? We make it easy to book local taxis from Allahabad Airport in just a few clicks. Our local cab package offers great value",
    },
    {
      title: "- Corporate Airport Transfers",
      description:
        "We also provide corporate airport transfer services and employee transport for businesses. Contact us for a custom quote and reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "- Airport Taxi at Per Km Rates",
      detail: "With our transparent pricing model",
    },
    {
      title: "- Prepaid Taxi Service",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "- Taxi Service in Allahabad",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: "Easy Allahabad Airport Cab Booking for Every Need",
  easyBookingInfo:
    "Whether you are visiting Allahabad for business or fun, or going for an outstation trip, Sewak Travels offers you the easiest and most reliable cab booking options. Our Allahabad Airport taxi contact number and online platform make it simple for you to book a cab within minutes.\n",
  easyBookingPoints: [
    "- Affordable Allahabad Airport Taxi Fare",
    "- Quick & Reliable Airport Taxi Service",
    "- Car Rental Services for Allahabad Airport",
  ],
  bookingCallToAction:
    "Book your Allahabad Airport cab now with Sewak Travels and enjoy easy, comfortable rides at great prices. Call us at +91-837-782-8828 or visit our website for fast and simple Allahabad Airport taxi booking. Let Sewak Travels make your airport journey effortless!",
  thingsToKnowHeading: "Things To Know About Allahabad",
  thingsToKnowInfo:
    "Allahabad, located in Uttar Pradesh, India, is known for its rich culture and history. This lively city is a major pilgrimage site, attracting millions of visitors, especially during the Kumbh Mela. The meeting point of the Ganges, Yamuna, and Saraswati rivers, known as Triveni Sangam, is a highlight of the city. Allahabad has many historical sites, such as Allahabad Fort, Anand Bhawan, and the Jawahar Planetarium. The city’s vibrant culture shows its historical importance, with local food and crafts still popular. Allahabad’s good connectivity by road, rail, and air makes it a must-visit place for history lovers and tourists eager to see India's rich culture.\n",
  faqHeading: "Frequently Asked Questions About Allahabad Airport Cab Booking",
  faqs: [
    {
      question: "How can I book an Allahabad Airport cab?",
      answer:
        "You can book an Allahabad Airport taxi easily by visiting our website sewaktravels.com, selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Allahabad?",
      answer:
        "Sewak Travels provides intra-city cab transfers, allowing users to book cabs to and from Allahabad Airport in Allahabad.",
    },
    {
      question:
        "What types of vehicles do you offer for Allahabad Airport cab services?",
      answer:
        "We offer various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Allahabad Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Allahabad Airport cabs?",
      answer:
        "Our Allahabad Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Absolutely! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question:
        "Can I book a cab for outstation travel from Allahabad Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Allahabad Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with your booking or any inquiries you may have.",
    },
    {
      question: "Do you offer local taxi services in Allahabad?",
      answer:
        "Yes, we provide local taxi services in Allahabad, perfect for sightseeing and city tours.",
    },
    {
      question: "How do I contact you for more information?",
      answer:
        "You can reach us at +91-837-782-8828 for any inquiries regarding our Allahabad Airport taxi service or bookings.",
    },
  ],
};
export const amritsarTaxiData = {
  pageTitle: "Book Amritsar Airport Cabs",
  mainHeading: "Book Amritsar Airport Cabs on Sewak Travels",
  subHeading: "Enjoy Seamless Airport Pickup & Drop Services in Amritsar.",
  airportInfo:
    "Amritsar Airport, also known as Sri Guru Ram Dass Jee International Airport, is important for civilian air travel and serves as a military base. Opened on 15th August 1947, this historic airport has been a gateway to the famous Golden Temple for many years. Located just 11 km from Amritsar Railway Station and 12 km from the bus stand, Amritsar Airport is easily accessible for visitors wanting to explore Amritsar.\n",
  serviceInfo:
    "At Sewak Travels, we make your travel experience smoother with our reliable Amritsar Airport cab service. Whether you’re flying in for a quick visit or heading out, we ensure your journey to and from Amritsar Airport is stress-free and on time.\n",
  distanceHeading: "Amritsar to Amritsar Airport Cab Distance",
  distanceInfo:
    "The distance from Amritsar Airport to the city center is about 11 km, depending on the route and any stops. To enjoy a comfortable ride, book an Amritsar Airport taxi through Sewak Travels' easy online platform. Whether you're going to or coming from the airport, we make your travel convenient. Skip the wait and book your airport taxi in Amritsar directly with Sewak Travels for a smooth ride!\n",
  bookingHeading: "Online Amritsar Airport Cab Booking with Sewak Travels",
  bookingInfo:
    "Welcome to Sewak Travels, where we provide reliable, comfortable, and timely online Amritsar Airport cab booking designed for you. Whether you’re arriving in or leaving from Amritsar, our Amritsar Airport taxi service ensures a smooth travel experience from start to finish. With a fleet of modern vehicles and professional drivers, we offer top-quality airport cab services in Amritsar.\n",
  img: "/assets/img/LocationDifferent/Amritsar-airport.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book an Amritsar Cab?",
  whyChooseSubHeading:
    "Booking your Amritsar cabs is easy. When you choose Sewak for your cab service in Amritsar, you benefit from:",
  whyChooseInfo: [
    {
      title: "- 24/7 Booking & Travel Assistance:",
      detail: "Our Amritsar Airport taxi service is available around the clock",
    },
    {
      title: "- Outstation Cabs:",
      detail:
        "Need to go outside Amritsar? We provide outstation cabs from Amritsar Airport",
    },
    {
      title: "- Competitive Rates:",
      detail: "With Amritsar Airport taxi rates per km",
    },
    {
      title: "- Prepaid and Hassle-Free:",
      detail:
        "Reserve your prepaid taxi Amritsar Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "- Professional Drivers:",
      detail:
        "Sewak's drivers are trained professionals focused on your safety and comfort. Carefully selected through a thorough process",
    },
    {
      title: "- Comfortable Fleet:",
      detail: "Choose from a variety of vehicles to match your travel needs",
    },
    {
      title:
        "- Diverse Amritsar Taxi Options: When using our Amritsar cab service",
      detail: "you'll find three types of cabs: 4-seater sedans (Dzire",
    },
  ],
  howToBookHeading: "How to Book an Amritsar Airport Taxi?",
  howToBookInfo:
    "Sewak Travels makes booking in Amritsar cab easy, offering premium services at affordable Amritsar taxi rates, complete with pickup and drop-off services and quick customer support. With just a click on Sewak Travels, everything is at your fingertips. Simply visit sewaktravels.com, click on the cabs icon, choose your preference (outstation, local, hourly, corporate, or airport cab service), select your destination and date, pick the car and rates that fit your needs and budget, fill in the details, proceed to payment, and you're done! Now that you know about our Amritsar taxi service, why wait any longer?\n",
  taxiServicesHeading:
    "Various Taxi Services In Amritsar Airport by Sewak Travels",
  taxiServices: [
    {
      title: "-Outstation Cabs from Amritsar Airport:",
      description:
        "Sewak Travels provides reliable outstation cabs from Amritsar Airport",
    },
    {
      title: "- Local Taxis from Amritsar Airport:",
      description:
        "Need a ride within Amritsar? We simplify booking local taxis from Amritsar Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "- Corporate Airport Transfers:",
      description:
        "We also assist businesses with corporate airport transfer services and employee transport. Contact us for a custom quote and enjoy dependable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "- Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "-Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "- Taxi Service in Amritsar:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: "Easy Amritsar Airport Cab Booking for Every Need",
  easyBookingInfo:
    "Whether you’re in Amritsar for business, leisure, or an outstation trip, Sewak Travels offers you the best and most reliable cab booking options. Our Amritsar Airport taxi contact number and online platforms make it easy to book a cab in minutes.\n",
  easyBookingPoints: [
    "- Affordable Amritsar Airport Taxi Fare",
    "- Quick & Reliable Airport Taxi Service",
    "- Car Rental Services for Amritsar Airport",
  ],
  bookingCallToAction:
    "Book your Amritsar Airport cab today with Sewak Travels and enjoy easy, comfortable rides at great prices. Call us at +91-837-782-8828 or visit our website for quick and simple Amritsar Airport taxi booking. Let Sewak Travels make your airport journey easy!",
  thingsToKnowHeading: "Things To Know About Amritsar",
  thingsToKnowInfo:
    "Amritsar, located in Punjab, India, is famous for the Golden Temple, a key spiritual and cultural site. This historic city has a rich heritage that goes back centuries. Amritsar played a big role during India's fight for independence and remains a lively center of Sikh culture and history. The Golden Temple, also called Harmandir Sahib, draws millions of visitors and pilgrims each year, representing peace and spirituality. Additionally, Amritsar features other important sites like Jallianwala Bagh, a memorial for the 1919 massacre, and the Partition Museum, highlighting the effects of India’s partition. Amritsar’s lively culture is complemented by its traditional Punjabi cuisine, with local favorites like Amritsari Kulcha and Lassi being well-loved by visitors. The city is well-connected by road, rail, and air, making it a must-see destination for history lovers and tourists eager to explore India's rich cultural heritage.\n",
  faqHeading: "Frequently Asked Questions About Amritsar Airport Cab Booking",
  faqs: [
    {
      question: "How can I book an Amritsar Airport cab?",
      answer:
        "You can book an Amritsar Airport taxi easily by visiting our website sewaktravels.com and selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services Does Sewak Travels provide in Amritsar?",
      answer:
        "Sewak Travels offers intra-city cab transfers, allowing you to book cabs to and from the Amritsar Airport.",
    },
    {
      question:
        "What types of vehicles do you offer for Amritsar Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Amritsar Airport, allowing you to secure your ride in advance without any last-minute stress.",
    },
    {
      question: "What are the rates for Amritsar Airport cabs?",
      answer:
        "Our Amritsar Airport taxi fare is competitive and based on a per km rate, ensuring clear pricing with no hidden fees.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Yes! All our drivers are trained and experienced, ensuring your safety and comfort during your trip.",
    },
    {
      question: "Can I book a cab for outstation travel from Amritsar Airport?",
      answer:
        "Yes, we offer reliable outstation cabs from Amritsar Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with your booking or any inquiries.",
    },
    {
      question: "Do you offer local taxi services in Amritsar?",
      answer: "Yes, we provide local taxi services within Amritsar as well.",
    },
    {
      question: "How can I contact Sewak Travels for cab bookings?",
      answer:
        "You can reach us at +91-837-782-8828 for any inquiries regarding our Amritsar Airport taxi service or bookings.",
    },
  ],
};
export const aurangabadTaxiData = {
  pageTitle: "Book Aurangabad Airport Cabs",
  mainHeading: "Book Aurangabad Airport Cabs on Sewak Travels",
  subHeading: "Enjoy easy airport pickup and drop services in Aurangabad.",
  airportInfo:
    "Aurangabad Airport, also known as Chikkalthana Airport, is essential for civilian air travel and serves as a hub for tourism in Maharashtra. Opened on 15th August 1986, this airport has been a gateway to the famous Ajanta and Ellora Caves for many years. Located just 10 km from Aurangabad Railway Station and 12 km from the bus stand, Aurangabad Airport is easily accessible for visitors wanting to explore Aurangabad.\n",
  serviceInfo:
    "At Sewak Travels, we make your travel smoother with our dependable Aurangabad Airport cab service. Whether you’re arriving for a short visit or departing, we ensure your journey to and from Aurangabad Airport is easy and on time.\n",
  distanceHeading: "Aurangabad to Aurangabad Airport Cab Distance",
  distanceInfo:
    "The distance from Aurangabad Airport to the city center is about 10 km, depending on the route and any stops. To enjoy a comfortable ride, book an Aurangabad Airport taxi through Sewak Travels' easy online platform. Whether you're going to or coming from the airport, we make your travel convenient. Skip the wait and book your airport taxi in Aurangabad directly with Sewak Travels for a smooth ride!\n",
  bookingHeading: "Online Aurangabad Airport Cab Booking with Sewak Travels",
  bookingInfo:
    "Welcome to Sewak Travels, where we provide reliable, comfortable, and timely online Aurangabad Airport cab booking designed for you. Whether you’re arriving in or leaving from Aurangabad, our Aurangabad Airport taxi service ensures a smooth travel experience from start to finish. With a fleet of modern vehicles and professional drivers, we offer top-quality airport cab services in Aurangabad",
  img: "/assets/img/LocationDifferent/Aurangabad-airport.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book an Aurangabad Cab?",
  whyChooseSubHeading:
    "Booking your Aurangabad cabs is easy: When you choose Sewak for your cab service in Aurangabad, you benefit from:",
  whyChooseInfo: [
    {
      title: "- 24/7 Booking & Travel Assistance:",
      detail:
        "Our Aurangabad Airport taxi service is available around the clock",
    },
    {
      title: "- Outstation Cabs:",
      detail:
        "Need to go outside Aurangabad? We provide outstation cabs from Aurangabad Airport",
    },
    {
      title: "- Competitive Rates:",
      detail: "With Aurangabad Airport taxi rates per km",
    },
    {
      title: "- Prepaid and Hassle-Free:",
      detail:
        "Reserve your prepaid taxi Aurangabad Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "- Professional Drivers:",
      detail:
        "Sewak's drivers are trained professionals focused on your safety and comfort. Carefully selected through a thorough process",
    },
    {
      title: "- Comfortable Fleet:",
      detail: "Choose from a variety of vehicles to match your travel needs",
    },
    {
      title:
        "- Diverse Aurangabad Taxi Options: When using our Aurangabad cab service",
      detail: "you'll find three types of cabs: 4-seater sedans (Dzire",
    },
  ],
  howToBookHeading: "How to Book an Aurangabad Airport Taxi?",
  howToBookInfo:
    "Sewak Travels makes booking an Aurangabad cab easy, offering premium services at affordable Aurangabad taxi rates, complete with pickup and drop-off services and quick customer support. With just a click on Sewak Travels, everything is at your fingertips. Simply visit sewaktravels.com, click on the cabs icon, choose your preference (outstation, local, hourly, corporate, or airport cab service), select your destination and date, pick the car and rates that fit your needs and budget, fill in the details, proceed to payment, and you're done! Now that you know about our *Aurangabad taxi service, why wait any longer?\n",
  taxiServicesHeading:
    "Various Taxi Services In Aurangabad Airport by Sewak Travels",
  taxiServices: [
    {
      title: "- Outstation Cabs from Aurangabad Airport:",
      description:
        "Sewak Travels provides reliable outstation cabs from Aurangabad Airport",
    },
    {
      title: "- Local Taxis from Aurangabad Airport:",
      description:
        "Need a ride within Aurangabad? We simplify booking local taxis from Aurangabad Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "- Corporate Airport Transfers:",
      description:
        "We also assist businesses with corporate airport transfer services and employee transport. Contact us for a custom quote and enjoy dependable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "- Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "- Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "- Taxi Service in Aurangabad:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: "Easy Aurangabad Airport Cab Booking for Every Need",
  easyBookingInfo:
    "Whether you’re in Aurangabad for business, leisure, or an outstation trip, Sewak Travels offers you the best and most reliable cab booking options. Our Aurangabad Airport taxi contact number and online platforms make it easy to book a cab in minutes.\n",
  easyBookingPoints: [
    "- Affordable Aurangabad Airport Taxi Fare",
    "- Quick & Reliable Airport Taxi Service",
    "- Car Rental Services for Aurangabad Airport",
  ],
  bookingCallToAction:
    "Book your Aurangabad Airport cab today with Sewak Travels and enjoy easy, comfortable rides at great prices. Call us at +91-837-782-8828 or visit our website for quick and simple Aurangabad Airport taxi booking. Let Sewak Travels make your airport journey easy!",
  thingsToKnowHeading: "Things To Know About Aurangabad",
  thingsToKnowInfo:
    'Aurangabad, located in Maharashtra, India, is famous for its UNESCO World Heritage Sites, including the Ajanta and Ellora Caves, key tourist attractions showcasing ancient rock-cut architecture and exquisite sculptures. This historic city has a rich heritage that goes back centuries and played a significant role during India’s struggle for independence. Aurangabad features other important sites like Bibi Ka Maqbara, a stunning mausoleum often referred to as the "Taj of Deccan," and the Aurangabad Caves, showcasing remarkable Buddhist art. Aurangabad’s vibrant culture is complemented by its traditional cuisine, with local specialties like Aurangabad’s famous Paithani sarees and delicious non-vegetarian dishes being well-loved by visitors. The city is well-connected by road, rail, and air, making it a must-see destination for history lovers and tourists eager to explore India’s rich cultural heritage.\n',
  faqHeading: "Frequently Asked Questions About Aurangabad Airport Cab Booking",
  faqs: [
    {
      question: "How can I book an Aurangabad Airport cab?",
      answer:
        "You can book an Aurangabad Airport taxi easily by visiting our website sewaktravels.com and selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Aurangabad?  c",
      answer:
        "Sewak Travels offers intra-city cab transfers, allowing you to book cabs to and from the Aurangabad Airport.",
    },
    {
      question:
        "What types of vehicles do you offer for Aurangabad Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Aurangabad Airport, allowing you to secure your ride in advance without any last-minute stress.",
    },
    {
      question: "What are the rates for Aurangabad Airport cabs?",
      answer:
        "Our Aurangabad Airport taxi fare is competitive and based on a per km rate, ensuring clear pricing with no hidden fees.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Yes! All our drivers are trained and experienced, ensuring your safety and comfort during your trip.",
    },
    {
      question:
        "Can I book a cab for outstation travel from Aurangabad Airport?",
      answer:
        "Yes, we offer reliable outstation cabs from Aurangabad Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with your booking or any inquiries.",
    },
    {
      question: "Do you offer local taxi services in Aurangabad?",
      answer: "Yes, we provide local taxi services within Aurangabad as well.",
    },
    {
      question: "How can I contact Sewak Travels for cab bookings?",
      answer:
        "You can reach us at +91-837-782-8828 for any inquiries regarding our Aurangabad Airport taxi service or bookings.",
    },
  ],
};
export const ayodhyaTaxiData = {
  pageTitle: "Book Ayodhya Airport Cabs",
  mainHeading: "Book Ayodhya Airport Cabs on Sewak Travels",
  subHeading: "Enjoy smooth Airport Pickup & Drop Services in Ayodhya.",
  airportInfo:
    "Ayodhya Airport, also known as Faizabad Airport, plays an important role in both civilian air travel and as a significant hub for religious tourism in India. Established on 15th August 1947, this historic airbase has been a gateway to the spiritual city of Ayodhya for decades. Located just 8 km from Ayodhya Railway Station and 10 km from the bus stand, Ayodhya Airport provides easy access for tourists looking to explore Ayodhya.\n",
  serviceInfo:
    "At Sewak Travels, we make your travel experience smoother with our reliable Ayodhya Airport cab service. Whether you are flying in for a quick visit or heading out, we ensure your journey to and from Ayodhya Airport is relaxed.\n",
  distanceHeading: "Ayodhya to Ayodhya Airport Cab Distance",
  distanceInfo:
    "The distance from Ayodhya Airport to the city center is approximately 8 km, depending on the route and any stops made. For a comfortable and stress-free journey, simply book an Ayodhya Airport taxi through Sewak Travel's user-friendly online platform. Whether you are heading to or from the airport, we ensure an amazing experience, making your travel convenient and reliable.\n",
  bookingHeading: "Online Ayodhya Airport Cab Booking with Sewak Travels",
  bookingInfo:
    "Welcome to Sewak Travels, where we offer reliable, comfortable, and on-time Online Ayodhya Airport cab booking tailored to your needs. Whether you are arriving in or departing from Ayodhya, our Ayodhya Airport taxi service ensures a smooth travel experience from start to finish. With a fleet of modern vehicles and professional drivers, we provide the highest standard of airport cab services in Ayodhya.\n",
  img: "/assets/img/LocationDifferent/Ayodhya-airport.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book an Ayodhya Cab?",
  whyChooseSubHeading:
    "Book your Ayodhya Cabs effortlessly: When you choose Sewak for your cab service in Ayodhya, you get:",
  whyChooseInfo: [
    {
      title: "- 24/7 Booking & Travel Assistance:",
      detail: "Our Ayodhya Airport taxi service operates 24/7",
    },
    {
      title: "- Outstation Cabs:",
      detail:
        "Need to travel outside Ayodhya? We offer outstation cabs from Ayodhya Airport",
    },
    {
      title: "- Competitive Rates:",
      detail: "With Ayodhya Airport taxi per km rates",
    },
    {
      title: "- Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi at Ayodhya Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "- Professional Drivers:",
      detail:
        "Sewak's drivers are skilled professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "- Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title: "- Diverse Ayodhya Taxi Options:",
      detail: "When selecting our Ayodhya cab service for your journey",
    },
  ],
  howToBookHeading: "How to Book an Ayodhya Airport Taxi?",
  howToBookInfo:
    "Sewak Travels solves the issue of booking an Ayodhya Cab by offering premium services at affordable Ayodhya taxi rates, complete with a pickup-drop facility and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit https://sewaktravels.com/, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, proceed to payment.\n",
  taxiServicesHeading:
    "Various Taxi Service In Ayodhya Airport by Sewak Travels",
  taxiServices: [
    {
      title: "- Outstation Cabs from Ayodhya Airport:",
      description:
        "Sewak Travels offers reliable outstation cabs from Ayodhya Airport",
    },
    {
      title: "-Local Taxis from Ayodhya Airport:",
      description:
        "Need a ride within Ayodhya? We make it easy to book local taxis from Ayodhya Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "- Corporate Airport Transfers:",
      description:
        "We also cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "- Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "- Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "- Taxi Service in Ayodhya:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: "Easy Ayodhya Airport Cab Booking for Every Need",
  easyBookingInfo:
    "Whether you are visiting Ayodhya for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Ayodhya Airport taxi contact number and online platforms make it easy for you to book a cab within minutes.\n",
  easyBookingPoints: [
    "- Affordable Ayodhya Airport Taxi Fare",
    "- Quick & Reliable Airport Taxi Service",
    "- Car Rental Services for Ayodhya Airport",
  ],
  bookingCallToAction:
    "Book your Ayodhya Airport cab now with Sewak Travels and enjoy comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Ayodhya Airport taxi booking. Let Sewak Travels make your airport commute effortless!",
  thingsToKnowHeading: "Things To Know About Ayodhya",
  thingsToKnowInfo:
    "Ayodhya, Uttar Pradesh, is known worldwide for its historic Ram Janmabhoomi, a site of great significance for Hindus. This ancient city has a rich heritage dating back to the Treta Yuga. Ayodhya was once the capital of the ancient Kosala kingdom and is believed to be the birthplace of Lord Rama, an avatar of Lord Vishnu. The Ram Janmabhoomi complex stands as a symbol of faith, attracting millions of pilgrims annually. In addition to this, Ayodhya boasts other religious sites like Hanuman Garhi, Nageshwarnath Temple, and Kanak Bhawan, which showcase exquisite architecture and spiritual significance. Ayodhya’s vibrant culture reflects its historical importance, with traditional festivities, local craftsmanship, and delectable cuisine remaining popular. The city is well-connected by road, rail, and air, making it a must-visit destination for spiritual seekers and tourists eager to explore India’s rich cultural heritage.\n",
  faqHeading: "Frequently Asked Questions About Ayodhya Airport Cab Booking",
  faqs: [
    {
      question: "How can I book an Ayodhya Airport cab?",
      answer:
        "You can book an Ayodhya Airport taxi easily by visiting our website sewaktravels.com and selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Ayodhya?",
      answer:
        "Sewak Travels provides intra-city cab transfers which allow users to book cabs to and from the Ayodhya Airport in Ayodhya.",
    },
    {
      question:
        "What types of vehicles do you offer for Ayodhya Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Ayodhya Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Ayodhya Airport cabs?",
      answer:
        "Our Ayodhya Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Absolutely! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question: "Can I book a cab for outstation travel from Ayodhya Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Ayodhya Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with your booking or any inquiries you may have.",
    },
    {
      question: "Do you offer local taxi services in Ayodhya?",
      answer:
        "Yes, we provide local taxi services in Ayodhya, perfect for sightseeing and city tours.",
    },
    {
      question: "How do I contact you for more information?",
      answer:
        "You can reach us at +91-837-782-8828 for any inquiries regarding our Ayodhya Airport taxi service or bookings.",
    },
  ],
};
export const bagdograTaxiData = {
  pageTitle: "Book Bagdogra Airport Cabs",
  mainHeading: "Book Bagdogra Airport Cabs on Sewak Travels",
  subHeading: "Enjoy smooth Airport Pickup & Drop Services in Bagdogra.",
  airportInfo:
    "Bagdogra Airport, also known as Bagdogra International Airport, plays an important role in both civilian air travel and as a significant hub for tourism in India. Established on 15th August 1947, this historic airbase has been a gateway to the scenic hill stations and tea gardens of North Bengal for decades. Located just 11 km from Siliguri and 10 km from the town center, Bagdogra Airport provides easy access for tourists looking to explore the region.Our commitment to excellence ensures that you receive prompt and reliable service at every step. From the moment you arrive at Ayodhya Airport, our drivers will be ready to assist you with your luggage and ensure a smooth ride to your destination. We understand that travel can be stressful, which is why we offer flexible booking options and real-time tracking for your cab. Choose Sewak Travels for a hassle-free airport transfer experience that allows you to focus on what truly matters—your journey and the experiences that await you in Ayodhya.\n",
  serviceInfo:
    "At Sewak Travels, we make your travel experience smoother with our reliable Bagdogra Airport cab service. Whether you are flying in for a quick visit or heading out, we ensure your journey to and from Bagdogra Airport is relaxed.\n",
  distanceHeading: "Bagdogra to Bagdogra Airport Cab Distance",
  distanceInfo:
    "The distance from Bagdogra Airport to the city center is approximately 11 km, depending on the route and any stops made. For a comfortable and stress-free journey, simply book a Bagdogra Airport taxi through Sewak Travel's user-friendly online platform. Whether you are heading to or from the airport, we ensure an amazing experience, making your travel convenient and reliable.\n",
  bookingHeading: "Online Bagdogra Airport Cab Booking with Sewak Travels",
  bookingInfo:
    "Welcome to Sewak Travels, where we offer reliable, comfortable, and on-time Online Bagdogra Airport cab booking tailored to your needs. Whether you are arriving in or departing from Bagdogra, our Bagdogra Airport taxi service ensures a smooth travel experience from start to finish. With a fleet of modern vehicles and professional drivers, we provide the highest standard of airport cab services in Bagdogra.\n",
  img: "/assets/img/LocationDifferent/Bagdogra-airport.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book a Bagdogra Cab?",
  whyChooseSubHeading:
    "Book your Bagdogra Cabs effortlessly: When you choose Sewak for your cab service in Bagdogra, you get:",
  whyChooseInfo: [
    {
      title: "- 24/7 Booking & Travel Assistance:",
      detail: "Our Bagdogra Airport taxi service operates 24/7",
    },
    {
      title: "- Outstation Cabs:",
      detail:
        "Need to travel outside Bagdogra? We offer outstation cabs from Bagdogra Airport",
    },
    {
      title: "- Competitive Rates:",
      detail: "With Bagdogra Airport taxi per km rates",
    },
    {
      title: "- Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi Bagdogra Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "- Professional Drivers:",
      detail:
        "Sewak's drivers are skilled professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "- Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title: "- Diverse Bagdogra Taxi Options:",
      detail: "When selecting our Bagdogra cab service for your journey",
    },
  ],
  howToBookHeading: "How to Book a Bagdogra Airport Taxi?",
  howToBookInfo:
    "Sewak Travels solves the issue of booking an Bagdogra Cab by offering premium services at affordable Bagdogra taxi rates, complete with a pickup-drop facility and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit https://sewaktravels.com/, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, proceed to payment.\n",
  taxiServicesHeading:
    "Various Taxi Service In Bagdogra Airport by Sewak Travels",
  taxiServices: [
    {
      title: "- Outstation Cabs from Bagdogra Airport:",
      description:
        "Sewak Travels offers reliable outstation cabs from Bagdogra Airport",
    },
    {
      title: "- Local Taxis from Bagdogra Airport:",
      description:
        "Need a ride within Bagdogra? We make it easy to book local taxis from Bagdogra Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "- Corporate Airport Transfers:",
      description:
        "We also cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "- Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "- Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "- Taxi Service in Bagdogra:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: "Easy Bagdogra Airport Cab Booking for Every Need",
  easyBookingInfo:
    "Whether you are visiting Bagdogra for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Bagdogra Airport taxi contact number and online platforms make it easy for you to book a cab within minutes.\n",
  easyBookingPoints: [
    "- Affordable Bagdogra Airport Taxi Fare",
    "- Quick & Reliable Airport Taxi Service",
    "- Car Rental Services for Bagdogra Airport",
  ],
  bookingCallToAction:
    "Book your Bagdogra Airport cab now with Sewak Travels and enjoy comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Bagdogra Airport taxi booking. Let Sewak Travels make your airport commute effortless!",
  thingsToKnowHeading: "Things To Know About Bagdogra",
  thingsToKnowInfo:
    "Bagdogra, West Bengal, is known for its proximity to popular tourist destinations like Darjeeling, Kalimpong, and Mirik. This scenic area serves as a gateway to the Himalayas and is surrounded by lush tea gardens. The region has a rich heritage, offering a blend of natural beauty and cultural experiences. Bagdogra is well-connected by road, rail, and air, making it a must-visit destination for travelers eager to explore North Bengal’s breathtaking landscapes and vibrant culture.\n",
  faqHeading: "Frequently Asked Questions About Bagdogra Airport Cab Booking",
  faqs: [
    {
      question: "How can I book a Bagdogra Airport cab?",
      answer:
        "You can book a Bagdogra Airport taxi easily by visiting our website sewaktravels.com and selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Bagdogra?",
      answer:
        "Sewak Travels provides intra-city cab transfers which allow users to book cabs to and from the Bagdogra Airport in Bagdogra.",
    },
    {
      question:
        "What types of vehicles do you offer for Bagdogra Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Bagdogra Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Bagdogra Airport cabs?",
      answer:
        "Our Bagdogra Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Absolutely! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question: "Can I book a cab for outstation travel from Bagdogra Airport?",
      answer: "Can I book a cab for outstation travel from Bagdogra Airport?",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with your booking or any inquiries you may have.",
    },
    {
      question: "Do you offer local taxi services in Bagdogra?",
      answer:
        "Yes, we provide local taxi services in Bagdogra, perfect for sightseeing and city tours.",
    },
    {
      question: "How do I contact you for more information?",
      answer:
        "You can reach us at +91-837-782-8828 for any inquiries regarding our Bagdogra Airport taxi service or bookings.",
    },
  ],
};
export const bareillyTaxiData = {
  pageTitle: "Book Bareilly Airport Cabs",
  mainHeading: "Book Bareilly Airport Cabs on Sewak Travels",
  subHeading: "Enjoy smooth Airport Pickup & Drop Services in Bareilly.",
  airportInfo:
    "Bareilly Airport plays an important role in both civilian air travel and as a significant hub for religious tourism in India. Established on 15th August 1947, this historic airbase has been a path to the spiritual city of Bareilly for decades. Located just 8 km from Bareilly Railway Station and 10 km from the bus stand, Bareilly Airport provides easy access for tourists looking to explore Bareilly.\n",
  serviceInfo:
    "At Sewak Travels, we make your travel experience smoother with our reliable Bareilly Airport cab service. Whether you are flying in for a quick visit or heading out, we ensure your journey to and from Bareilly Airport is relaxed. Our Bareilly Airport cab service ensures you do not miss any attractions. Whether it is visiting the historic sites or experiencing the local markets, our Bareilly taxi services are tailored for every traveler. Our commitment to safety and comfort makes us the top  choice for travelers. So, whether you're a tourist or a local, choose Sewak Travels for an amazing travel experience in Bareilly. \n",
  distanceHeading: "Bareilly to Bareilly Airport Cab Distance",
  distanceInfo:
    "The distance from Bareilly Airport to the city center is around 8 km, depending on the route and any stops made. For a comfortable journey, book a Bareilly Airport taxi through Sewak Travel's user-friendly online platform. No matter what, we ensure an amazing experience, making your travel convenient and reliable.\n",
  bookingHeading: "Online Bareilly Airport Cab Booking with Sewak Travels",
  bookingInfo:
    "At Sewak Travels we offer reliable, comfortable, and on-time Online Bareilly Airport cab booking. Whether you are arriving in or departing from Bareilly, our Bareilly Airport taxi service ensures a smooth travel experience. With a fleet of modern vehicles and professional drivers, we provide the highest standard of airport cab services in Bareilly.\n",
  img: "/assets/img/LocationDifferent/Bareillyairport.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book a Bareilly Cab?",
  whyChooseSubHeading:
    "Book your Bareilly Cabs effortlessly: When you choose Sewak for your cab service in Bareilly, you get:",
  whyChooseInfo: [
    {
      title: "24/7 Booking & Travel Assistance:",
      detail: "Our Bareilly Airport taxi service operates 24/7",
    },
    {
      title: "Outstation Cabs:",
      detail:
        "Need to travel outside Bareilly? We offer outstation cabs from Bareilly Airport",
    },
    {
      title: "Competitive Rates:",
      detail: "With Bareilly Airport taxi per km rates",
    },
    {
      title: "Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi at Bareilly Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "Professional Drivers:",
      detail:
        "Sewak's drivers are skilled professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title: "Diverse Bareilly Taxi Options:",
      detail: "When selecting our Bareilly cab service for your journey",
    },
  ],
  howToBookHeading: "How to Book a Bareilly Airport Taxi?",
  howToBookInfo:
    "Sewak Travels solves the issue of booking a Bareilly Cab by offering premium services at affordable Bareilly taxi rates, complete with a pickup-drop facility and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit https://sewaktravels.com/, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, proceed to payment.  You can also modify your booking or reach out to our 24/7 customer support for any assistance. Additionally, our user-friendly interface ensures a hassle-free experience, allowing you to focus on enjoying your journey. With Sewak Travels, travel comfortably and conveniently every time!\n\n",
  taxiServicesHeading:
    "Various Taxi Service In Bareilly Airport by Sewak Travels",
  taxiServices: [
    {
      title: "Outstation Cabs from Bareilly Airport:",
      description:
        "Sewak Travels offers reliable outstation cabs from Bareilly Airport",
    },
    {
      title: "Local Taxis from Bareilly Airport:",
      description:
        "Need a ride within Bareilly? We make it easy to book local taxis from Bareilly Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "Corporate Airport Transfers:",
      description:
        "We also cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "Taxi Service in Bareilly:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: "Easy Bareilly Airport Cab Booking for Every Need",
  easyBookingInfo:
    "Whether you are visiting Bareilly for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Bareilly Airport taxi contact number and online platforms make it easy for you to book a cab as quickly as possible.\n",
  easyBookingPoints: [
    "Affordable Bareilly Airport Taxi Fare",
    "Quick & Reliable Airport Taxi Service",
    "Car Rental Services for Bareilly Airport",
  ],
  bookingCallToAction:
    "Book your Bareilly Airport cab now with Sewak Travels and enjoy comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Bareilly Airport taxi booking. Let Sewak Travels make your airport commute effortless!",
  thingsToKnowHeading: "Things To Know About Bareilly",
  thingsToKnowInfo:
    "Bareilly is known worldwide for its historic landmarks and lively culture. The city has a rich heritage and is famous for its traditional crafts, including the famous Bareilly comb. Bareilly is also a hub for religious tourism, attracting visitors to its temples and cultural festivals. The city’s  markets, delicious local cuisine, and hospitable people make it an attractive destination. Bareilly is well-connected by road, rail, and air, ensuring easy access for travelers eager to explore its rich cultural heritage.\n",
  faqHeading: "Frequently Asked Questions About Bareilly Airport Cab Booking",
  faqs: [
    {
      question: "How can I book a Bareilly Airport cab?",
      answer:
        "You can book a Bareilly Airport taxi easily by visiting our website sewaktravels.com and selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Bareilly?",
      answer:
        "Sewak Travels provides intra-city cab transfers which allow users to book cabs to and from the Bareilly Airport in Bareilly.",
    },
    {
      question:
        "What types of vehicles do you offer for Bareilly Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Bareilly Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Bareilly Airport cabs?",
      answer:
        "Our Bareilly Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Absolutely! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question: "Can I book a cab for outstation travel from Bareilly Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Bareilly Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with your booking or any inquiries you may have.",
    },
    {
      question: "Do you offer local taxi services in Bareilly?",
      answer:
        "Yes, we provide local taxi services in Bareilly, perfect for sightseeing and city tours.",
    },
    {
      question: "How do I contact you for more information?",
      answer:
        "You can reach us at +91-837-782-8828 for any inquiries regarding our Bareilly Airport taxi service or bookings.",
    },
  ],
};
export const belgaumTaxiData = {
  pageTitle: "Book Belgaum Airport Cabs",
  mainHeading: "Book Belgaum Airport Cabs on Sewak Travels",
  subHeading: "Enjoy smooth Airport Pickup & Drop Services in Belgaum.  ",
  airportInfo:
    "Belgaum Airport plays an important role in both civilian air travel and as a significant hub for religious tourism in India. Established on 15th August 1947, this historic airbase has been a path to the spiritual city of Belgaum for decades. Located just 8 km from Belgaum Railway Station and 10 km from the bus stand, Belgaum Airport provides easy access for tourists looking to explore Belgaum.  \n",
  serviceInfo:
    "At Sewak Travels, we make your travel experience smoother with our reliable Belgaum Airport cab service. Whether you are flying in for a quick visit or heading out, we ensure your journey to and from Belgaum Airport is relaxed. Our Belgaum Airport cab service ensures you do not miss any attractions. Whether it is visiting the historic sites or experiencing the local markets, our Belgaum taxi services are tailored for every traveler. Our commitment to safety and comfort makes us the top choice for travelers. So, whether you're a tourist or a local, choose Sewak Travels for an amazing travel experience in Belgaum.\n",
  distanceHeading: "Belgaum to Belgaum Airport Cab Distance",
  distanceInfo:
    "The distance from Belgaum Airport to the city center is around 8 km, depending on the route and any stops made. For a comfortable journey, book a Belgaum Airport taxi through Sewak Travel's user-friendly online platform. No matter what, we ensure an amazing experience, making your travel convenient and reliable.\n",
  bookingHeading: "Online Belgaum Airport Cab Booking with Sewak Travels",
  bookingInfo:
    "At Sewak Travels, we offer reliable, comfortable, and on-time Online Belgaum Airport cab booking. Whether you are arriving in or departing from Belgaum, our Belgaum Airport taxi service ensures a smooth travel experience. With a fleet of modern vehicles and professional drivers, we provide the highest standard of airport cab services in Belgaum.\n",
  img: "/assets/img/LocationDifferent/Belgaum-airport.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book a Belgaum Cab?",
  whyChooseSubHeading:
    "Book your Belgaum Cabs  effortlessly: When you choose Sewak Travels for your cab service in Belgaum, you get:",
  whyChooseInfo: [
    {
      title: "- 24/7 Booking & Travel Assistance:",
      detail: "Our Belgaum Airport taxi service operates 24/7",
    },
    {
      title: "- Outstation Cabs:",
      detail:
        "Need to travel outside Belgaum? We offer outstation cabs from Belgaum Airport",
    },
    {
      title: "- Competitive Rates:",
      detail: "With Belgaum Airport taxi per km rates",
    },
    {
      title: "- Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi at Belgaum Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "- Professional Drivers:",
      detail:
        "Sewak's drivers are skilled professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "- Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title: "- Diverse Belgaum Taxi Options:",
      detail: "When selecting our Belgaum cab service for your journey",
    },
  ],
  howToBookHeading: "How to Book a Belgaum Airport Taxi?",
  howToBookInfo:
    "Sewak Travels solves the issue of booking a Belgaum Cab by offering premium services at affordable Belgaum taxi rates, complete with a pickup-drop facility and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit https://sewaktravels.com/, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, proceed to payment. You can also modify your booking or reach out to our 24/7 customer support for any assistance. Additionally, our user-friendly interface ensures a hassle-free experience, allowing you to focus on enjoying your journey. With Sewak Travels, travel comfortably and conveniently every time!\n\n",
  taxiServicesHeading:
    "Various Taxi Service In Belgaum Airport by Sewak Travels",
  taxiServices: [
    {
      title: "- Outstation Cabs from Belgaum Airport:",
      description:
        "Sewak Travels offers reliable outstation cabs from Belgaum Airport",
    },
    {
      title: "- Local Taxis from Belgaum Airport:",
      description:
        "Need a ride within Belgaum? We make it easy to book local taxis from Belgaum Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "- Corporate Airport Transfers:",
      description:
        "We also cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "- Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "- Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "- Taxi Service in Belgaum:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: "Easy Belgaum Airport Cab Booking for Every Need",
  easyBookingInfo:
    "Whether you are visiting Belgaum for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Belgaum Airport taxi contact number and online platforms make it easy for you to book a cab as quickly as possible.\n",
  easyBookingPoints: [
    "- Affordable Belgaum Airport Taxi Fare",
    "- Quick & Reliable Belgaum Airport Taxi Service",
    "- Car Rental Services for Belgaum Airport",
  ],
  bookingCallToAction:
    "Book your Belgaum Airport cab now with Sewak Travels and enjoy comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Belgaum Airport taxi booking. Let Sewak Travels make your airport commute effortless!",
  thingsToKnowHeading: "Things To Know About Belgaum",
  thingsToKnowInfo:
    "Belgaum is known worldwide for its historic landmarks and lively culture. The city has a rich heritage and is famous for its traditional crafts, including the famous Belgaum comb. Belgaum is also a hub for religious tourism, attracting visitors to its temples and cultural festivals. The city’s markets, delicious local cuisine, and hospitable people make it an attractive destination. Belgaum is well-connected by road, rail, and air, ensuring easy access for travelers eager to explore its rich cultural heritage.\n",
  faqHeading: "Frequently Asked Questions About Belgaum Airport Cab Booking",
  faqs: [
    {
      question: "How can I book a Belgaum Airport cab?",
      answer:
        "You can book a Belgaum Airport taxi easily by visiting our website sewaktravels.com and selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Belgaum?",
      answer:
        "Sewak Travels provides intra-city cab transfers which allow users to book cabs to and from the Belgaum Airport in Belgaum.",
    },
    {
      question:
        "What types of vehicles do you offer for Belgaum Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Belgaum Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Belgaum Airport cabs?",
      answer:
        "Our Belgaum Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Absolutely! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question: "Can I book a cab for outstation travel from Belgaum Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Belgaum Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support team is available 24/7 to assist you with any queries or concerns related to your booking. You can contact us anytime for assistance with your Belgaum Airport taxi booking or other travel needs.",
    },
    {
      question: "Can I modify or cancel my Belgaum Airport cab booking?",
      answer:
        "Yes, you can easily modify or cancel your Belgaum Airport cab booking by contacting our customer support team or through our website, depending on the booking terms and conditions.",
    },
    {
      question: "How do I pay for my Belgaum Airport cab service?",
      answer:
        "We offer multiple payment options, including online payment methods and cash on arrival, for a seamless booking experience. You can choose the method that suits you best when you book your Belgaum Airport taxi with Sewak Travels.",
    },
  ],
};
export const satnaTaxiData = {
  pageTitle: "Book Satna Airport Cabs",
  mainHeading: "Book Satna Airport Cabs on Sewak Travels",
  subHeading: "Enjoy smooth Airport Pickup & Drop Services in Satna.",
  airportInfo:
    "Satna Airport, also known as Satna Domestic Airport, plays an important role in both civilian air travel and as a significant hub for tourism in India. Established on 15th August 1947, this historic airbase has been a gateway to the scenic regions of Madhya Pradesh for decades. Located just 11 km from the city center, Satna Airport provides easy access for tourists looking to explore the region. Our commitment to excellence ensures that you receive prompt and reliable service at every step. From the moment you arrive at Satna Airport, our drivers will be ready to assist you with your luggage and ensure a smooth ride to your destination. We understand that travel can be stressful, which is why we offer flexible booking options and real-time tracking for your cab. Choose Sewak Travels for a hassle-free airport transfer experience that allows you to focus on what truly matters—your journey and the experiences that await you in Satna.\n",
  serviceInfo:
    "At Sewak Travels, we make your travel experience smoother with our reliable Satna Airport cab service. Whether you are flying in for a quick visit or heading out, we ensure your journey to and from Satna Airport is relaxed.\n",
  distanceHeading: "Satna to Satna Airport Cab Distance",
  distanceInfo:
    "The distance from Satna Airport to the city center is approximately 11 km, depending on the route and any stops made. For a comfortable and stress-free journey, simply book a Satna Airport taxi through Sewak Travel's user-friendly online platform. Whether you are heading to or from the airport, we ensure an amazing experience, making your travel convenient and reliable.\n",
  bookingHeading: "Online Satna Airport Cab Booking with Sewak Travels",
  bookingInfo:
    "Welcome to Sewak Travels, where we offer reliable, comfortable, and on-time Online Satna Airport cab booking tailored to your needs. Whether you are arriving in or departing from Satna, our Satna Airport taxi service ensures a smooth travel experience from start to finish. With a fleet of modern vehicles and professional drivers, we provide the highest standard of airport cab services in Satna.\n",
  img: "/assets/img/LocationDifferent/Satna-airport.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book a Satna Cab?",
  whyChooseSubHeading:
    "Book your Satna Cabs effortlessly: When you choose Sewak for your cab service in Satna, you get:",
  whyChooseInfo: [
    {
      title: "24/7 Booking & Travel Assistance:",
      detail: "Our Satna Airport taxi service operates 24/7",
    },
    {
      title: "Outstation Cabs:",
      detail:
        "Need to travel outside Satna? We offer outstation cabs from Satna Airport",
    },
    {
      title: "Competitive Rates:",
      detail: "With Satna Airport taxi per km rates",
    },
    {
      title: "Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi Satna Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "Professional Drivers:",
      detail:
        "Sewak's drivers are skilled professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title:
        "Diverse Satna Taxi Options: When selecting our Satna cab service for your journey",
      detail: "you'll access three types of cabs: 4-seater sedans (Dzire",
    },
  ],
  howToBookHeading: "How to Book a Satna Airport Taxi?",
  howToBookInfo:
    "Sewak Travels solves the issue of booking a Satna Cab by offering premium services at affordable Satna taxi rates, complete with a pickup-drop facility and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit https://sewaktravels.com/, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, and proceed to payment.\n",
  taxiServicesHeading:
    "Various Taxi Service In Satna Airport by Sewak Travelsc",
  taxiServices: [
    {
      title: "Outstation Cabs from Satna Airport:",
      description:
        "Sewak Travels offers reliable outstation cabs from Satna Airport",
    },
    {
      title: "Local Taxis from Satna Airport:",
      description:
        "Need a ride within Satna? We make it easy to book local taxis from Satna Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "Corporate Airport Transfers:",
      description:
        "We also cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "Taxi Service in Satna:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: "Easy Satna Airport Cab Booking for Every Need",
  easyBookingInfo:
    "Whether you are visiting Satna for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Satna Airport taxi contact number and online platforms make it easy for you to book a cab within minutes.\n",
  easyBookingPoints: [
    "Affordable Satna Airport Taxi Fare",
    "Quick & Reliable Airport Taxi Service",
    "Car Rental Services for Satna Airport",
  ],
  bookingCallToAction:
    "Book your Satna Airport cab now with Sewak Travels and enjoy comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Satna Airport taxi booking. Let Sewak Travels make your airport commute effortless!",
  thingsToKnowHeading: "Things To Know About Satna",
  thingsToKnowInfo:
    "Satna, Madhya Pradesh, is known for its proximity to popular tourist destinations like Khajuraho, Panna, and Maihar. This area serves as a gateway to rich cultural and heritage sites, surrounded by scenic landscapes. Satna is well-connected by road, rail, and air, making it a must-visit destination for travelers eager to explore the heart of India’s historical and natural beauty.\n",
  faqHeading: "Frequently Asked Questions About Satna Airport Cab Booking",
  faqs: [
    {
      question: "How can I book a Satna Airport cab?",
      answer:
        "You can book a Satna Airport taxi easily by visiting our website sewaktravels.com and selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Satna?",
      answer:
        "Sewak Travels provides intra-city cab transfers which allow users to book cabs to and from the Satna Airport in Satna.",
    },
    {
      question:
        "What types of vehicles do you offer for Satna Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Satna Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Satna Airport cabs?",
      answer:
        "Our Satna Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Absolutely! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question: "Can I book a cab for outstation travel from Satna Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Satna Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with your booking or any inquiries you may have.",
    },
    {
      question: "Do you offer local taxi services in Satna?",
      answer:
        "Yes, we provide local taxi services in Satna, perfect for sightseeing and city tours.",
    },
    {
      question:
        "Yes, we provide local taxi services in Satna, perfect for sightseeing and city tours.",
      answer:
        "You can reach us at +91-837-782-8828 for any inquiries regarding our Satna Airport taxi service or bookings.",
    },
  ],
};
export const bhubaneswarTaxiData = {
  pageTitle: "Book Bhubaneswar Airport Cabs",
  mainHeading: "Book Bhubaneswar Airport Cabs on Sewak Travels",
  subHeading: "Enjoy smooth Airport Pickup & Drop Services in Bhubaneswar.",
  airportInfo:
    "Bhubaneswar Airport, also known as Biju Patnaik International Airport, plays a crucial role in both civilian air travel and tourism in India. Established on 15th August 1947, this historic air base serves as a gateway to the ancient temples and scenic beauty of Odisha. Located just 3 km from the city center, Bhubaneswar Airport provides easy access for tourists looking to explore the region. Our commitment to excellence ensures that you receive prompt and reliable service at every step. From the moment you arrive at Bhubaneswar Airport, our drivers will be ready to assist you with your luggage and ensure a smooth ride to your destination. We understand that travel can be stressful, which is why we offer flexible booking options and real-time tracking for your cab. \n",
  serviceInfo:
    "Choose Sewak Travels for a hassle-free airport transfer experience that allows you to focus on what truly matters—your journey and the experiences that await you in Bhubaneswar. Whether you are flying in for a quick visit or heading out, we ensure your journey to and from Bhubaneswar Airport is relaxed.\n",
  distanceHeading: "Bhubaneswar to Bhubaneswar Airport Cab Distance",
  distanceInfo:
    "The distance from Bhubaneswar Airport to the city center is approximately 3 km, depending on the route and any stops made. For a comfortable and stress-free journey, simply book a Bhubaneswar Airport taxi through Sewak Travel's user-friendly online platform. Whether you are heading to or from the airport, we ensure an amazing experience, making your travel convenient and reliable.\n",
  bookingHeading: "Online Bhubaneswar Airport Cab Booking with Sewak Travels",
  bookingInfo:
    "Welcome to Sewak Travels, where we offer reliable, comfortable, and on-time Online Bhubaneswar Airport cab booking tailored to your needs. Whether you are arriving in or departing from Bhubaneswar, our Bhubaneswar Airport taxi service ensures a smooth travel experience from start to finish. With a fleet of modern vehicles and professional drivers, we provide the highest standard of airport cab services in Bhubaneswar.\n",
  img: "/assets/img/LocationDifferent/Bhubaneswar-airport.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book a Bhubaneswar Cab?",
  whyChooseSubHeading:
    "Book your Bhubaneswar Cabs effortlessly: When you choose Sewak for your cab service in Bhubaneswar, you get:",
  whyChooseInfo: [
    {
      title: "24/7 Booking & Travel Assistance:",
      detail: "Our Bhubaneswar Airport taxi service operates 24/7",
    },
    {
      title: "Outstation Cabs:",
      detail:
        "Need to travel outside Bhubaneswar? We offer outstation cabs from Bhubaneswar Airport",
    },
    {
      title: "Competitive Rates:",
      detail: "With Bhubaneswar Airport taxi per km rates",
    },
    {
      title: "Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi Bhubaneswar Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "Professional Drivers:",
      detail:
        "Sewak's drivers are skilled professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title: "Diverse Bhubaneswar Taxi Options:",
      detail: "When selecting our Bhubaneswar cab service for your journey",
    },
  ],
  howToBookHeading: "How to Book a Bhubaneswar Airport Taxi?",
  howToBookInfo:
    "Sewak Travels solves the issue of booking a Bhubaneswar Cab by offering premium services at affordable Bhubaneswar taxi rates, complete with a pickup-drop facility and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit https://sewaktravels.com/, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, proceed to payment.\n",
  taxiServicesHeading:
    "Various Taxi Services in Bhubaneswar Airport by Sewak Travels",
  taxiServices: [
    {
      title: "Outstation Cabs from Bhubaneswar Airport:",
      description:
        "Sewak Travels offers reliable outstation cabs from Bhubaneswar Airport",
    },
    {
      title: "Local Taxis from Bhubaneswar Airport:",
      description:
        "Need a ride within Bhubaneswar? We make it easy to book local taxis from Bhubaneswar Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "Corporate Airport Transfers:",
      description:
        "We also cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "Taxi Service in Bhubaneswar:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: "Easy Bhubaneswar Airport Cab Booking for Every Need",
  easyBookingInfo:
    "Whether you are visiting Bhubaneswar for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Bhubaneswar Airport taxi contact number and online platforms make it easy for you to book a cab within minutes.\n",
  easyBookingPoints: [
    "Affordable Bhubaneswar Airport Taxi Fare",
    "Quick & Reliable Airport Taxi Service",
    "Car Rental Services for Bhubaneswar Airport",
  ],
  bookingCallToAction:
    "Book your Bhubaneswar Airport cab now with Sewak Travels and enjoy comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Bhubaneswar Airport taxi booking. Let Sewak Travels make your airport commute effortless!",
  thingsToKnowHeading: "Things To Know About Bhubaneswar",
  thingsToKnowInfo:
    'Bhubaneswar, Odisha, is known for its ancient temples, vibrant culture, and as a gateway to important tourist destinations like Puri and Konark. This city, also known as the "Temple City of India," combines history, architecture, and nature, offering visitors a unique blend of experiences. Bhubaneswar is well-connected by road, rail, and air, making it a must-visit destination for travelers eager to explore Odisha’s rich heritage and natural beauty.\n',
  faqHeading:
    "Frequently Asked Questions About Bhubaneswar Airport Cab Booking",
  faqs: [
    {
      question: "How can I book a Bhubaneswar Airport cab?",
      answer:
        "You can book a Bhubaneswar Airport taxi easily by visiting our website sewaktravels.com, selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Bhubaneswar?",
      answer:
        "Sewak Travels provides intra-city cab transfers that allow users to book cabs to and from the Bhubaneswar Airport in Bhubaneswar.",
    },
    {
      question:
        "What types of vehicles do you offer for Bhubaneswar Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Bhubaneswar Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Bhubaneswar Airport cabs?",
      answer:
        "Our Bhubaneswar Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Absolutely! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question:
        "Can I book a cab for outstation travel from Bhubaneswar Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Bhubaneswar Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with your Bhubaneswar cab bookings.",
    },
    {
      question: "Do you provide local sightseeing cabs in Bhubaneswar?",
      answer:
        "Yes, we offer local taxi services for sightseeing and exploring the beautiful attractions in and around Bhubaneswar.",
    },
    {
      question: "What safety measures are in place for your cab services?",
      answer:
        "We prioritize passenger safety by following standard sanitization protocols, ensuring our vehicles are clean and safe for travel.",
    },
  ],
};
export const chandigarhTaxiData = {
  pageTitle: "Book Chandigarh Airport Cabs",
  mainHeading: "Book Chandigarh Airport Cabs on Sewak Travels",
  subHeading: "Get smooth airport pickup and drop services in Chandigarh.",
  airportInfo:
    "Chandigarh Airport is important for travel and tourism in India. It opened on August 15, 1947, and serves as a gateway to the lovely city of Chandigarh and nearby areas. The airport is just 10 km from the city center, making it easy for visitors to reach. \n",
  serviceInfo:
    "At Sewak Travels, we aim to provide quick and dependable service every time. Our drivers will help with your luggage and ensure you have a smooth ride from the moment you arrive at Chandigarh Airport. We know travel can be stressful, so we offer flexible booking and real-time tracking for your cab.Whether you are here for a short time or heading somewhere else, we ensure your trips are relaxing.\n",
  distanceHeading: "Chandigarh to Chandigarh Airport Cab Distance  ",
  distanceInfo:
    "The distance from Chandigarh Airport to the city center is about 10 km, depending on the route and stops. For a comfortable and easy trip, book a Chandigarh Airport taxi through Sewak Travels' user-friendly online platform. We ensure a great experience for both airport pickups and drops, making your travel simple and reliable.\n",
  bookingHeading: "Online Chandigarh Airport Cab Booking with Sewak Travels",
  bookingInfo:
    "We offer reliable, comfortable, and timely online Chandigarh Airport cab booking. Whether you are arriving or leaving Chandigarh, our Chandigarh Airport taxi service ensures smooth travel from start to finish. With modern vehicles and skilled drivers, we provide the best airport cab services in Chandigarh.\n",
  img: "/assets/img/LocationDifferent/Chandigarh-airport.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book a Chandigarh Cab? ",
  whyChooseSubHeading:
    "Book your Agra Cabs effortlessly: When you choose Sewak for your cab service in Chandigarh, you get:",
  whyChooseInfo: [
    {
      title: "24/7 Booking & Travel Assistance:",
      detail:
        "Our Chandigarh Airport taxi service is available around the clock",
    },
    {
      title: "Outstation Cabs:",
      detail:
        "Need to travel outside Chandigarh? We offer outstation cabs from Chandigarh Airport",
    },
    {
      title: "Competitive Rates:",
      detail: "With Chandigarh Airport taxi per km rates",
    },
    {
      title: "Prepaid and Hassle-Free:",
      detail:
        "You can book your prepaid taxi Chandigarh Airport in advance or upon arrival",
    },
    {
      title: "Professional Drivers:",
      detail:
        "Sewak's drivers are trained professionals who care about your safety and comfort. They are knowledgeable about the routes and can share local tips while speaking the local language. Our Chandigarh taxi has full air conditioning",
    },
    {
      title: "Comfortable Fleet:",
      detail: "Choose from a range of vehicles that fit your travel needs",
    },
    {
      title: "Diverse Chandigarh Taxi Options:",
      detail:
        "Our Chandigarh cab service includes three types of cabs: 4-seater sedans (Dzire",
    },
  ],
  howToBookHeading: "How to Book a Chandigarh Airport Taxi?",
  howToBookInfo:
    "Sewak Travels makes booking a Chandigarh Cab easy, offering top services at fair Chandigarh taxi rates, along with pickup-drop service and quick customer support. Just visit Sewak Travels at https://sewaktravels.com/, click on the cabs icon, choose your preferences (outstation, local or hourly, corporate, and airport cab service), pick your destination and date, select the car and rates that fit your needs and budget, fill in the details, and proceed to payment.\n",
  taxiServicesHeading:
    "Various Taxi Services in Chandigarh Airport by Sewak Travels",
  taxiServices: [
    {
      title: "Outstation Cabs from Chandigarh Airport:",
      description:
        "Sewak Travels provides reliable outstation cabs from Chandigarh Airport",
    },
    {
      title: "Local Taxis from Chandigarh Airport:",
      description:
        "Need a ride within Chandigarh? Booking local taxis from Chandigarh Airport is simple with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "Corporate Airport Transfers:",
      description:
        "We also support businesses with corporate airport transfer services and employee transport. Contact us for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "Airport Taxi at Per Km Rates:",
      detail: "With our clear pricing model",
    },
    {
      title: "Prepaid Taxi Service:",
      detail: "To avoid last-minute hassles",
    },
    {
      title: "Taxi Service in Chandigarh:",
      detail: "Besides airport transfers",
    },
  ],
  easyBookingHeading: "Easy Chandigarh Airport Cab Booking for Every Need",
  easyBookingInfo:
    "Whether you are visiting Chandigarh for business, leisure, or heading out, Sewak Travels gives you easy and reliable cab booking options. Our Chandigarh Airport taxi contact number and online platforms make booking quick and simple.\n",
  easyBookingPoints: [
    "Affordable Chandigarh Airport Taxi Fare",
    "Quick & Reliable Airport Taxi Service",
    "Car Rental Services for Chandigarh Airport",
  ],
  bookingCallToAction:
    "Book your Chandigarh Airport cab now with Sewak Travels and enjoy comfortable rides at great prices. Call us at +91-837-782-8828 or visit our website for quick and easy Chandigarh Airport taxi booking. Let Sewak Travels make your airport commute easy!",
  thingsToKnowHeading: "Things To Know About Chandigarh",
  thingsToKnowInfo:
    "Chandigarh is known for its unique buildings, beautiful gardens, and vibrant culture, making it an important spot in northern India. Designed by architect Le Corbusier, this city has modern planning and stunning views. Chandigarh is well-connected by road, rail, and air, making it a must-visit for travelers wanting to explore the region's rich heritage and natural beauty. Chandigarh is the capital for both Punjab and Haryana. The city is home to a diverse population, with a blend of cultures, languages, and traditions, making it a melting pot of Indian heritage. The annual festivals, such as Baisakhi and Diwali, bring together communities, showcasing vibrant cultural celebrations filled with music, dance, and traditional food.\nChandigarh is also recognized for its vibrant lifestyle and numerous attractions, such as Rock Garden, Sukhna Lake, and Zakir Hussain Rose Garden, which showcase the city’s artistic flair and ecological beauty. The local food scene is diverse, featuring delicious Punjabi cuisine that will satisfy any food lover. You can find numerous eateries, from street food stalls to fine dining restaurants, ensuring a delightful culinary experience during your stay.\nThe city's unique architecture and well-planned layout make it ideal for long walks or cycling, providing a perfect blend of urban life and natural surroundings. Sewak Travels not only ensures a seamless transfer but also helps you discover the beauty and charm of Chandigarh, making your journey as enriching as the destination itself.\n",
  faqHeading: "Frequently Asked Questions About Chandigarh Airport Cab Booking",
  faqs: [
    {
      question: "How can I book a Chandigarh Airport cab?",
      answer:
        "You can book a Chandigarh Airport taxi easily by visiting our website sewaktravels.com, selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What airport cab services does Sewak Travels provide in Chandigarh?",
      answer:
        "Sewak Travels offers intra-city cab transfers, allowing users to book cabs to and from Chandigarh Airport.",
    },
    {
      question:
        "What types of vehicles do you offer for Chandigarh Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Chandigarh Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Chandigarh Airport cabs?",
      answer:
        "Our Chandigarh Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Yes! All our drivers are professionals, ensuring your safety and comfort during your journey.",
    },
    {
      question:
        "Can I book a cab for outstation travel from Chandigarh Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Chandigarh Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to help you with your Chandigarh cab bookings.",
    },
    {
      question: "Do you provide local sightseeing cabs in Chandigarh?",
      answer:
        "Yes, we offer local taxi services for sightseeing and exploring the attractions in and around Chandigarh.",
    },
    {
      question: "What safety measures are in place for your cab services?",
      answer:
        "We prioritize passenger safety by following sanitization protocols, ensuring our vehicles are clean and safe for travel.",
    },
  ],
};
export const chennaiTaxiData = {
  pageTitle: "Book Chennai Airport Cabs",
  mainHeading: "Book Chennai Airport Cabs on Sewak Travels",
  subHeading: "Enjoy smooth Airport Pickup & Drop Services in Chennai.",
  airportInfo:
    "Chennai Airport, also known as Chennai International Airport, is a key hub for both domestic and international travel in India. Established in 1932, this historic airport serves as a gateway to the vibrant culture, beaches, and historical sites of Tamil Nadu. Located just 15 km from the city center, Chennai Airport provides easy access for tourists looking to explore the region. Our commitment to excellence ensures that you receive prompt and reliable service at every step. From the moment you arrive at Chennai Airport, our drivers will be ready to assist you with your luggage and ensure a smooth ride to your destination. We understand that travel can be stressful, which is why we offer flexible booking options and real-time tracking for your cab. \n",
  serviceInfo:
    "At Sewak Travels, we make your travel experience smoother with our reliable Chennai Airport cab service. Whether you’re flying in for a quick visit or heading out, we ensure your journey to and from Chennai Airport is stress-free and on time.\n",
  distanceHeading: "Chennai to Chennai Airport Cab Distance",
  distanceInfo:
    "The distance from Chennai Airport to the city center is approximately 15 km, depending on the route and any stops made. For a comfortable and stress-free journey, simply book a Chennai Airport taxi through Sewak Travel's user-friendly online platform. Whether you are heading to or from the airport, we ensure an amazing experience, making your travel convenient and reliable.\n",
  bookingHeading: "Online Chennai Airport Cab Booking with Sewak Travels",
  bookingInfo:
    "Welcome to Sewak Travels, where we offer reliable, comfortable, and on-time online Chennai Airport cab booking tailored to your needs. Whether you are arriving in or departing from Chennai, our Chennai Airport taxi service ensures a smooth travel experience from start to finish. With a fleet of modern vehicles and professional drivers, we provide the highest standard of airport cab services in Chennai.\n",
  img: "/assets/img/LocationDifferent/Chennai-airport.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book a Chennai Cab?",
  whyChooseSubHeading:
    "Book your Chennai Cabs effortlessly: When you choose Sewak for your cab service in Chennai, you get:",
  whyChooseInfo: [
    {
      title: "24/7 Booking & Travel Assistance:",
      detail: "Our Chennai Airport taxi service operates 24/7",
    },
    {
      title: "Outstation Cabs:",
      detail:
        "Need to travel outside Chennai? We offer outstation cabs from Chennai Airport",
    },
    {
      title: "Competitive Rates:",
      detail: "With Chennai Airport taxi per km rates",
    },
    {
      title: "Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi Chennai Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "Professional Drivers:",
      detail:
        "Sewak's drivers are skilled professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title: "Diverse Chennai Taxi Options:",
      detail: "When selecting our Chennai cab service for your journey",
    },
  ],
  howToBookHeading: "How to Book a Chennai Airport Taxi?",
  howToBookInfo:
    "Sewak Travels solves the issue of booking a Chennai Cab by offering premium services at affordable Chennai taxi rates, complete with a pickup-drop facility and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit https://sewaktravels.com/, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, and proceed to payment.\n",
  taxiServicesHeading:
    "Various Taxi Services in Chennai Airport by Sewak Travels",
  taxiServices: [
    {
      title: "Outstation Cabs from Chennai Airport:",
      description:
        "Sewak Travels offers reliable outstation cabs from Chennai Airport",
    },
    {
      title: "Local Taxis from Chennai Airport:",
      description:
        "Need a ride within Chennai? We make it easy to book local taxis from Chennai Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "Corporate Airport Transfers:",
      description:
        "We also cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "Taxi Service in Chennai:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: "Easy Chennai Airport Cab Booking for Every Need",
  easyBookingInfo:
    "Whether you are visiting Chennai for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Chennai Airport taxi contact number and online platforms make it easy for you to book a cab within minutes.\n",
  easyBookingPoints: [
    "Affordable Chennai Airport Taxi Fare",
    "Quick & Reliable Airport Taxi Service",
    "Car Rental Services for Chennai Airport",
  ],
  bookingCallToAction:
    "Book your Chennai Airport cab now with Sewak Travels and enjoy comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Chennai Airport taxi booking. Let Sewak Travels make your airport commute effortless!",
  thingsToKnowHeading: "Things To Know About Chennai",
  thingsToKnowInfo:
    "Chennai, the capital city of Tamil Nadu, is known for its rich cultural heritage, bustling economy, and picturesque beaches. Famous for its classical music and dance forms, the city boasts several ancient temples and historical landmarks, making it a cultural hub in India. Chennai is also recognized for its thriving IT sector and is home to numerous educational institutions, attracting students from across the country. The city's culinary scene is famous for its South Indian cuisine, featuring dishes like idli, dosa, and filter coffee. Additionally, Chennai is well-connected by road, rail, and air, making it an ideal destination for travelers eager to explore the vibrant culture and heritage of South India.\n",
  faqHeading: "Frequently Asked Questions About Chennai Airport Cab Booking",
  faqs: [
    {
      question: "How can I book a Chennai Airport cab?",
      answer:
        "You can book a Chennai Airport taxi easily by visiting our website sewaktravels.com, selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Chennai?",
      answer:
        "Sewak Travels provides intra-city cab transfers that allow users to book cabs to and from Chennai Airport in Chennai.",
    },
    {
      question:
        "What types of vehicles do you offer for Chennai Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Chennai Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Chennai Airport cabs?",
      answer:
        "Our Chennai Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Absolutely! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question: "Can I book a cab for outstation travel from Chennai Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Chennai Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with your Chennai cab bookings.",
    },
    {
      question: "Do you provide local sightseeing cabs in Chennai?",
      answer:
        "Yes, we offer local taxi services for sightseeing and exploring the beautiful attractions in and around Chennai.",
    },
    {
      question: "What safety measures are in place for your cab services?",
      answer:
        "We prioritize passenger safety by following standard sanitization protocols, ensuring our vehicles are clean and safe for travel.",
    },
  ],
};
export const chhatrapatiTaxiData = {
  pageTitle: "Book Chhatrapati Airport Cabs",
  mainHeading: "Book Chhatrapati Shivaji Airport Cabs on Sewak Travels",
  subHeading:
    "Enjoy easy airport pickup and drop services at Chhatrapati Shivaji.",
  airportInfo:
    "Chhatrapati Shivaji Airport, also known as Chhatrapati Shivaji Maharaj International Airport, is an important place for both domestic and international flights in India. Opened in 1932, this important airport is your gateway to the culture, beautiful beaches, and historic sites of Maharashtra. It is only 15 km from the city center, making it simple for travelers to explore.\n",
  serviceInfo:
    " At Sewak Travels, we promise to give you great service from start to finish. When you arrive at Chhatrapati Shivaji Airport, our drivers will help you with your bags and ensure you have a smooth ride to your destination. We understand that travel can be stressful, so we offer easy booking and real-time tracking for your cab. Choose Sewak Travels for a worry-free airport transfer experience so you can focus on what truly matters. Whether you are flying in for a short visit or leaving, we make sure your trip to and from Chhatrapati Shivaji Airport is easy and relaxed. \n",
  distanceHeading:
    "Chhatrapati Shivaji to Chhatrapati Shivaji Airport Cab Distance",
  distanceInfo:
    "The distance from Chhatrapati Shivaji Airport to the city center is about 15 km, depending on the route and any stops along the way. For a comfortable and easy trip, just book a Chhatrapati Shivaji Airport taxi through Sewak Travels’ easy-to-use online platform. Whether you are going to or from the airport, we guarantee a great experience, making your travel simple and reliable.\n",
  bookingHeading:
    "Online Chhatrapati Shivaji Airport Cab Booking with Sewak Travels",
  bookingInfo:
    "We provide reliable, comfortable, and on-time online cab booking for Chhatrapati Shivaji Airport. Whether you are arriving or leaving Chhatrapati Shivaji, our taxi service ensures a smooth travel experience from start to finish. With a fleet of modern vehicles and professional drivers, we offer the best airport cab services in Chhatrapati Shivaji.\n",
  img: "/assets/img/LocationDifferent/Chhatrapati-shivaji-airport.webp",
  whyChooseHeading:
    "Why Choose Sewak Travels to Book a Chhatrapati Shivaji Cab?",
  whyChooseSubHeading:
    "Book your Chhatrapati Shivaji Cabs effortlessly: When you choose Sewak for your cab service in Chhatrapati Shivaji, you get:",
  whyChooseInfo: [
    {
      title: "- 24/7 Booking & Travel Assistance:",
      detail: "Our Chhatrapati Shivaji Airport taxi service operates 24/7",
    },
    {
      title: "- Outstation Cabs:",
      detail:
        "Need to travel outside Chhatrapati Shivaji? We offer outstation cabs from Chhatrapati Shivaji Airport",
    },
    {
      title: "- Competitive Rates:",
      detail: "With Chhatrapati Shivaji Airport taxi per km rates",
    },
    {
      title: "- Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi at Chhatrapati Shivaji Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "- Professional Drivers:",
      detail:
        "Sewak's drivers are skilled professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "- Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title: "- Diverse Chhatrapati Shivaji Taxi Options:",
      detail:
        "When selecting our Chhatrapati Shivaji cab service for your journey",
    },
  ],
  howToBookHeading: "How to Book a Chhatrapati Shivaji Airport Taxi?",
  howToBookInfo:
    "Sewak Travels makes it easy to book a cab with great services at affordable rates, along with pickup and drop options and fast customer support. Just go to Sewak Travels, and everything you need is at your fingertips. Visit https://sewaktravels.com/, click on the cabs icon, select your choice (outstation, local, hourly, corporate, or airport cab service), pick your destination and date, choose the vehicle and rates that fit your needs and budget, fill in the required details, and proceed to payment.\n",
  taxiServicesHeading:
    "Various Taxi Services in Chhatrapati Shivaji Airport by Sewak Travels",
  taxiServices: [
    {
      title: "Outstation Cabs:",
      description: "We offer reliable outstation cabs from the airport",
    },
    {
      title: "Local Taxis:",
      description:
        "Need a ride in Chhatrapati Shivaji? We make it simple to book local taxis from the airport in just a few clicks. Our local cab package offers great value for city tours and short trips.",
    },
    {
      title: "Corporate Transfers:",
      description:
        "We also provide services for businesses with corporate airport transfer services and employee transport. Contact us for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "- Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "-Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "- Taxi Service in Chhatrapati Shivaji:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: "Easy Chhatrapati Shivaji Cab Booking for Every Need",
  easyBookingInfo:
    "Whether you’re visiting Chhatrapati Shivaji for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Chhatrapati Shivaji Airport taxi contact number and online platforms make it easy for you to book a cab within minutes.\n",
  easyBookingPoints: [
    "- Affordable Chhatrapati Shivaji Airport Taxi Fare",
    "- Quick & Reliable Airport Taxi Service",
    "- Car Rental Services for Chhatrapati Shivaji Airport",
  ],
  bookingCallToAction:
    "Book your Chhatrapati Shivaji Airport cab now with Sewak Travels and enjoy hassle-free, comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Chhatrapati Shivaji Airport taxi booking. Let Sewak Travels make your airport commute effortless!",
  thingsToKnowHeading: "Things To Know About Chhatrapati Shivaji",
  thingsToKnowInfo:
    "Chhatrapati Shivaji, the capital city of Maharashtra, is known for its rich culture, busy economy, and beautiful beaches. Famous for its music and dance, the city has many old temples and historic sites, making it a cultural center in India. It has a strong IT sector and many schools, attracting students from across the country. The food scene is famous for local dishes that show the flavors of Maharashtra. Plus, Chhatrapati Shivaji is well-connected by road, rail, and air, making it a great place for travelers eager to explore the vibrant culture and heritage of Maharashtra.\n",
  faqHeading:
    "Frequently Asked Questions About Chhatrapati Shivaji Airport Cab Booking",
  faqs: [
    {
      question: "How can I book a Chhatrapati Shivaji Airport cab?",
      answer:
        "You can easily book a taxi by visiting our website sewaktravels.com, choosing the cab option, selecting your preferences, and completing the booking.",
    },
    {
      question: "What cab services does Sewak Travels provide?",
      answer:
        "Sewak Travels offers cab transfers to and from Chhatrapati Shivaji Airport.",
    },
    {
      question: "What types of vehicles do you have for the taxi service?",
      answer:
        "We offer different options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles for comfortable rides.",
    },
    {
      question: "Is there a prepaid taxi option?",
      answer:
        "Yes, you can book a prepaid taxi for your transfer through our platform for a hassle-free experience.",
    },
    {
      question: "Can I hire a taxi for outstation trips?",
      answer:
        "Yes! We provide outstation cab services to meet your travel needs.",
    },
    {
      question: "What are the per-kilometer rates for taxis?",
      answer:
        "Our rates are clear and based on the distance you travel. For a detailed quote, please visit our website.",
    },
    {
      question: "Are the drivers trained and professional?",
      answer:
        "Yes, all our drivers are experienced, well-trained, and dedicated to ensuring your safety and comfort during the ride.",
    },
    {
      question: "What payment methods do you accept?",
      answer:
        "We accept various payment methods, including credit/debit cards, net banking, and mobile wallets.",
    },
    {
      question: "How do I cancel my cab booking?",
      answer:
        "You can easily cancel your booking through our website or by contacting our customer service team. Please review our cancellation policy for any applicable fees.",
    },
    {
      question: "Is there customer support service available?",
      answer:
        "Yes, we offer 24/7 customer support to assist you with any inquiries or issues related to your cab booking. Feel free to reach out at any time.",
    },
  ],
};
export const coimbatoreTaxiData = {
  pageTitle: "Book Coimbatore Airport Cabs",
  mainHeading: "Book Coimbatore Airport Cabs on Sewak Travels",
  subHeading: "Enjoy smooth Airport Pickup & Drop Services in Coimbatore.",
  airportInfo:
    "Coimbatore International Airport plays an important role in both civilian air travel and as a significant hub for tourism in South India. Located just 13 km from the city center, Coimbatore Airport provides easy access for tourists looking to explore the region. Our commitment to excellence ensures that you receive prompt and reliable service at every step. From the moment you arrive at Coimbatore Airport, our drivers will be ready to assist you with your luggage and ensure a smooth ride to your destination. We understand that travel can be stressful, which is why we offer flexible booking options and real-time tracking for your cab. Choose Sewak Travels for a hassle-free airport transfer experience that allows you to focus on what truly matters—your journey and the experiences that await you in Coimbatore.\n",
  serviceInfo:
    "At Sewak Travels, we make your travel experience smoother with our reliable Coimbatore Airport cab service. Whether you are flying in for a quick visit or heading out, we ensure your journey to and from Coimbatore Airport is relaxed.\n",
  distanceHeading: "Coimbatore to Coimbatore Airport Cab Distance",
  distanceInfo:
    "The distance from Coimbatore Airport to the city center is approximately 13 km, depending on the route and any stops made. For a comfortable and stress-free journey, simply book a Coimbatore Airport taxi through Sewak Travel's user-friendly online platform. Whether you are heading to or from the airport, we ensure an amazing experience, making your travel convenient and reliable.\n",
  bookingHeading: "Online Coimbatore Airport Cab Booking with Sewak Travels",
  bookingInfo:
    "Welcome to Sewak Travels, where we offer reliable, comfortable, and on-time Online Coimbatore Airport cab booking tailored to your needs. Whether you are arriving in or departing from Coimbatore, our Coimbatore Airport taxi service ensures a smooth travel experience from start to finish. With a fleet of modern vehicles and professional drivers, we provide top-notch airport cab services in Coimbatore.\n",
  img: "/assets/img/LocationDifferent/Coimbatore-airport.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book a Coimbatore Cab?",
  whyChooseSubHeading:
    "Book your Coimbatore Cabs effortlessly: When you choose Sewak for your cab service in Coimbatore, you get:",
  whyChooseInfo: [
    {
      title: "24/7 Booking & Travel Assistance:",
      detail: "Our Coimbatore Airport taxi service operates 24/7",
    },
    {
      title: "Outstation Cabs:",
      detail:
        "Need to travel outside Coimbatore? We offer outstation cabs from Coimbatore Airport",
    },
    {
      title: "Competitive Rates:",
      detail: "With Coimbatore Airport taxi per km rates",
    },
    {
      title: "Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi Coimbatore Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "Professional Drivers:",
      detail:
        "Sewak's drivers are skilled professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title: "Diverse Coimbatore Taxi Options:",
      detail: "When selecting our Coimbatore cab service for your journey",
    },
  ],
  howToBookHeading: "How to Book a Coimbatore Airport Taxi?",
  howToBookInfo:
    "Sewak Travels solves the issue of booking a Coimbatore Cab by offering premium services at affordable Coimbatore taxi rates, complete with a pickup-drop facility and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit https://sewaktravels.com/, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, and proceed to payment.\n",
  taxiServicesHeading:
    "Various Taxi Services in Coimbatore Airport by Sewak Travels",
  taxiServices: [
    {
      title: "Outstation Cabs from Coimbatore Airport:",
      description:
        "Sewak Travels offers reliable outstation cabs from Coimbatore Airport",
    },
    {
      title: "Local Taxis from Coimbatore Airport:",
      description:
        "Need a ride within Coimbatore? We make it easy to book local taxis from Coimbatore Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "Corporate Airport Transfers:",
      description:
        "We also cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "Taxi Service in Coimbatore:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: "Easy Coimbatore Airport Cab Booking for Every Need",
  easyBookingInfo:
    "Whether you're in Coimbatore for business, leisure, or an outstation trip, Sewak Travels provides the most convenient and reliable cab booking options. You can easily book a cab within minutes using our Coimbatore Airport taxi contact number or through our online platforms.\n",
  easyBookingPoints: [
    "Affordable Coimbatore Airport Taxi Fare",
    "Quick & Reliable Airport Taxi Service",
    "Car Rental Services for Coimbatore Airport",
  ],
  bookingCallToAction:
    "Book your Coimbatore Airport cab now with Sewak Travels and enjoy comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Coimbatore Airport taxi booking. Let Sewak Travels make your airport commute effortless!",
  thingsToKnowHeading: "Things to Know About Coimbatore",
  thingsToKnowInfo:
    'Coimbatore, in Tamil Nadu, is an important industrial city with a pleasant climate. It’s near the Western Ghats and is a gateway to hill stations like Ooty and Coonoor. Coimbatore is known for its textile industry, earning it the name "Manchester of South India." The city is well-connected by road, rail, and air, with Coimbatore International Airport as a major hub. Coimbatore also has a rich cultural heritage, tasty South Indian food, and many temples, blending tradition and modern life for visitors.\n',
  faqHeading: "Frequently Asked Questions About Coimbatore Airport Cab Booking",
  faqs: [
    {
      question: "How do I book a taxi from Coimbatore Airport?",
      answer:
        "Booking a taxi from Coimbatore Airport with Sewak Travels is simple. Just go to our website https://sewaktravels.com, select the Airport cab service, choose your vehicle, and complete the booking. You can also call us at +91-837-782-8828.",
    },
    {
      question:
        "What are the taxi charges from Coimbatore Airport to the city?",
      answer:
        "Taxi charges from Coimbatore Airport to the city vary based on the vehicle you choose. We have sedans, SUVs, and luxury cars at competitive prices. Our clear per-kilometer rates mean no hidden costs.",
    },
    {
      question: "Can I book an outstation cab from Coimbatore Airport?",
      answer:
        "Yes, Sewak Travels provides outstation cabs from Coimbatore Airport. You can book a ride to nearby places at affordable prices by selecting the outstation option when you book your cab.",
    },
    {
      question: "Do you offer prepaid taxis at Coimbatore Airport?",
      answer:
        "Yes, we have prepaid taxi services at Coimbatore Airport. Booking in advance helps you avoid last-minute issues, ensuring a smooth and timely ride.",
    },
    {
      question: "Are your airport cabs available 24/7?",
      answer:
        "Yes, our Coimbatore Airport cab service runs 24/7, so you can book a cab at any time, whether for an early morning flight or a late-night arrival.",
    },
    {
      question: "How far is Coimbatore Airport from the city center?",
      answer:
        "Coimbatore Airport is about 13 km from the city center, which takes around 30-40 minutes to drive, depending on traffic.",
    },
    {
      question:
        "What kind of vehicles are available for Coimbatore Airport cab booking?",
      answer:
        "We offer various vehicles, including 4-seater sedans, 7-seater SUVs, and premium cars. You can pick the one that fits your needs.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Yes, all our drivers are highly skilled, professional, and know local routes well. They focus on passenger safety and comfort.",
    },
    {
      question: "How can I cancel or modify my booking?",
      answer:
        "You can easily cancel or change your booking by visiting our website or calling our customer support team. Please check our cancellation policy for more information.",
    },
    {
      question: "What if my flight is delayed?",
      answer:
        "We know flights can be delayed. Our drivers keep track of flight arrivals and will be there when your flight lands, so your ride is not affected",
    },
  ],
};
export const damanTaxiData = {
  pageTitle: "Book Daman Airport Cabs",
  mainHeading: "Book Daman Airport Cabs on Sewak Travels",
  subHeading: "Enjoy smooth Airport Pickup & Drop Services in Daman.",
  airportInfo:
    "Daman Airport, also known as Daman International Airport, is a key hub for both domestic and international travel in India. Established in 1932, this historic airport serves as a gateway to the vibrant culture, beaches, and historical sites of Daman. Located just 15 km from the city center, Daman Airport provides easy access for tourists looking to explore the region. Our commitment to excellence ensures that you receive prompt and reliable service at every step. From the moment you arrive at Daman Airport, our drivers will be ready to assist you with your luggage and ensure a smooth ride to your destination. We understand that travel can be stressful, which is why we offer flexible booking options and real-time tracking for your cab. \n",
  serviceInfo:
    "Choose Sewak Travels for a smooth airport transfer experience that allows you to focus on what truly matters—your journey and the experiences that await you in Daman.Whether you are flying in for a quick visit or heading out, we ensure your journey to and from Daman Airport is relaxed.\n",
  distanceHeading: "Daman to Daman Airport Cab Distance",
  distanceInfo:
    "The distance from Daman Airport to the city center is approximately 15 km, depending on the route and any stops made. For a comfortable and stress-free journey, simply book a Daman Airport taxi through Sewak Travel's user-friendly online platform. Whether you are heading to or from the airport, we ensure an amazing experience, making your travel convenient and reliable.\n",
  bookingHeading: "Online Daman Airport Cab Booking with Sewak Travels",
  bookingInfo:
    "Welcome to Sewak Travels, where we offer reliable, comfortable, and on-time online Daman Airport cab booking tailored to your needs. Whether you are arriving in or departing from Daman, our Daman Airport taxi service ensures a smooth travel experience from start to finish. With a fleet of modern vehicles and professional drivers, we provide the highest standard of airport cab services in Daman.\n",
  img: "/assets/img/LocationDifferent/Daman-airport.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book a Daman Cab?",
  whyChooseSubHeading:
    "Book your Daman Cabs effortlessly: When you choose Sewak for your cab service in Daman, you get:",
  whyChooseInfo: [
    {
      title: "24/7 Booking & Travel Assistance:",
      detail: "Our Daman Airport taxi service operates 24/7",
    },
    {
      title: "Outstation Cabs:",
      detail:
        "Need to travel outside Daman? We offer outstation cabs from Daman Airport",
    },
    {
      title: "Competitive Rates: With Daman Airport taxi per km rates",
      detail:
        "enjoy transparent pricing with no hidden charges. Our budget-friendly prices ensure the best cab service in Daman without compromising quality or comfort.",
    },
    {
      title: "Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi Daman Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "Professional Drivers:",
      detail:
        "Sewak's drivers are skilled professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title: "Diverse Daman Taxi Options:",
      detail: "When selecting our Daman cab service for your journey",
    },
  ],
  howToBookHeading: "How to Book a Daman Airport Taxi?",
  howToBookInfo:
    "Sewak Travels solves the issue of booking a Daman Cab by offering premium services at affordable Daman taxi rates, complete with a pickup-drop facility and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit https://sewaktravels.com/, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, and proceed to payment.\n",
  taxiServicesHeading:
    "Various Taxi Services in Daman Airport by Sewak Travels",
  taxiServices: [
    {
      title: "Outstation Cabs from Daman Airport:",
      description:
        "Sewak Travels offers reliable outstation cabs from Daman Airport",
    },
    {
      title: "Local Taxis from Daman Airport:",
      description:
        "Need a ride within Daman? We make it easy to book local taxis from Daman Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "Corporate Airport Transfers:",
      description:
        "We also cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "Taxi Service in Daman:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: "Easy Daman Airport Cab Booking for Every Need",
  easyBookingInfo:
    "Whether you are visiting Daman for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Daman Airport taxi contact number and online platforms make it easy for you to book a cab within minutes.\n",
  easyBookingPoints: [
    "Affordable Daman Airport Taxi Fare",
    "Quick & Reliable Airport Taxi Service",
    "Car Rental Services for Daman Airport",
  ],
  bookingCallToAction:
    "Book your Daman Airport cab now with Sewak Travels and enjoy comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Daman Airport taxi booking. Let Sewak Travels make your airport commute effortless!",
  thingsToKnowHeading: "Things To Know About Daman",
  thingsToKnowInfo:
    "Daman, part of the union territory of Daman and Diu, is known for its rich cultural heritage, beautiful beaches, and scenic landscapes. Famous for its colonial architecture and vibrant festivals, the area boasts several ancient churches and forts, making it a cultural hub in India. Daman is also recognized for its relaxing beach resorts and is a popular getaway for tourists seeking a break from city life. The region's culinary scene is famous for its seafood, featuring dishes like fish curry and prawn fry. Additionally, Daman is well-connected by road, rail, and air, making it an ideal destination for travelers eager to explore the vibrant culture and heritage of this coastal paradise.\n",
  faqHeading: "Frequently Asked Questions About Daman Airport Cab Booking",
  faqs: [
    {
      question: "How can I book a Daman Airport cab?",
      answer:
        "You can book a Daman Airport taxi easily by visiting our website sewaktravels.com, selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Daman?",
      answer:
        "Sewak Travels provides intra-city cab transfers that allow users to book cabs to and from Daman Airport in Daman.",
    },
    {
      question:
        "What types of vehicles do you offer for Daman Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Daman Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Daman Airport cabs?",
      answer:
        "Our Daman Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Absolutely! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question: "Can I book a cab for outstation travel from Daman Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Daman Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with your Daman cab bookings.",
    },
    {
      question: "Do you provide local sightseeing cabs in Daman?",
      answer:
        "Yes, we offer local taxi services for sightseeing and exploring the beautiful attractions in and around Daman.",
    },
    {
      question: "What safety measures are in place for your cab services?",
      answer:
        "We prioritize passenger safety by following standard sanitization protocols, ensuring our vehicles are clean and safe for travel.",
    },
  ],
};
export const darbhangaTaxiData = {
  pageTitle: "Book Darbhanga Airport Cabs",
  mainHeading: "Book Darbhanga Airport Cabs on Sewak Travels",
  subHeading: "Enjoy Easy Airport Pickup & Drop Services in Darbhanga.",
  airportInfo:
    "Darbhanga Airport, also called Darbhanga International Airport, is an important place for travel in India. It opened in 1932 and helps people explore the culture, history, and beautiful sights of Darbhanga. Located just 15 km from the city center, the airport makes it easy for visitors to get around.\n",
  serviceInfo:
    "At Sewak Travels, we promise quick and reliable service. Our drivers will help you with your bags and ensure a smooth ride to your destination. We know travel can be stressful, so we offer easy booking options and real-time cab tracking.Whether you're visiting for a short time or heading out, we make sure your trip to and from Darbhanga Airport is comfortable.\n",
  distanceHeading: "Darbhanga to Darbhanga Airport Cab Distance",
  distanceInfo:
    "The distance from Darbhanga Airport to the city center is approximately 15 km, depending on the route and any stops made. For a comfortable and stress-free journey, simply book a Darbhanga Airport taxi through Sewak Travel's user-friendly online platform. Whether you are heading to or from the airport, we ensure an amazing experience, making your travel convenient and reliable.\n",
  bookingHeading: "Online Darbhanga Airport Cab Booking with Sewak Travels",
  bookingInfo:
    "Welcome to Sewak Travels, where we offer reliable, comfortable, and on-time online Darbhanga Airport cab booking tailored to your needs. Whether you are arriving in or departing from Darbhanga, our Darbhanga Airport taxi service ensures a smooth travel experience from start to finish. With a fleet of modern vehicles and professional drivers, we provide the highest standard of airport cab services in Darbhanga.\n",
  img: "/assets/img/LocationDifferent/Darbhanga-airport.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book a Darbhanga Cab?",
  whyChooseSubHeading:
    "Book your Darbhanga Cabs effortlessly: When you choose Sewak for your cab service in Darbhanga, you get:",
  whyChooseInfo: [
    {
      title: "24/7 Booking & Travel Assistance:",
      detail: "Our Darbhanga Airport taxi service operates 24/7",
    },
    {
      title: "Outstation Cabs:",
      detail:
        "Need to travel outside Darbhanga? We offer outstation cabs from Darbhanga Airport",
    },
    {
      title: "Competitive Rates:",
      detail: "With Darbhanga Airport taxi per km rates",
    },
    {
      title: "Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi Darbhanga Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "Professional Drivers:",
      detail:
        "Sewak's drivers are skilled professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title: "Diverse Darbhanga Taxi Options:",
      detail: "When selecting our Darbhanga cab service for your journey",
    },
  ],
  howToBookHeading: "How to Book a Darbhanga Airport Taxi?",
  howToBookInfo:
    "Sewak Travels solves the issue of booking a Darbhanga Cab by offering premium services at affordable Darbhanga taxi rates, complete with a pickup-drop facility and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit https://sewaktravels.com/, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, and proceed to payment.\n",
  taxiServicesHeading:
    "Various Taxi Services in Darbhanga Airport by Sewak Travels",
  taxiServices: [
    {
      title: "Outstation Cabs from Darbhanga Airport:",
      description:
        "Sewak Travels offers reliable outstation cabs from Darbhanga Airport",
    },
    {
      title: "Local Taxis from Darbhanga Airport:",
      description:
        "Need a ride within Darbhanga? We make it easy to book local taxis from Darbhanga Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "Corporate Airport Transfers:",
      description:
        "We also cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "Taxi Service in Darbhanga:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: "Easy Darbhanga Airport Cab Booking for Every Need",
  easyBookingInfo:
    "Whether you are visiting Darbhanga for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Darbhanga Airport taxi contact number and online platforms make it easy for you to book a cab within minutes.\n",
  easyBookingPoints: [
    "Affordable Darbhanga Airport Taxi Fare",
    "Quick & Reliable Airport Taxi Service",
    "Car Rental Services for Darbhanga Airport",
  ],
  bookingCallToAction:
    "Book your Darbhanga Airport cab now with Sewak Travels and enjoy comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Darbhanga Airport taxi booking. Let Sewak Travels make your airport commute effortless!",
  thingsToKnowHeading: "Things To Know About Darbhanga",
  thingsToKnowInfo:
    "Darbhanga, located in the Indian state of Bihar, is known for its rich cultural heritage, historical sites, and vibrant festivals. Famous for its ancient architecture and significant landmarks, the area boasts several temples, palaces, and cultural events, making it a cultural hub in India. Darbhanga is also recognized for its educational institutions and is a popular destination for tourists seeking a glimpse into the region's traditions and history. The region's culinary scene is renowned for its authentic Bihari dishes, including Litti Chokha and Samosas. Additionally, Darbhanga is well-connected by road, rail, and air, making it an ideal destination for travelers eager to explore the rich culture and heritage of this historical city.\n",
  faqHeading: "Frequently Asked Questions About Darbhanga Airport Cab Booking",
  faqs: [
    {
      question: "How can I book a Darbhanga Airport cab?",
      answer:
        "You can book a Darbhanga Airport taxi easily by visiting our website sewaktravels.com, selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Darbhanga?",
      answer:
        "Sewak Travels provides intra-city cab transfers that allow users to book cabs to and from Darbhanga Airport in Darbhanga.",
    },
    {
      question:
        "What types of vehicles do you offer for Darbhanga Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Darbhanga Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Darbhanga Airport cabs?",
      answer:
        "Our Darbhanga Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Absolutely! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question:
        "Can I book a cab for outstation travel from Darbhanga Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Darbhanga Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with your Darbhanga cab bookings and inquiries.",
    },
    {
      question: "What COVID-19 safety measures are in place?",
      answer:
        "We prioritize passenger safety by following strict sanitization protocols and ensuring our vehicles are thoroughly cleaned before and after each ride.",
    },
    {
      question:
        "What is the approximate distance from Darbhanga Airport to the city center?",
      answer:
        "The distance from Darbhanga Airport to the city center is about 15 km.",
    },
  ],
};
export const dehradunTaxiData = {
  pageTitle: "Book Dehradun Airport Cabs",
  mainHeading: "Book Dehradun Airport Cabs on Sewak Travels",
  subHeading: "Enjoy Smooth Airport Pickup & Drop Services in Dehradun",
  airportInfo:
    "Dehradun Airport, or Jolly Grant Airport, is an important hub for travelers in India. Since it opened in 1994, it has become the gateway to the beautiful landscapes and rich culture of Dehradun and Uttarakhand. Located just 25 km from the city center, the airport is easy to access for tourists wanting to explore the area.\n",
  serviceInfo:
    "At Sewak Travels, we promise quick and reliable service. Our drivers are ready to help you with your luggage when you arrive at Dehradun Airport, ensuring you have a smooth ride to your destination. We know that traveling can be stressful, so we offer easy booking options and real-time tracking for your cab.Choose Sewak Travels for a hassle-free airport transfer experience. Whether you’re here for a short visit or heading out, we make your journey to and from Dehradun Airport relaxing.\n",
  distanceHeading: "Dehradun to Dehradun Airport Cab Distance",
  distanceInfo:
    "The distance from Dehradun Airport to the city center is approximately 25 km, depending on the route and any stops made. For a comfortable and stress-free journey, simply book a Dehradun Airport taxi through Sewak Travel's user-friendly online platform. Whether you are heading to or from the airport, we ensure an amazing experience, making your travel convenient and reliable.\n",
  bookingHeading: "Online Dehradun Airport Cab Booking with Sewak Travels",
  bookingInfo:
    "Welcome to Sewak Travels, where we offer reliable, comfortable, and on-time online Dehradun Airport cab booking tailored to your needs. Whether you are arriving in or departing from Dehradun, our Dehradun Airport taxi service ensures a smooth travel experience from start to finish. With a fleet of modern vehicles and professional drivers, we provide the highest standard of airport cab services in Dehradun.\n",
  img: "/assets/img/LocationDifferent/Dehradun-airport.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book a Dehradun Cab?",
  whyChooseSubHeading:
    "Book your Dehradun Cabs effortlessly: When you choose Sewak for your cab service in Dehradun, you get:",
  whyChooseInfo: [
    {
      title: "24/7 Booking & Travel Assistance:",
      detail: "Our Dehradun Airport taxi service operates 24/7",
    },
    {
      title: "Outstation Cabs:",
      detail:
        "Need to travel outside Dehradun? We offer outstation cabs from Dehradun Airport",
    },
    {
      title: "Competitive Rates:",
      detail: "With Dehradun Airport taxi per km rates",
    },
    {
      title: "Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi Dehradun Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "Professional Drivers:",
      detail:
        "Sewak's drivers are skilled professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title: "Diverse Dehradun Taxi Options:",
      detail: "When selecting our Dehradun cab service for your journey",
    },
  ],
  howToBookHeading: "How to Book a Dehradun Airport Taxi?",
  howToBookInfo:
    "Sewak Travels solves the issue of booking a Dehradun Cab by offering premium services at affordable Dehradun taxi rates, complete with a pickup-drop facility and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit https://sewaktravels.com/, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, and proceed to payment.\n",
  taxiServicesHeading:
    "Various Taxi Services in Dehradun Airport by Sewak Travels",
  taxiServices: [
    {
      title: "Outstation Cabs from Dehradun Airport:",
      description:
        "Sewak Travels offers reliable outstation cabs from Dehradun Airport",
    },
    {
      title: "Local Taxis from Dehradun Airport:",
      description:
        "Need a ride within Dehradun? We make it easy to book local taxis from Dehradun Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "Corporate Airport Transfers:",
      description:
        "We also cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "Taxi Service in Dehradun:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: "Easy Dehradun Airport Cab Booking for Every Need",
  easyBookingInfo:
    "Whether you are visiting Dehradun for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Dehradun Airport taxi contact number and online platforms make it easy for you to book a cab within minutes.\n",
  easyBookingPoints: [
    "Affordable Dehradun Airport Taxi Fare",
    "Quick & Reliable Airport Taxi Service",
    "Car Rental Services for Dehradun Airport",
  ],
  bookingCallToAction:
    "Book your Dehradun Airport cab now with Sewak Travels and enjoy comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Dehradun Airport taxi booking. Let Sewak Travels make your airport commute effortless!",
  thingsToKnowHeading: "Things To Know About Dehradun",
  thingsToKnowInfo:
    "Dehradun, the capital of Uttarakhand, is renowned for its picturesque landscapes, pleasant climate, and historical significance. Nestled in the Doon Valley, it serves as the gateway to several popular hill stations like Mussoorie and Auli. The city is a blend of modernity and tradition, featuring numerous educational institutions, making it a hub for students. Nature enthusiasts can explore the scenic forests of the Rajaji National Park or enjoy the serene beauty of the nearby rivers, such as the Ganga and Yamuna. Dehradun's culinary scene is diverse, with local delicacies like Aloo ke Gutke and Gahat ki Dal that tantalize the taste buds. Moreover, the city hosts several festivals and fairs throughout the year, showcasing its rich cultural heritage. With its excellent connectivity via road, rail, and air, Dehradun is an ideal destination for travelers looking to experience the charm of the Himalayas and the rich traditions of North India.",
  faqHeading: "Frequently Asked Questions About Dehradun Airport Cab Booking",
  faqs: [
    {
      question: "How can I book a Dehradun Airport cab?",
      answer:
        "You can book a Dehradun Airport taxi easily by visiting our website sewaktravels.com, selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Dehradun?",
      answer:
        "Sewak Travels provides intra-city cab transfers that allow users to book cabs to and from Dehradun Airport in Dehradun.",
    },
    {
      question:
        "What types of vehicles do you offer for Dehradun Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Dehradun Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Dehradun Airport cabs?",
      answer:
        "Our Dehradun Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Absolutely! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question: "Can I book a cab for outstation travel from Dehradun?",
      answer:
        "Yes, Sewak Travels provides outstation cab services from Dehradun Airport, making it convenient for you to travel to nearby cities or hill stations.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with your booking or any inquiries you may have.",
    },
    {
      question: "Do you offer local taxi services in Dehradun?",
      answer:
        "Yes, we provide local taxi services in Dehradun, perfect for sightseeing and city tours.",
    },
    {
      question: "How do I contact you for more information?",
      answer:
        "You can reach us at +91-837-782-8828 for any inquiries regarding our Dehradun Airport taxi service or bookings.",
    },
  ],
};
export const dimapurTaxiData = {
  pageTitle: "Book Dimapur Airport Cabs",
  mainHeading: "Book Dimapur Airport Cabs on Sewak Travels",
  subHeading: "Enjoy easy Airport Pickup & Drop Services in Dimapur.",
  airportInfo:
    "Dimapur Airport, or Dimapur International Airport, is an important place for travel in India. It was opened in 1932, and serves as a gateway to the culture, beautiful landscapes, and history of Dimapur. The airport is just 15 km from the city center, making it easy for visitors to explore.\n",
  serviceInfo:
    "At Sewak Travels, we offer fast and reliable service. Our drivers will help you with your bags and ensure a smooth ride. We understand that travel can be stressful, so we provide easy booking and real-time cab tracking. Whether you are visiting for a short time or heading out, we make sure your trip to and from Dimapur Airport is comfortable.\n",
  distanceHeading: "Dimapur to Dimapur Airport Cab Distance",
  distanceInfo:
    "The distance from Dimapur Airport to the city center is about 15 km, depending on the route. For a comfortable journey, simply book a Dimapur Airport taxi through Sewak Travels' user-friendly online platform. Whether you're going to or from the airport, we ensure an amazing experience.\n",
  bookingHeading: "Online Dimapur Airport Cab Booking with Sewak Travels",
  bookingInfo:
    "Welcome to Sewak Travels, where we provide reliable and comfortable online Dimapur Airport cab booking tailored to your needs. Whether arriving in or departing from Dimapur, our Dimapur Airport taxi service ensures a smooth travel experience from start to finish. With modern vehicles and professional drivers, we offer top-quality airport cab services in Dimapur.\n",
  img: "/assets/img/LocationDifferent/Dimapur-airport.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book a Dimapur Cab?",
  whyChooseSubHeading:
    "Booking your Dimapur Cabs is easy with Sewak. Here’s why you should choose us:",
  whyChooseInfo: [
    {
      title: "24/7 Booking & Travel Assistance:",
      detail:
        "Our Dimapur Airport taxi service is available all day and night. You can book a ride anytime. We know flights can arrive or leave at odd hours",
    },
    {
      title: "Outstation Cabs:",
      detail: "If you need to travel outside Dimapur",
    },
    {
      title: "Competitive Rates:",
      detail:
        "We have clear pricing with no hidden charges. Our budget-friendly prices make sure you get the best cab service in Dimapur without losing quality.",
    },
    {
      title: "Prepaid and Hassle-Free:",
      detail:
        "You can book your prepaid taxi at Dimapur Airport in advance or when you arrive. We offer flexible payment options. Our safe online payment system makes sure your transaction is secure and easy.",
    },
    {
      title: "Professional Drivers:",
      detail:
        "Our drivers are trained professionals who focus on your safety and comfort. They know the best routes and speak the local language well",
    },
    {
      title: "Comfortable Fleet:",
      detail: "We have a range of vehicles for you to choose from",
    },
    {
      title: "Diverse Dimapur Taxi Options:",
      detail: "You can select from 4-seater sedans (like Dzire and Etios)",
    },
  ],
  howToBookHeading: "How to Book a Dimapur Airport Taxi?",
  howToBookInfo:
    "Sewak Travels makes booking a Dimapur Cab easy. Visit https://sewaktravels.com/, click on the cabs icon, select your preferences (Outstation, local, corporate, or airport cab service), choose your destination and date, select your vehicle, fill in your details, and proceed to payment. Our user-friendly interface allows you to complete your booking in just a few clicks, giving you peace of mind as you prepare for your journey.\n",
  taxiServicesHeading:
    "Various Taxi Services in Dimapur Airport by Sewak Travels",
  taxiServices: [
    {
      title: "Outstation Cabs from Dimapur Airport:",
      description:
        "We provide dependable outstation cabs from Dimapur Airport for your travel needs. Enjoy quick and comfortable rides in our clean",
    },
    {
      title: "Local Taxis from Dimapur Airport:",
      description:
        "Need a ride within Dimapur? We make it easy to book local taxis from Dimapur Airport. Our local cab package is perfect for city tours and quick trips. Discover the rich history of Dimapur with visits to attractions like the Kachari Ruins",
    },
    {
      title: "Corporate Airport Transfers:",
      description:
        "We also provide corporate airport transfer services for businesses. Contact us for a custom quote and enjoy reliable service for your company’s needs. Whether you need transportation for meetings",
    },
  ],
  highlights: [
    {
      title: "Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing",
    },
    {
      title: "Prepaid Taxi Service:",
      detail:
        "Book your prepaid taxi at Dimapur Airport in advance for peace of mind. Our system ensures your taxi is ready when you land",
    },
    {
      title: "Taxi Service in Dimapur:",
      detail: "We offer taxi services in Dimapur for sightseeing and more",
    },
  ],
  easyBookingHeading: "Easy Dimapur Airport Cab Booking for Every Need",
  easyBookingInfo:
    "Whether you are visiting Dimapur for business or leisure, Sewak Travels offers convenient and reliable cab booking options. Our Dimapur Airport taxi contact number and online platforms make booking a cab easy.\n",
  easyBookingPoints: [
    "Affordable Dimapur Airport Taxi Fare:",
    "We believe in providing quality service at reasonable rates",
    "ensuring that your travel experience is enjoyable.\nQuick & Reliable Airport Taxi Service:",
    "Our commitment to punctuality means you will never miss a flight. We monitor flight schedules to adjust for any delays",
    "making sure our drivers are there for you.\nCar Rental Services for Dimapur Airport:",
    "Looking to explore at your own pace? We offer flexible car rental options for those who prefer to drive themselves.",
  ],
  bookingCallToAction:
    "Book your Dimapur Airport cab now with Sewak Travels and enjoy comfortable rides at great prices. Call us at +91-837-782-8828 or visit our website for quick and easy Dimapur Airport taxi booking. Let Sewak Travels make your airport journey as simple as possible.",
  thingsToKnowHeading: "Things To Know About Dimapur",
  thingsToKnowInfo:
    "Dimapur is in Nagaland and is known for its rich culture, beautiful views, and old sites. The city has unique buildings and lively festivals, with many old temples and forts. People come to Dimapur to enjoy the scenery and relax. The local food is delicious, with dishes like smoked meat and bamboo shoots. It is easy to reach Dimapur by road, train, or plane.\n\nOne special thing about Dimapur is its mix of cultures. This is clear during festivals like the Hornbill Festival, where you can see the traditions and crafts of the Naga tribes. Visiting local markets is fun because you can find handmade crafts, traditional clothes, and local foods.\n\nDimapur has stunning natural beauty, with green hills, nice views, and many hiking trails. Outdoor lovers can hike to beautiful spots and waterfalls, perfect for adventures. The area has rich plants and animals, making it a great place for photos and nature walks.\n",
  faqHeading: "Frequently Asked Questions About Dimapur Airport Cab Booking",
  faqs: [
    {
      question: "How can I book a Dimapur Airport cab?",
      answer:
        "You can easily book a Dimapur Airport taxi by visiting our website, selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Dimapur?",
      answer:
        "Sewak Travels provides intra-city cab transfers for trips to and from Dimapur Airport.",
    },
    {
      question:
        "What types of vehicles do you offer for Dimapur Airport cab services?",
      answer:
        "We offer various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles to ensure a comfortable ride.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Dimapur Airport, allowing you to secure your ride in advance.",
    },
    {
      question: "What are the rates for Dimapur Airport cabs?",
      answer:
        "Our Dimapur Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Yes! All our drivers are professionals, ensuring your safety and comfort during your journey.",
    },
    {
      question: "Can I book a cab for outstation travel from Dimapur Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Dimapur Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with your Dimapur cab bookings.",
    },
    {
      question: "Do you provide local sightseeing cabs in Dimapur?",
      answer:
        "Yes, we offer local taxi services for sightseeing and exploring Dimapur’s attractions.",
    },
    {
      question: "How can I contact Sewak Travels for inquiries?",
      answer:
        "You can reach us at +91-837-782-8828 or visit our website for more information.",
    },
  ],
};
export const diuTaxiData = {
  pageTitle: "Book Diu Airport Cabs",
  mainHeading: "Book Diu Airport Cabs on Sewak Travels",
  subHeading: "Enjoy smooth Airport Pickup & Drop Services in Diu.",
  airportInfo:
    "Diu Airport, also known as Diu International Airport, is an essential hub for both domestic and international travel in India. Established in 1970, this airport is the gateway to the stunning beaches, rich history, and vibrant culture of Diu. Located just 8 km from the town center, Diu Airport provides convenient access for tourists eager to explore the region's unique attractions. Our commitment to excellence ensures that you receive prompt and reliable service at every step. From the moment you arrive at Diu Airport, our drivers will be ready to assist you with your luggage and ensure a smooth ride to your destination. We understand that travel can be stressful, which is why we offer flexible booking options and real-time tracking for your cab. \n",
  serviceInfo:
    "Choose Sewak Travels for a seamless airport transfer experience that allows you to focus on what truly matters—your journey and the experiences that await you in Diu.Whether you are flying in for a quick visit or heading out, we ensure your journey to and from Diu Airport is relaxed.\n",
  distanceHeading: "Diu to Diu Airport Cab Distance",
  distanceInfo:
    "The distance from Diu Airport to the town center is approximately 8 km, depending on the route and any stops made. For a comfortable and stress-free journey, simply book a Diu Airport taxi through Sewak Travels' user-friendly online platform. Whether you are heading to or from the airport, we ensure an amazing experience, making your travel convenient and reliable.",
  bookingHeading: "Online Diu Airport Cab Booking with Sewak Travels",
  bookingInfo:
    "Welcome to Sewak Travels, where we offer reliable, comfortable, and on-time Online Diu Airport cab booking tailored to your needs. Whether you are arriving in or departing from Diu, our Diu Airport taxi service ensures a smooth travel experience from start to finish. With a fleet of modern vehicles and professional drivers, we provide the highest standard of airport cab services in Diu.\n",
  img: "/assets/img/LocationDifferent/Diu-airport.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book a Diu Cab?",
  whyChooseSubHeading:
    "Book your Diu Cabs effortlessly: When you choose Sewak for your cab service in Diu, you get:",
  whyChooseInfo: [
    {
      title: "24/7 Booking & Travel Assistance:",
      detail: "Our Diu Airport taxi service operates 24/7",
    },
    {
      title: "Outstation Cabs:",
      detail:
        "Need to travel outside Diu? We offer outstation cabs from Diu Airport",
    },
    {
      title: "Competitive Rates:",
      detail: "With Diu Airport taxi per km rates",
    },
    {
      title: "Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi Diu Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "Professional Drivers:",
      detail:
        "Sewak's drivers are skilled professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title: "Diverse Diu Taxi Options:",
      detail: "When selecting our Diu cab service for your journey",
    },
  ],
  howToBookHeading: "How to Book a Diu Airport Taxi?",
  howToBookInfo:
    "Sewak Travels solves the issue of booking a Diu Cab by offering premium services at affordable Diu taxi rates, complete with a pickup-drop facility and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit https://sewaktravels.com/, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, and proceed to payment.\n",
  taxiServicesHeading: "Various Taxi Services in Diu Airport by Sewak Travels",
  taxiServices: [
    {
      title: "Outstation Cabs from Diu Airport:",
      description:
        "Sewak Travels offers reliable outstation cabs from Diu Airport",
    },
    {
      title: "Local Taxis from Diu Airport:",
      description:
        "Need a ride within Diu? We make it easy to book local taxis from Diu Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "Corporate Airport Transfers:",
      description:
        "We also cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "Taxi Service in Diu:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: "Easy Diu Airport Cab Booking for Every Need",
  easyBookingInfo:
    "Whether you are visiting Diu for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Diu Airport taxi contact number and online platforms make it easy for you to book a cab within minutes.\n",
  easyBookingPoints: [
    "Affordable Diu Airport Taxi Fare",
    "Quick & Reliable Airport Taxi Service",
    "Car Rental Services for Diu Airport",
  ],
  bookingCallToAction:
    "Book your Diu Airport cab now with Sewak Travels and enjoy comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Diu Airport taxi booking. Let Sewak Travels make your airport commute effortless!",
  thingsToKnowHeading: "Things To Know About Diu",
  thingsToKnowInfo:
    "Diu is not just about beautiful beaches; it’s also rich in history and culture. The town features stunning forts like Diu Fort, built by the Portuguese, and churches that reflect colonial architecture. Visitors can explore the vibrant local markets offering handicrafts, seafood, and local delicacies. For nature lovers, the serene beaches such as Nagoa Beach and Ghoghla Beach provide a perfect getaway for relaxation and water sports. Diu is also known for its annual Diu Festival, attracting tourists with its lively events and cultural showcases. Whether you're wandering through its quaint streets or enjoying a sunset by the beach, Diu promises memorable experiences.\n",
  faqHeading: "Frequently Asked Questions About Diu Airport Cab Booking",
  faqs: [
    {
      question: "How can I book a Diu Airport cab?",
      answer:
        "You can book a Diu Airport taxi easily by visiting our website sewaktravels.com, selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Diu?",
      answer:
        "Sewak Travels provides intra-city cab transfers that allow users to book cabs to and from Diu Airport in Diu.",
    },
    {
      question:
        "What types of vehicles do you offer for Diu Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Diu Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Diu Airport cabs?",
      answer:
        "Our Diu Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Absolutely! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question: "Can I book a cab for outstation travel from Diu Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Diu Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with your Diu cab bookings.",
    },
    {
      question: "Do you provide local sightseeing cabs in Diu?",
      answer:
        "Yes, we offer local taxi services for sightseeing and exploring the beautiful attractions in and around Diu.",
    },
    {
      question: "What safety measures are in place for your cab services?",
      answer:
        "We prioritize passenger safety by following standard sanitization protocols, ensuring our vehicles are clean and safe for travel.",
    },
  ],
};
export const goaTaxiData = {
  pageTitle: "Book Goa Airport Cabs",
  mainHeading: "Book Goa Airport Cabs on Sewak Travels",
  subHeading: "Enjoy smooth Airport Pickup & Drop Services in Goa.",
  airportInfo:
    "Goa Airport, also known as Dabolim Airport, is a key hub for both domestic and international travel in India. Established in 1955, this historic airport serves as a gateway to the vibrant culture, beaches, and historical sites of Goa. Located just 30 km from the city center, Goa Airport provides easy access for tourists looking to explore the region. Our commitment to excellence ensures that you receive prompt and reliable service at every step. From the moment you arrive at Goa Airport, our drivers will be ready to assist you with your luggage and ensure a smooth ride to your destination. We understand that travel can be stressful, which is why we offer flexible booking options and real-time tracking for your cab. \n",
  serviceInfo:
    "Choose Sewak Travels for a smooth airport transfer experience that allows you to focus on what truly matters—your journey and the experiences that await you in Goa. Whether you are flying in for a quick visit or heading out, we ensure your journey to and from Goa Airport is relaxed.\n",
  distanceHeading: "Goa to Goa Airport Cab Distance",
  distanceInfo:
    "The distance from Goa Airport to the city center is approximately 30 km, depending on the route and any stops made. For a comfortable and stress-free journey, simply book a Goa Airport taxi through Sewak Travels' user-friendly online platform. Whether you are heading to or from the airport, we ensure an amazing experience, making your travel convenient and reliable.",
  bookingHeading: "Online Goa Airport Cab Booking with Sewak Travels",
  bookingInfo:
    "Welcome to Sewak Travels, where we offer reliable, comfortable, and on-time online Goa Airport cab booking tailored to your needs. Whether you are arriving in or departing from Goa, our Goa Airport taxi service ensures a smooth travel experience from start to finish. With a fleet of modern vehicles and professional drivers, we provide the highest standard of airport cab services in Goa.\n",
  img: "/assets/img/LocationDifferent/Goa-airport.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book a Goa Cab?",
  whyChooseSubHeading:
    "Book your Goa Cabs effortlessly: When you choose Sewak for your cab service in Goa, you get:",
  whyChooseInfo: [
    {
      title: "24/7 Booking & Travel Assistance:",
      detail: "Our Goa Airport taxi service operates 24/7",
    },
    {
      title: "Outstation Cabs:",
      detail:
        "Need to travel outside Goa? We offer outstation cabs from Goa Airport",
    },
    {
      title: "Competitive Rates:",
      detail: "With Goa Airport taxi per km rates",
    },
    {
      title: "Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi Goa Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "Professional Drivers:",
      detail:
        "Sewak's drivers are skilled professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title: "Diverse Goa Taxi Options:",
      detail: "When selecting our Goa cab service for your journey",
    },
  ],
  howToBookHeading: "How to Book a Goa Airport Taxi?",
  howToBookInfo:
    "Sewak Travels solves the issue of booking a Goa Cab by offering premium services at affordable Goa taxi rates, complete with a pickup-drop facility and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit https://sewaktravels.com/, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, and proceed to payment.\n",
  taxiServicesHeading: "Various Taxi Services in Goa Airport by Sewak Travels",
  taxiServices: [
    {
      title: "Outstation Cabs from Goa Airport:",
      description:
        "Sewak Travels offers reliable outstation cabs from Goa Airport",
    },
    {
      title: "Local Taxis from Goa Airport:",
      description:
        "Need a ride within Goa? We make it easy to book local taxis from Goa Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "Corporate Airport Transfers:",
      description:
        "We also cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "Taxi Service in Goa:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: "Easy Goa Airport Cab Booking for Every Need",
  easyBookingInfo:
    "Whether you are visiting Goa for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Goa Airport taxi contact number and online platforms make it easy for you to book a cab within minutes.\n",
  easyBookingPoints: [
    "Affordable Goa Airport Taxi Fare",
    "Quick & Reliable Airport Taxi Service",
    "Car Rental Services for Goa Airport",
  ],
  bookingCallToAction:
    "Book your Goa Airport cab now with Sewak Travels and enjoy comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Goa Airport taxi booking. Let Sewak Travels make your airport commute effortless!",
  thingsToKnowHeading: "Things To Know About Goa",
  thingsToKnowInfo:
    "Goa, known for its rich cultural heritage, beautiful beaches, and scenic landscapes, is a popular destination for tourists. Famous for its colonial architecture and vibrant festivals, the area boasts several ancient churches and forts, making it a cultural hub in India. Goa is also recognized for its relaxing beach resorts and is a popular getaway for tourists seeking a break from city life. The region's culinary scene is famous for its seafood, featuring dishes like fish curry and prawn fry. Additionally, Goa is well-connected by road, rail, and air, making it an ideal destination for travelers eager to explore the vibrant culture and heritage of this coastal paradise.\n",
  faqHeading: "Frequently Asked Questions About Goa Airport Cab Booking",
  faqs: [
    {
      question: "How can I book a Goa Airport cab?",
      answer:
        "You can book a Goa Airport taxi easily by visiting our website sewaktravels.com, selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Goa?",
      answer:
        "Sewak Travels provides intra-city cab transfers that allow users to book cabs to and from Goa Airport in Goa.",
    },
    {
      question:
        "What types of vehicles do you offer for Goa Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Goa Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Goa Airport cabs?",
      answer:
        "Our Goa Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Absolutely! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question: "Can I book a cab for outstation travel from Goa Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Goa Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with your Goa cab bookings.",
    },
    {
      question: "Do you provide local sightseeing cabs in Goa?",
      answer:
        "Yes, we offer local taxi services for sightseeing and exploring the beautiful attractions in and around Goa.",
    },
    {
      question: "What safety measures are in place for your cab services?",
      answer:
        "We prioritize passenger safety by following standard sanitization protocols, ensuring our vehicles are clean and safe for travel.",
    },
  ],
};
export const gorakhpurTaxiData = {
  pageTitle: "Book Gorakhpur Airport Cabs",
  mainHeading: "Book Gorakhpur Airport Cabs on Sewak Travels",
  subHeading: "Enjoy smooth Airport Pickup & Drop Services in Gorakhpur.",
  airportInfo:
    "Gorakhpur Airport, also known as Gorakhpur International Airport, is a key hub for both domestic and international travel in India. Established in 1980, this airport serves as a gateway to the vibrant culture, historical sites, and natural beauty of Gorakhpur. Located just 8 km from the city center, Gorakhpur Airport provides easy access for tourists looking to explore the region. Our commitment to excellence ensures that you receive prompt and reliable service at every step. From the moment you arrive at Gorakhpur Airport, our drivers will be ready to assist you with your luggage and ensure a smooth ride to your destination. We understand that travel can be stressful, which is why we offer flexible booking options and real-time tracking for your cab. \n",
  serviceInfo:
    "Choose Sewak Travels for a smooth airport transfer experience that allows you to focus on what truly matters—your journey and the experiences that await you in Gorakhpur. Whether you are flying in for a quick visit or heading out, we ensure your journey to and from Gorakhpur Airport is relaxed.\n",
  distanceHeading: "Gorakhpur to Gorakhpur Airport Cab Distance",
  distanceInfo:
    "The distance from Gorakhpur Airport to the city center is approximately 8 km, depending on the route and any stops made. For a comfortable and stress-free journey, simply book a Gorakhpur Airport taxi through Sewak Travel's user-friendly online platform. Whether you are heading to or from the airport, we ensure an amazing experience, making your travel convenient and reliable.\n",
  bookingHeading: "Online Gorakhpur Airport Cab Booking with Sewak Travels",
  bookingInfo:
    "Welcome to Sewak Travels, where we offer reliable, comfortable, and on-time Online Gorakhpur Airport cab booking tailored to your needs. Whether you are arriving in or departing from Gorakhpur, our Gorakhpur Airport taxi service ensures a smooth travel experience from start to finish. With a fleet of modern vehicles and professional drivers, we provide the highest standard of airport cab services in Gorakhpur.\n",
  img: "/assets/img/LocationDifferent/Gorakhpur-airport.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book a Gorakhpur Cab?",
  whyChooseSubHeading:
    "Book your Gorakhpur Cabs effortlessly: When you choose Sewak for your cab service in Gorakhpur, you get:",
  whyChooseInfo: [
    {
      title: "24/7 Booking & Travel Assistance:",
      detail: "Our Gorakhpur Airport taxi service operates 24/7",
    },
    {
      title: "Outstation Cabs:",
      detail:
        "Need to travel outside Gorakhpur? We offer outstation cabs from Gorakhpur Airport",
    },
    {
      title: "Competitive Rates:",
      detail: "With Gorakhpur Airport taxi per km rates",
    },
    {
      title: "Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi Gorakhpur Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "Professional Drivers:",
      detail:
        "Sewak's drivers are skilled professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title: "Diverse Gorakhpur Taxi Options:",
      detail: "When selecting our Gorakhpur cab service for your journey",
    },
  ],
  howToBookHeading: "How to Book a Gorakhpur Airport Taxi?",
  howToBookInfo:
    "Sewak Travels solves the issue of booking a Gorakhpur Cab by offering premium services at affordable Gorakhpur taxi rates, complete with a pickup-drop facility and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit https://sewaktravels.com/, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, and proceed to payment.",
  taxiServicesHeading:
    "Various Taxi Services in Gorakhpur Airport by Sewak Travels",
  taxiServices: [
    {
      title: "Outstation Cabs from Gorakhpur Airport:",
      description:
        "Sewak Travels offers reliable outstation cabs from Gorakhpur Airport",
    },
    {
      title: "Local Taxis from Gorakhpur Airport:",
      description:
        "Need a ride within Gorakhpur? We make it easy to book local taxis from Gorakhpur Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "Corporate Airport Transfers:",
      description:
        "We also cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "Taxi Service in Gorakhpur:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: "Easy Gorakhpur Airport Cab Booking for Every Need",
  easyBookingInfo:
    "Whether you are visiting Gorakhpur for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Gorakhpur Airport taxi contact number and online platforms make it easy for you to book a cab within minutes.\n",
  easyBookingPoints: [
    "Affordable Gorakhpur Airport Taxi Fare",
    "Quick & Reliable Airport Taxi Service",
    "Car Rental Services for Gorakhpur Airport",
  ],
  bookingCallToAction:
    "Book your Gorakhpur Airport cab now with Sewak Travels and enjoy comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Gorakhpur Airport taxi booking. Let Sewak Travels make your airport commute effortless!",
  thingsToKnowHeading: "Things To Know About Gorakhpur",
  thingsToKnowInfo:
    "Gorakhpur, located in the state of Uttar Pradesh, is known for its rich cultural heritage, historical sites, and natural beauty. Famous for its vibrant festivals and religious significance, the area boasts several ancient temples and landmarks, making it a cultural hub in India. Gorakhpur is also recognized for its bustling markets and is a popular destination for tourists seeking a blend of tradition and modernity. The region's culinary scene is famous for its local delicacies, featuring dishes like kebabs and chaats. Additionally, Gorakhpur is well-connected by road, rail, and air, making it an ideal destination for travelers eager to explore the vibrant culture and heritage of this historical city.\n",
  faqHeading: "Frequently Asked Questions About Gorakhpur Airport Cab Booking",
  faqs: [
    {
      question: "How can I book a Gorakhpur Airport cab?",
      answer:
        "You can book a Gorakhpur Airport taxi easily by visiting our website sewaktravels.com, selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Gorakhpur?",
      answer:
        "Sewak Travels provides intra-city cab transfers that allow users to book cabs to and from Gorakhpur Airport in Gorakhpur.",
    },
    {
      question:
        "What types of vehicles do you offer for Gorakhpur Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Gorakhpur Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Gorakhpur Airport cabs?",
      answer:
        "Our Gorakhpur Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Absolutely! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question:
        "Can I book a cab for outstation travel from Gorakhpur Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Gorakhpur Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with any queries or booking needs, ensuring a smooth experience.",
    },
    {
      question: "What safety measures are in place for your cabs?",
      answer:
        "We prioritize your safety by following strict sanitization protocols, and our drivers adhere to all safety guidelines to ensure a safe travel environment.",
    },
    {
      question: "Can I modify my cab booking after it’s been confirmed?",
      answer:
        "Yes, you can modify your booking by contacting our customer support team. We’ll do our best to accommodate your changes.",
    },
  ],
};
export const guwahatiTaxiData = {
  pageTitle: "Book Guwahati Airport Cabs",
  mainHeading: "Book Guwahati Airport Cabs on Sewak Travels",
  subHeading: "Enjoy smooth Airport Pickup & Drop Services in Guwahati.",
  airportInfo:
    "Guwahati Airport, also called Lokpriya Gopinath Bordoloi International Airport, is an important airport for both domestic and international travel in India. Opened in 1958, it is the main entry point to explore Guwahati's culture, tea gardens, and historical sites. The airport is just 25 km from the city center, making it easy for tourists to visit the area. \n",
  serviceInfo:
    "At Sewak Travels, we make sure you receive reliable service from the moment you land. Our drivers will help you with your luggage and provide a smooth ride to your destination. We offer easy booking options and real-time cab tracking to make your travel stress-free. Choose Sewak Travels for a comfortable airport transfer, letting you enjoy your journey in Guwahati. Whether you're visiting for a short time or heading out, we ensure your trip to and from Guwahati Airport is relaxing.\n",
  distanceHeading: "Guwahati to Guwahati Airport Cab Distance",
  distanceInfo:
    "The distance from Guwahati Airport to the city center is approximately 25 km, depending on the route and any stops made. For a comfortable and stress-free journey, simply book a Guwahati Airport taxi through Sewak Travel's user-friendly online platform. Whether you're heading to or from the airport, we ensure a hassle-free experience, making your travel convenient and reliable. Skip the wait and book your airport taxi in Guwahati directly with Sewak Travels for a seamless ride!\n",
  bookingHeading: "Online Guwahati Airport Cab Booking with Sewak Travels",
  bookingInfo:
    "Welcome to Sewak Travels, where we offer reliable, comfortable, and on-time Online Guwahati Airport cab booking tailored to your needs. Whether you are arriving in or departing from Guwahati, our Guwahati Airport taxi service ensures a smooth travel experience from start to finish. With a fleet of modern vehicles and professional drivers, we provide the highest standard of airport cab services in Guwahati.\n",
  img: "/assets/img/LocationDifferent/Guwahati-airport.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book a Guwahati Cab?",
  whyChooseSubHeading:
    "Book your Guwahati Cabs effortlessly: When you choose Sewak for your cab service in Guwahati, you get:",
  whyChooseInfo: [
    {
      title: "24/7 Booking & Travel Assistance:",
      detail: "Our Guwahati Airport taxi service operates 24/7",
    },
    {
      title: "Outstation Cabs:",
      detail:
        "Need to travel outside Guwahati? We offer outstation cabs from Guwahati Airport",
    },
    {
      title: "Competitive Rates:",
      detail: "With Guwahati Airport taxi per km rates",
    },
    {
      title: "Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi Guwahati Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "Professional Drivers:",
      detail:
        "Sewak's drivers are skilled professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title: "Diverse Guwahati Taxi Options:",
      detail: "When selecting our Guwahati cab service for your journey",
    },
  ],
  howToBookHeading: "How to Book a Guwahati Airport Taxi?",
  howToBookInfo:
    "Sewak Travels solves the issue of booking a Guwahati Cab by offering premium services at affordable Guwahati taxi rates, complete with a pickup-drop facility and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit https://sewaktravels.com/, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, and proceed to payment.\n",
  taxiServicesHeading:
    "Various Taxi Services in Guwahati Airport by Sewak Travels",
  taxiServices: [
    {
      title: "Outstation Cabs from Guwahati Airport:",
      description:
        "Sewak Travels offers reliable outstation cabs from Guwahati Airport",
    },
    {
      title: "Local Taxis from Guwahati Airport:",
      description:
        "Need a ride within Guwahati? We make it easy to book local taxis from Guwahati Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "Corporate Airport Transfers:",
      description:
        "We also cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "Taxi Service in Guwahati:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: "Easy Guwahati Airport Cab Booking for Every Need",
  easyBookingInfo:
    "Whether you are visiting Guwahati for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Guwahati Airport taxi contact number and online platforms make it easy for you to book a cab within minutes.\n",
  easyBookingPoints: [
    "Affordable Guwahati Airport Taxi Fare",
    "Quick & Reliable Airport Taxi Service",
    "Car Rental Services for Guwahati Airport",
  ],
  bookingCallToAction:
    "Book your Guwahati Airport cab now with Sewak Travels and enjoy comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Guwahati Airport taxi booking. Let Sewak Travels make your airport commute effortless!",
  thingsToKnowHeading: "Things To Know About Guwahati",
  thingsToKnowInfo:
    "Guwahati is the biggest city in Assam and an important center for culture and business in Northeast India. It's known for its rich history, lively culture, and beautiful views. The city is famous for its old temples and the Brahmaputra River, which attracts many tourists. Guwahati is also well-known for its tea gardens and is a great starting point for exploring Assam and the surrounding areas. The local food is delicious, with popular dishes like Assam tea and fish curry. Plus, Guwahati is easy to reach by road, rail, and air, making it a perfect place for travelers to discover the culture and beauty of this region.\n",
  faqHeading: "Frequently Asked Questions About Guwahati Airport Cab Booking",
  faqs: [
    {
      question: "How can I book a Guwahati Airport cab?",
      answer:
        "You can book a Guwahati Airport taxi easily by visiting our website sewaktravels.com, selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Guwahati?",
      answer:
        "Sewak Travels provides intra-city cab transfers that allow users to book cabs to and from Guwahati Airport in Guwahati.",
    },
    {
      question:
        "What types of vehicles do you offer for Guwahati Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Guwahati Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Guwahati Airport cabs?",
      answer:
        "Our Guwahati Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Absolutely! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question: "Can I book a cab for outstation travel from Guwahati Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Guwahati Airport for your convenience.",
    },
    {
      question: "What are your operating hours?",
      answer:
        "Our Guwahati Airport cab service operates 24/7, ensuring you can book a ride at any time.",
    },
    {
      question: "How do I contact Sewak Travels for any queries?",
      answer:
        "For any questions, feel free to call us at +91-837-782-8828 or visit our website.",
    },
    {
      question:
        "Is there a cancellation policy for Guwahati Airport cab bookings?",
      answer:
        "Yes, we have a cancellation policy that allows you to cancel your booking within a specified time frame for a full refund. Please check our website for detailed terms and conditions related to cancellations.",
    },
  ],
};
export const hindonTaxiData = {
  pageTitle: "Book Hindon Airport Cabs",
  mainHeading: "Book Hindon Airport Cabs on Sewak Travels",
  subHeading: "Enjoy smooth Airport Pickup & Drop Services in Hindon.",
  airportInfo:
    "Hindon Airport, also known as Hindon International Airport, is a key hub for both domestic and international travel in India. Established in 1932, this historic airport serves as a gateway to the vibrant culture, beaches, and historical sites of Hindon. Located just 15 km from the city center, Hindon Airport provides easy access for tourists looking to explore the region. Our commitment to excellence ensures that you receive prompt and reliable service at every step. From the moment you arrive at Hindon Airport, our drivers will be ready to assist you with your luggage and ensure a smooth ride to your destination. We understand that travel can be stressful, which is why we offer flexible booking options and real-time tracking for your cab. \n",
  serviceInfo:
    "Choose Sewak Travels for a smooth airport transfer experience that allows you to focus on what truly matters—your journey and the experiences that await you in Hindon. Whether you are flying in for a quick visit or heading out, we ensure your journey to and from Hindon Airport is relaxed.\n",
  distanceHeading: "Hindon to Hindon Airport Cab Distance",
  distanceInfo:
    "The distance from Hindon Airport to the city center is approximately 15 km, depending on the route and any stops made. For a comfortable and stress-free journey, simply book a Hindon Airport taxi through Sewak Travel's user-friendly online platform. Whether you are heading to or from the airport, we ensure an amazing experience, making your travel convenient and reliable.\n",
  bookingHeading: "Online Hindon Airport Cab Booking with Sewak Travels",
  bookingInfo:
    "Welcome to Sewak Travels, where we offer reliable, comfortable, and on-time online Hindon Airport cab booking tailored to your needs. Whether you are arriving in or departing from Hindon, our Hindon Airport taxi service ensures a smooth travel experience from start to finish. With a fleet of modern vehicles and professional drivers, we provide the highest standard of airport cab services in Hindon.\n",
  img: "/assets/img/LocationDifferent/Hindon-airport.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book a Hindon Cab?",
  whyChooseSubHeading:
    "Book your Hindon Cabs effortlessly: When you choose Sewak for your cab service in Hindon, you get:",
  whyChooseInfo: [
    {
      title: "24/7 Booking & Travel Assistance:",
      detail: "Our Hindon Airport taxi service operates 24/7",
    },
    {
      title: "Outstation Cabs:",
      detail:
        "Need to travel outside Hindon? We offer outstation cabs from Hindon Airport",
    },
    {
      title: "Competitive Rates:",
      detail: "With Hindon Airport taxi per km rates",
    },
    {
      title: "Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi Hindon Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "Professional Drivers:",
      detail:
        "Sewak's drivers are skilled professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title: "Diverse Hindon Taxi Options:",
      detail: "When selecting our Hindon cab service for your journey",
    },
  ],
  howToBookHeading: "How to Book a Hindon Airport Taxi?",
  howToBookInfo:
    "Sewak Travels solves the issue of booking a Hindon Cab by offering premium services at affordable Hindon taxi rates, complete with a pickup-drop facility and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit https://sewaktravels.com/, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, and proceed to payment.\n",
  taxiServicesHeading:
    "Various Taxi Services in Hindon Airport by Sewak Travels",
  taxiServices: [
    {
      title: "Outstation Cabs from Hindon Airport:",
      description:
        "Sewak Travels offers reliable outstation cabs from Hindon Airport",
    },
    {
      title: "Local Taxis from Hindon Airport:",
      description:
        "Need a ride within Hindon? We make it easy to book local taxis from Hindon Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "Corporate Airport Transfers:",
      description:
        "We also cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "Taxi Service in Hindon:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: "Easy Hindon Airport Cab Booking for Every Need",
  easyBookingInfo:
    "Whether you are visiting Hindon for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Hindon Airport taxi contact number and online platforms make it easy for you to book a cab within minutes.\n",
  easyBookingPoints: [
    "Affordable Hindon Airport Taxi Fare",
    "Quick & Reliable Airport Taxi Service",
    "Car Rental Services for Hindon Airport",
  ],
  bookingCallToAction:
    "Book your Hindon Airport cab now with Sewak Travels and enjoy comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Hindon Airport taxi booking. Let Sewak Travels make your airport commute effortless!",
  thingsToKnowHeading: "Things To Know About Hindon",
  thingsToKnowInfo:
    "Hindon, part of the union territory of Daman and Diu, is known for its rich cultural heritage, beautiful beaches, and scenic landscapes. Famous for its colonial architecture and vibrant festivals, the area boasts several ancient churches and forts, making it a cultural hub in India. Hindon is also recognized for its relaxing beach resorts and is a popular getaway for tourists seeking a break from city life. The region's culinary scene is famous for its seafood, featuring dishes like fish curry and prawn fry. Additionally, Hindon is well-connected by road, rail, and air, making it an ideal destination for travelers eager to explore the vibrant culture and heritage of this coastal paradise.\n",
  faqHeading: "Frequently Asked Questions About Hindon Airport Cab Booking",
  faqs: [
    {
      question: "How can I book a Hindon Airport cab?",
      answer:
        "You can book a Hindon Airport taxi easily by visiting our website sewaktravels.com, selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Hindon?",
      answer:
        "Sewak Travels provides intra-city cab transfers that allow users to book cabs to and from Hindon Airport in Hindon.",
    },
    {
      question:
        "What types of vehicles do you offer for Hindon Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Hindon Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Hindon Airport cabs?",
      answer:
        "Our Hindon Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Absolutely! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question: "Can I book a cab for outstation travel from Hindon Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Hindon Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with your Hindon cab bookings.",
    },
    {
      question: "Do you provide local sightseeing cabs in Hindon?",
      answer:
        "Yes, we offer local taxi services for sightseeing and exploring the beautiful attractions in and around Hindon.",
    },
    {
      question: "What safety measures are in place for your cab services?",
      answer:
        "We prioritize passenger safety by ensuring our vehicles are sanitized regularly, equipped with necessary safety features like seat belts, and follow all health and hygiene protocols.",
    },
  ],
};
export const hubballiTaxiData = {
  pageTitle: "Book Hubballi  Airport Cabs",
  mainHeading: "Book Hubballi Airport Cabs on Sewak Travels",
  subHeading: "Enjoy smooth Airport Pickup & Drop Services in Hubballi.",
  airportInfo:
    "Hubballi Airport, also known as Hubballi International Airport, is a key hub for both domestic and international travel in India. Established in 1990, this strategic airport serves as a gateway to the vibrant culture, historical sites, and beautiful landscapes of Hubballi. Located just 20 km from the city center, Hubballi Airport provides easy access for tourists looking to explore the region. Our commitment to excellence ensures that you receive prompt and reliable service at every step. From the moment you arrive at Hubballi Airport, our drivers will be ready to assist you with your luggage and ensure a smooth ride to your destination. We understand that travel can be stressful, which is why we offer flexible booking options and real-time tracking for your cab. \n",
  serviceInfo:
    "At Sewak Travels, we make your travel experience smoother with our reliable Agra Airport cab service. Whether you’re flying in for a quick visit or heading out, we ensure your journey to and from Agra Airport is stress-free and on time. ",
  distanceHeading: "Hubballi to Hubballi Airport Cab Distance",
  distanceInfo:
    "The distance from Hubballi Airport to the city center is approximately 20 km, depending on the route and any stops made. For a comfortable and stress-free journey, simply book a Hubballi Airport taxi through Sewak Travel's user-friendly online platform. Whether you are heading to or from the airport, we ensure an amazing experience, making your travel convenient and reliable.\n",
  bookingHeading: "Online Hubballi Airport Cab Booking with Sewak Travels",
  bookingInfo:
    "Welcome to Sewak Travels, where we offer reliable, comfortable, and on-time online Hubballi Airport cab booking tailored to your needs. Whether you are arriving in or departing from Hubballi, our Hubballi Airport taxi service ensures a smooth travel experience from start to finish. With a fleet of modern vehicles and professional drivers, we provide the highest standard of airport cab services in Hubballi.\n",
  img: "/assets/img/LocationDifferent/Hubballi-airport.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book a Hubballi Cab?",
  whyChooseSubHeading:
    "Book your Hubballi Cabs effortlessly: When you choose Sewak for your cab service in Hubballi, you get:",
  whyChooseInfo: [
    {
      title: "24/7 Booking & Travel Assistance:",
      detail: "Our Hubballi Airport taxi service operates 24/7",
    },
    {
      title: "Outstation Cabs:",
      detail:
        "Need to travel outside Hubballi? We offer outstation cabs from Hubballi Airport",
    },
    {
      title: "Competitive Rates:",
      detail: "With Hubballi Airport taxi per km rates",
    },
    {
      title: "Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi Hubballi Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "Professional Drivers:",
      detail:
        "Sewak's drivers are skilled professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title: "Diverse Hubballi Taxi Options:",
      detail: "When selecting our Hubballi cab service for your journey",
    },
  ],
  howToBookHeading: "How to Book a Hubballi Airport Taxi?",
  howToBookInfo:
    "Sewak Travels solves the issue of booking a Hubballi Cab by offering premium services at affordable Hubballi taxi rates, complete with a pickup-drop facility and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit https://sewaktravels.com/, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, and proceed to payment.\n",
  taxiServicesHeading:
    "Various Taxi Services in Hubballi Airport by Sewak Travels",
  taxiServices: [
    {
      title: "Outstation Cabs from Hubballi Airport:",
      description:
        "Sewak Travels offers reliable outstation cabs from Hubballi Airport",
    },
    {
      title: "Local Taxis from Hubballi Airport:",
      description:
        "Need a ride within Hubballi? We make it easy to book local taxis from Hubballi Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "Corporate Airport Transfers:",
      description:
        "We also cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "Taxi Service in Hubballi:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: "Easy Hubballi Airport Cab Booking for Every Need",
  easyBookingInfo:
    "Whether you are visiting Hubballi for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Hubballi Airport taxi contact number and online platforms make it easy for you to book a cab within minutes.\n",
  easyBookingPoints: [
    "Affordable Hubballi Airport Taxi Fare",
    "Quick & Reliable Airport Taxi Service",
    "Car Rental Services for Hubballi Airport",
  ],
  bookingCallToAction:
    "Book your Hubballi Airport cab now with Sewak Travels and enjoy comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Hubballi Airport taxi booking. Let Sewak Travels make your airport commute effortless!",
  thingsToKnowHeading: "Things To Know About Hubballi",
  thingsToKnowInfo:
    "Hubballi, also known as Hubli, is a prominent city in the southern part of India, known for its rich cultural heritage and vibrant festivals. It is a key hub for trade and commerce, offering a unique blend of modernity and tradition. Famous for its temples, ancient markets, and bustling streets, Hubballi is also recognized for its delicious South Indian cuisine. With its well-connected road and rail networks, Hubballi serves as a major gateway for travelers to explore the nearby regions of Karnataka.\n",
  faqHeading: "Frequently Asked Questions About Hubballi Airport Cab Booking",
  faqs: [
    {
      question: "How can I book a Hubballi Airport cab?",
      answer:
        "You can book a Hubballi Airport taxi easily by visiting our website sewaktravels.com, selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Hubballi?",
      answer:
        "Sewak Travels provides intra-city cab transfers that allow users to book cabs to and from Hubballi Airport in Hubballi.",
    },
    {
      question:
        "What types of vehicles do you offer for Hubballi Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Hubballi Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Hubballi Airport cabs?",
      answer:
        "Our Hubballi Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Absolutely! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question: "Can I book a cab for outstation travel from Hubballi Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Hubballi Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with your Hubballi cab bookings.",
    },
    {
      question: "Do you provide local sightseeing cabs in Hubballi?",
      answer:
        "Yes, we offer local taxi services for sightseeing and exploring the beautiful attractions in and around Hubballi.",
    },
    {
      question: "What safety measures are taken in the cabs?",
      answer:
        "We prioritize safety and ensure that all our cabs are sanitized regularly, and our drivers follow strict protocols.",
    },
  ],
};
export const indiraGandhiInternationalTaxiData = {
  pageTitle: "Book Indira Gandhi International Airport Cabs",
  mainHeading: "Book Indira Gandhi International Airport Cabs on Sewak Travels",
  subHeading: "Enjoy smooth Airport Pickup & Drop Services in Delhi.",
  airportInfo:
    "Indira Gandhi International Airport, one of India's busiest airports, is a key hub for both domestic and international travel. Located about 16 km from the city center, it serves as a gateway to Delhi's vibrant culture, historical landmarks, and bustling markets. Our commitment to excellence ensures that you receive prompt and reliable service at every step. From the moment you arrive at Indira Gandhi International Airport, our drivers will be ready to assist you with your luggage and ensure a smooth ride to your destination. We understand that travel can be stressful, which is why we offer flexible booking options and real-time tracking for your cab. \n",
  serviceInfo:
    "Choose Sewak Travels for a smooth airport transfer experience that allows you to focus on what truly matters—your journey and the experiences that await you in Delhi.At Sewak Travels, we make your travel experience smoother with our reliable Indira Gandhi International Airport cab service. Whether you’re flying in for a quick visit or heading out, we ensure your journey to and from Agra Airport is stress-free and on time.\n",
  distanceHeading: "Delhi to Indira Gandhi International Airport Cab Distance",
  distanceInfo:
    "The distance from Indira Gandhi International Airport to the city center is approximately 16 km, depending on the route and any stops made. For a comfortable and stress-free journey, simply book an Indira Gandhi International Airport taxi through Sewak Travel's user-friendly online platform. Whether you are heading to or from the airport, we ensure an amazing experience, making your travel convenient and reliable.\n",
  bookingHeading:
    "Online Indira Gandhi International Airport Cab Booking with Sewak Travels",
  bookingInfo:
    "Welcome to Sewak Travels, where we offer reliable, comfortable, and on-time online Indira Gandhi International Airport cab booking tailored to your needs. Whether you are arriving in or departing from Delhi, our Indira Gandhi International Airport taxi service ensures a smooth travel experience from start to finish. With a fleet of modern vehicles and professional drivers, we provide the highest standard of airport cab services in Delhi.\n",
  img: "/assets/img/LocationDifferent/Indira-Gandhi-International-airport.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book a Delhi Cab?",
  whyChooseSubHeading:
    "Book your Delhi Cabs effortlessly: When you choose Sewak for your cab service in Delhi, you get:",
  whyChooseInfo: [
    {
      title: "24/7 Booking & Travel Assistance:",
      detail:
        "Our Indira Gandhi International Airport taxi service operates 24/7",
    },
    {
      title: "Outstation Cabs:",
      detail:
        "Need to travel outside Delhi? We offer outstation cabs from Indira Gandhi International Airport",
    },
    {
      title: "Competitive Rates:",
      detail: "With Indira Gandhi International Airport taxi per km rates",
    },
    {
      title: "Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi Indira Gandhi International Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "Professional Drivers:",
      detail:
        "Sewak's drivers are skilled professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title: "Diverse Delhi Taxi Options:",
      detail: "When selecting our Delhi cab service for your journey",
    },
  ],
  howToBookHeading: "How to Book an Indira Gandhi International Airport Taxi?",
  howToBookInfo:
    "Sewak Travels solves the issue of booking a Delhi Cab by offering premium services at affordable Delhi taxi rates, complete with a pickup-drop facility and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit https://sewaktravels.com/, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, and proceed to payment.\n",
  taxiServicesHeading:
    "Various Taxi Services in Indira Gandhi International Airport by Sewak Travels",
  taxiServices: [
    {
      title: "Outstation Cabs from Indira Gandhi International Airport:",
      description:
        "Sewak Travels offers reliable outstation cabs from Indira Gandhi International Airport",
    },
    {
      title: "Local Taxis from Indira Gandhi International Airport:",
      description:
        "Need a ride within Delhi? We make it easy to book local taxis from Indira Gandhi International Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "Corporate Airport Transfers:",
      description:
        "We also cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "Taxi Service in Delhi:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading:
    "Easy Indira Gandhi International Airport Cab Booking for Every Need",
  easyBookingInfo:
    "Whether you are visiting Delhi for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Indira Gandhi International Airport taxi contact number and online platforms make it easy for you to book a cab within minutes.\n",
  easyBookingPoints: [
    "Affordable Indira Gandhi International Airport Taxi Fare",
    "Quick & Reliable Airport Taxi Service",
    "Car Rental Services for Indira Gandhi International Airport",
  ],
  bookingCallToAction:
    "Book your Indira Gandhi International Airport cab now with Sewak Travels and enjoy comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Indira Gandhi International Airport taxi booking. Let Sewak Travels make your airport commute effortless!",
  thingsToKnowHeading: "Things To Know About Delhi",
  thingsToKnowInfo:
    "Delhi, the capital of India, is a lively city known for its rich history and diverse culture. It blends the old and the new, with famous spots like the Red Fort, India Gate, and Qutub Minar. Delhi is also known for its street food, busy markets, and historical buildings. It's a great place for both culture and food lovers. The city is well-connected by road, rail, and air, making it easy to explore the rest of India from here.\n",
  faqHeading:
    "Frequently Asked Questions About Indira Gandhi International Airport Cab Booking",
  faqs: [
    {
      question: "How can I book an Indira Gandhi International Airport cab?",
      answer:
        "You can book an Indira Gandhi International Airport taxi easily by visiting our website sewaktravels.com, selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Delhi?",
      answer:
        "Sewak Travels provides intra-city cab transfers that allow users to book cabs to and from Indira Gandhi International Airport in Delhi.",
    },
    {
      question:
        "What types of vehicles do you offer for Indira Gandhi International Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Indira Gandhi International Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question:
        "What are the rates for Indira Gandhi International Airport cabs?",
      answer:
        "Our Indira Gandhi International Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Absolutely! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question:
        "Can I book a cab for outstation travel from Indira Gandhi International Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Indira Gandhi International Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with your Delhi cab bookings.",
    },
    {
      question: "Do you provide local sightseeing cabs in Delhi?",
      answer:
        "Yes, we offer local taxi services for sightseeing and exploring the beautiful attractions in and around Delhi.",
    },
    {
      question: "What safety measures are in place for your cab services?",
      answer:
        "We prioritize passenger safety by following standard sanitization protocols, ensuring our vehicles are clean and safe for travel.",
    },
  ],
};
export const indoreTaxiData = {
  pageTitle: "Book Indore Airport Cabs",
  mainHeading: "Book Indore Airport Cabs on Sewak Travels",
  subHeading: "Enjoy smooth Airport Pickup & Drop Services in Indore.",
  airportInfo:
    "Indore Airport, also known as Devi Ahilya Bai Holkar Airport, is a key hub for both domestic and international travel in India. Established in 1932, this historic airport serves as a gateway to the vibrant culture, historical sites, and economic hubs of Indore. Located just 8 km from the city center, Indore Airport provides easy access for tourists looking to explore the region. Our commitment to excellence ensures that you receive prompt and reliable service at every step. From the moment you arrive at Indore Airport, our drivers will be ready to assist you with your luggage and ensure a smooth ride to your destination. We understand that travel can be stressful, which is why we offer flexible booking options and real-time tracking for your cab. \n",
  serviceInfo:
    "Choose Sewak Travels for a smooth airport transfer experience that allows you to focus on what truly matters—your journey and the experiences that await you in Indore.At Sewak Travels, we make your travel experience smoother with our reliable Indore Airport cab service. Whether you are flying in for a quick visit or heading out, we ensure your journey to and from Indore Airport is relaxed.\n",
  distanceHeading: "Indore to Indore Airport Cab Distance",
  distanceInfo:
    "The distance from Indore Airport to the city center is approximately 8 km, depending on the route and any stops made. For a comfortable and stress-free journey, simply book an Indore Airport taxi through Sewak Travel's user-friendly online platform. Whether you are heading to or from the airport, we ensure an amazing experience, making your travel convenient and reliable.\n",
  bookingHeading: "Online Indore Airport Cab Booking with Sewak Travels",
  bookingInfo:
    "Welcome to Sewak Travels, where we offer reliable, comfortable, and on-time online Indore Airport cab booking tailored to your needs. Whether you are arriving in or departing from Indore, our Indore Airport taxi service ensures a smooth travel experience from start to finish. With a fleet of modern vehicles and professional drivers, we provide the highest standard of airport cab services in Indore.\n",
  img: "/assets/img/LocationDifferent/Indore-airport.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book an Indore Cab?",
  whyChooseSubHeading:
    "Book your Indore Cabs effortlessly: When you choose Sewak for your cab service in Indore, you get:",
  whyChooseInfo: [
    {
      title: "24/7 Booking & Travel Assistance:",
      detail: "Our Indore Airport taxi service operates 24/7",
    },
    {
      title: "Outstation Cabs:",
      detail:
        "Need to travel outside Indore? We offer outstation cabs from Indore Airport",
    },
    {
      title: "Competitive Rates:",
      detail: "With Indore Airport taxi per km rates",
    },
    {
      title: "Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi Indore Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "Professional Drivers:",
      detail:
        "Sewak's drivers are skilled professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title: "Diverse Indore Taxi Options:",
      detail: "When selecting our Indore cab service for your journey",
    },
  ],
  howToBookHeading: "How to Book an Indore Airport Taxi?",
  howToBookInfo:
    "Sewak Travels solves the issue of booking an Indore Cab by offering premium services at affordable Indore taxi rates, complete with a pickup-drop facility and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit https://sewaktravels.com/, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, and proceed to payment.\n",
  taxiServicesHeading:
    "Various Taxi Services in Indore Airport by Sewak Travels",
  taxiServices: [
    {
      title: "Outstation Cabs from Indore Airport:",
      description:
        "Sewak Travels offers reliable outstation cabs from Indore Airport",
    },
    {
      title: "Local Taxis from Indore Airport:",
      description:
        "Need a ride within Indore? We make it easy to book local taxis from Indore Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "Corporate Airport Transfers:",
      description:
        "We also cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "Taxi Service in Indore:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: "Easy Indore Airport Cab Booking for Every Need",
  easyBookingInfo:
    "Whether you are visiting Indore for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Indore Airport taxi contact number and online platforms make it easy for you to book a cab within minutes.\n",
  easyBookingPoints: [
    "Affordable Indore Airport Taxi Fare",
    "Quick & Reliable Airport Taxi Service",
    "Car Rental Services for Indore Airport",
  ],
  bookingCallToAction:
    "Book your Indore Airport cab now with Sewak Travels and enjoy comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Indore Airport taxi booking. Let Sewak Travels make your airport commute effortless!",
  thingsToKnowHeading: "Things To Know About Indore",
  thingsToKnowInfo:
    "Indore, the largest city in Madhya Pradesh, is known for its culture, history, and modern feel. It has lively markets, beautiful temples, and tasty street food like poha-jalebi and samosas. Indore is also one of India's cleanest cities and an important business hub. It is easy to reach by road, rail, and air, making it a great place for travelers to enjoy both history and modern life.\n",
  faqHeading: "Frequently Asked Questions About Indore Airport Cab Booking",
  faqs: [
    {
      question: "How can I book an Indore Airport cab?",
      answer:
        "You can book an Indore Airport taxi easily by visiting our website sewaktravels.com, selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Indore?",
      answer:
        "Sewak Travels provides intra-city cab transfers that allow users to book cabs to and from Indore Airport in Indore.",
    },
    {
      question:
        "What types of vehicles do you offer for Indore Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Indore Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Indore Airport cabs?",
      answer:
        "Our Indore Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Absolutely! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question: "Can I book a cab for outstation travel from Indore Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Indore Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with your Indore cab bookings.",
    },
    {
      question: "Do you provide local sightseeing cabs in Indore?",
      answer:
        "Yes, we offer local taxi services for sightseeing and exploring the beautiful attractions in and around Indore.",
    },
    {
      question: "What safety measures are in place for your cab services?",
      answer:
        "We prioritize passenger safety by following standard sanitization protocols, ensuring our vehicles are clean and safe for travel.",
    },
  ],
};
export const jabalpurTaxiData = {
  pageTitle: "Book Jabalpur Airport Cabs",
  mainHeading: "Book Jabalpur Airport Cabs on Sewak Travels",
  subHeading: "Enjoy smooth Airport Pickup & Drop Services in Jabalpur.",
  airportInfo:
    "Jabalpur Airport, also known as Dumna Airport, is a key hub for both domestic and international travel in India. Established in 1932, this historic airport serves as a gateway to the vibrant culture, scenic landscapes, and historical sites of Jabalpur. Located just 25 km from the city center, Jabalpur Airport provides easy access for tourists looking to explore the region. Our commitment to excellence ensures that you receive prompt and reliable service at every step. From the moment you arrive at Jabalpur Airport, our drivers will be ready to assist you with your luggage and ensure a smooth ride to your destination. We understand that travel can be stressful, which is why we offer flexible booking options and real-time tracking for your cab. \n",
  serviceInfo:
    "At Sewak Travels, we make your travel experience smoother with our reliable Jabalpur Airport cab service. Choose Sewak Travels for a smooth airport transfer experience that allows you to focus on what truly matters—your journey and the experiences that await you in Jabalpur. Whether you are flying in for a quick visit or heading out, we ensure your journey to and from Jabalpur Airport is relaxed.\n",
  distanceHeading: "Jabalpur to Jabalpur Airport Cab Distance",
  distanceInfo:
    "The distance from Jabalpur Airport to the city center is approximately 25 km, depending on the route and any stops made. For a comfortable and stress-free journey, simply book a Jabalpur Airport taxi through Sewak Travel's user-friendly online platform. Whether you are heading to or from the airport, we ensure an amazing experience, making your travel convenient and reliable.\n",
  bookingHeading: "Online Jabalpur Airport Cab Booking with Sewak Travels",
  bookingInfo:
    "Welcome to Sewak Travels, where we offer reliable, comfortable, and on-time online Jabalpur Airport cab booking tailored to your needs. Whether you are arriving in or departing from Jabalpur, our Jabalpur Airport taxi service ensures a smooth travel experience from start to finish. With a fleet of modern vehicles and professional drivers, we provide the highest standard of airport cab services in Jabalpur.\n",
  img: "/assets/img/LocationDifferent/Jabalpur-airport.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book a Jabalpur Cab?",
  whyChooseSubHeading:
    "Book your Jabalpur Cabs effortlessly: When you choose Sewak for your cab service in Jabalpur, you get:",
  whyChooseInfo: [
    {
      title: "24/7 Booking & Travel Assistance:",
      detail: "Our Jabalpur Airport taxi service operates 24/7",
    },
    {
      title: "Outstation Cabs:",
      detail:
        "Need to travel outside Jabalpur? We offer outstation cabs from Jabalpur Airport",
    },
    {
      title: "Competitive Rates:",
      detail: "With Jabalpur Airport taxi per km rates",
    },
    {
      title: "Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi Jabalpur Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "Professional Drivers:",
      detail:
        "Sewak's drivers are skilled professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title: "Diverse Jabalpur Taxi Options:",
      detail: "When selecting our Jabalpur cab service for your journey",
    },
  ],
  howToBookHeading: "How to Book a Jabalpur Airport Taxi?",
  howToBookInfo:
    "Sewak Travels solves the issue of booking a Jabalpur Cab by offering premium services at affordable Jabalpur taxi rates, complete with a pickup-drop facility and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit https://sewaktravels.com/, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, and proceed to payment.\n",
  taxiServicesHeading:
    "Various Taxi Services in Jabalpur Airport by Sewak Travels",
  taxiServices: [
    {
      title: "Outstation Cabs from Jabalpur Airport:",
      description:
        "Sewak Travels offers reliable outstation cabs from Jabalpur Airport",
    },
    {
      title: "Local Taxis from Jabalpur Airport:",
      description:
        "Need a ride within Jabalpur? We make it easy to book local taxis from Jabalpur Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "Corporate Airport Transfers:",
      description:
        "We also cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "Taxi Service in Jabalpur:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: "Easy Jabalpur Airport Cab Booking for Every Need",
  easyBookingInfo:
    "Whether you are visiting Jabalpur for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Jabalpur Airport taxi contact number and online platforms make it easy for you to book a cab within minutes.\n",
  easyBookingPoints: [
    "Affordable Jabalpur Airport Taxi Fare",
    "Quick & Reliable Airport Taxi Service",
    "Car Rental Services for Jabalpur Airport",
  ],
  bookingCallToAction:
    "Book your Jabalpur Airport cab now with Sewak Travels and enjoy comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Jabalpur Airport taxi booking. Let Sewak Travels make your airport commute effortless!",
  thingsToKnowHeading: "Things To Know About Jabalpur",
  thingsToKnowInfo:
    "Jabalpur, in Madhya Pradesh, is known for its culture, beautiful nature, and history. Famous spots include the marble rocks at Bhedaghat, Madan Mahal Fort, and Rani Durgavati Museum. The city also has delicious food like kebabs and poha. Well-connected by road, rail, and air, Jabalpur is a great place for travelers to explore.\n",
  faqHeading: "Frequently Asked Questions About Jabalpur Airport Cab Booking",
  faqs: [
    {
      question: "How can I book a Jabalpur Airport cab?",
      answer:
        "You can book a Jabalpur Airport taxi easily by visiting our website sewaktravels.com, selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Jabalpur?",
      answer:
        "Sewak Travels provides intra-city cab transfers that allow users to book cabs to and from Jabalpur Airport in Jabalpur.",
    },
    {
      question:
        "What types of vehicles do you offer for Jabalpur Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Jabalpur Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Jabalpur Airport cabs?",
      answer:
        "Our Jabalpur Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Absolutely! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question: "Can I book a cab for outstation travel from Jabalpur Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Jabalpur Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with your Jabalpur cab bookings.",
    },
    {
      question: "Do you provide local sightseeing cabs in Jabalpur?",
      answer:
        "Yes, we offer local taxi services for sightseeing and exploring the beautiful attractions in and around Jabalpur.",
    },
    {
      question: "What safety measures are in place for your cabs?",
      answer:
        "Our cabs are regularly sanitized, and all drivers adhere to safety protocols to ensure a hygienic and safe travel experience.",
    },
  ],
};
export const jaipurTaxiData = {
  pageTitle: "Book Jaipur Airport Cabs",
  mainHeading: "Book Jaipur Airport Cabs on Sewak Travels",
  subHeading: "Enjoy smooth Airport Pickup & Drop Services in Jaipur.",
  airportInfo:
    "Jaipur Airport, also known as Jaipur International Airport, is a key hub for both domestic and international travel in India. Established in 1942, this historic airport serves as a gateway to the vibrant culture, palaces, and historical sites of Jaipur. Located just 13 km from the city center, Jaipur Airport provides easy access for tourists looking to explore the region. Our commitment to excellence ensures that you receive prompt and reliable service at every step. From the moment you arrive at Jaipur Airport, our drivers will be ready to assist you with your luggage and ensure a smooth ride to your destination. We understand that travel can be stressful, which is why we offer flexible booking options and real-time tracking for your cab. \n",
  serviceInfo:
    "At Sewak Travels, we make your travel experience smoother with our reliable Jaipur Airport cab service.Choose Sewak Travels for a smooth airport transfer experience that allows you to focus on what truly matters—your journey and the experiences that await you in Jaipur.Whether you are flying in for a quick visit or heading out, we ensure your journey to and from Jaipur Airport is relaxed.\n",
  distanceHeading: "Jaipur to Jaipur Airport Cab Distance",
  distanceInfo:
    "The distance from Jaipur Airport to the city center is approximately 13 km, depending on the route and any stops made. For a comfortable and stress-free journey, simply book a Jaipur Airport taxi through Sewak Travel's user-friendly online platform. Whether you are heading to or from the airport, we ensure an amazing experience, making your travel convenient and reliable.\n",
  bookingHeading: "Online Jaipur Airport Cab Booking with Sewak Travels",
  bookingInfo:
    "Welcome to Sewak Travels, where we offer reliable, comfortable, and on-time online Jaipur Airport cab booking tailored to your needs. Whether you are arriving in or departing from Jaipur, our Jaipur Airport taxi service ensures a smooth travel experience from start to finish. With a fleet of modern vehicles and professional drivers, we provide the highest standard of airport cab services in Jaipur.\n",
  img: "/assets/img/LocationDifferent/Jaipur-airport.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book a Jaipur Cab?",
  whyChooseSubHeading:
    "Book your Jaipur Cabs effortlessly: When you choose Sewak for your cab service in Jaipur, you get:",
  whyChooseInfo: [
    {
      title: "24/7 Booking & Travel Assistance:",
      detail: "Our Jaipur Airport taxi service operates 24/7",
    },
    {
      title: "Outstation Cabs:",
      detail:
        "Need to travel outside Jaipur? We offer outstation cabs from Jaipur Airport",
    },
    {
      title: "Competitive Rates:",
      detail: "With Jaipur Airport taxi per km rates",
    },
    {
      title: "Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi Jaipur Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "Professional Drivers:",
      detail:
        "Sewak's drivers are skilled professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title: "Diverse Jaipur Taxi Options:",
      detail: "When selecting our Jaipur cab service for your journey",
    },
  ],
  howToBookHeading: "How to Book a Jaipur Airport Taxi?",
  howToBookInfo:
    "Sewak Travels solves the issue of booking a Jaipur Cab by offering premium services at affordable Jaipur taxi rates, complete with a pickup-drop facility and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit https://sewaktravels.com/, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, and proceed to payment.\n",
  taxiServicesHeading:
    "Various Taxi Services in Jaipur Airport by Sewak Travels",
  taxiServices: [
    {
      title: "Outstation Cabs from Jaipur Airport:",
      description:
        "Sewak Travels offers reliable outstation cabs from Jaipur Airport",
    },
    {
      title: "Local Taxis from Jaipur Airport:",
      description:
        "Need a ride within Jaipur? We make it easy to book local taxis from Jaipur Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "Corporate Airport Transfers:",
      description:
        "We also cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "Taxi Service in Jaipur:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: "Easy Jaipur Airport Cab Booking for Every Need",
  easyBookingInfo:
    "Whether you are visiting Jaipur for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Jaipur Airport taxi contact number and online platforms make it easy for you to book a cab within minutes.\n",
  easyBookingPoints: [
    "Affordable Jaipur Airport Taxi Fare",
    "Quick & Reliable Airport Taxi Service",
    "Car Rental Services for Jaipur Airport",
  ],
  bookingCallToAction:
    "Book your Jaipur Airport cab now with Sewak Travels and enjoy comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Jaipur Airport taxi booking. Let Sewak Travels make your airport commute effortless!",
  thingsToKnowHeading: "Things To Know About Jaipur",
  thingsToKnowInfo:
    "Jaipur, known as the Pink City, is famous for its palaces, forts, and rich culture. As the capital of Rajasthan, it attracts tourists with sights like Amer Fort, City Palace, Hawa Mahal, and Jantar Mantar. The city is known for its beautiful buildings, lively markets, and tasty Rajasthani food. From traditional crafts to modern shopping, Jaipur offers a mix of history and modern life. Its warm hospitality and festive vibe make it a great place to visit.\n",
  faqHeading: "Frequently Asked Questions About Jaipur Airport Cab Booking",
  faqs: [
    {
      question: "How can I book a Jaipur Airport cab?",
      answer:
        "You can book a Jaipur Airport taxi easily by visiting our website sewaktravels.com, selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Jaipur?",
      answer:
        "Sewak Travels provides intra-city cab transfers that allow users to book cabs to and from Jaipur Airport in Jaipur.",
    },
    {
      question:
        "What types of vehicles do you offer for Jaipur Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Jaipur Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Jaipur Airport cabs?",
      answer:
        "Our Jaipur Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Absolutely! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question: "Can I book a cab for outstation travel from Jaipur Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Jaipur Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with your Jaipur cab bookings.",
    },
    {
      question: "Do you provide local sightseeing cabs in Jaipur?",
      answer:
        "Yes, we offer local taxi services for sightseeing and exploring the beautiful attractions in and around Jaipur.",
    },
    {
      question: "What safety measures are in place for your cab services?",
      answer:
        "We prioritize passenger safety by following standard sanitization protocols, ensuring our vehicles are clean and safe for travel.",
    },
  ],
};
export const jodhpurTaxiData = {
  pageTitle: "Book Jodhpur Airport Cabs",
  mainHeading: "Book Jodhpur Airport Cabs on Sewak Travels",
  subHeading: "Enjoy smooth Airport Pickup & Drop Services in Jodhpur.",
  airportInfo:
    "Jodhpur Airport, also known as Jodhpur International Airport, is a key hub for both domestic and international travel in India. Established in 1950, this historic airport serves as a gateway to the royal heritage, deserts, and vibrant culture of Jodhpur. Located just 5 km from the city center, Jodhpur Airport provides easy access for tourists looking to explore the region. Our commitment to excellence ensures that you receive prompt and reliable service at every step. From the moment you arrive at Jodhpur Airport, our drivers will be ready to assist you with your luggage and ensure a smooth ride to your destination. We understand that travel can be stressful, which is why we offer flexible booking options and real-time tracking for your cab.\n",
  serviceInfo:
    "At Sewak Travels, we make your travel experience smoother with our reliable Jodhpur Airport cab service.Choose Sewak Travels for a smooth airport transfer experience that allows you to focus on what truly matters—your journey and the experiences that await you in Jodhpur. Whether you are flying in for a quick visit or heading out, we ensure your journey to and from Jodhpur Airport is relaxed.\n",
  distanceHeading: "Jodhpur to Jodhpur Airport Cab Distance",
  distanceInfo:
    "The distance from Jodhpur Airport to the city center is approximately 5 km, depending on the route and any stops made. For a comfortable and stress-free journey, simply book a Jodhpur Airport taxi through Sewak Travel's user-friendly online platform. Whether you are heading to or from the airport, we ensure an amazing experience, making your travel convenient and reliable.\n",
  bookingHeading: "Online Jodhpur Airport Cab Booking with Sewak Travels",
  bookingInfo:
    "Welcome to Sewak Travels, where we offer reliable, comfortable, and on-time online Jodhpur Airport cab booking tailored to your needs. Whether you are arriving in or departing from Jodhpur, our Jodhpur Airport taxi service ensures a smooth travel experience from start to finish. With a fleet of modern vehicles and professional drivers, we provide the highest standard of airport cab services in Jodhpur.\n",
  img: "/assets/img/LocationDifferent/Jaipur-airport.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book a Jodhpur Cab?",
  whyChooseSubHeading:
    "Book your Jodhpur cabs effortlessly: When you choose Sewak for your cab service in Jodhpur, you get:",
  whyChooseInfo: [
    {
      title: "24/7 Booking & Travel Assistance:",
      detail: "Our Jodhpur Airport taxi service operates 24/7",
    },
    {
      title: "Outstation Cabs:",
      detail:
        "Need to travel outside Jodhpur? We offer outstation cabs from Jodhpur Airport",
    },
    {
      title: "Competitive Rates:",
      detail: "With Jodhpur Airport taxi per km rates",
    },
    {
      title: "Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi at Jodhpur Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "Professional Drivers:",
      detail:
        "Sewak's drivers are skilled professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title: "Diverse Jodhpur Taxi Options:",
      detail: "When selecting our Jodhpur cab service for your journey",
    },
  ],
  howToBookHeading: "How to Book a Jodhpur Airport Taxi?",
  howToBookInfo:
    "Sewak Travels solves the issue of booking a Jodhpur cab by offering premium services at affordable Jodhpur taxi rates, complete with a pickup-drop facility and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit https://sewaktravels.com/, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, and proceed to payment.\n",
  taxiServicesHeading:
    "Various Taxi Services in Jodhpur Airport by Sewak Travels",
  taxiServices: [
    {
      title: "Outstation Cabs from Jodhpur Airport:",
      description:
        "Sewak Travels offers reliable outstation cabs from Jodhpur Airport",
    },
    {
      title: "Local Taxis from Jodhpur Airport:",
      description:
        "Need a ride within Jodhpur? We make it easy to book local taxis from Jodhpur Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "Corporate Airport Transfers:",
      description:
        "We also cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "Taxi Service in Jodhpur:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: "Easy Jodhpur Airport Cab Booking for Every Need",
  easyBookingInfo:
    "Whether you are visiting Jodhpur for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Jodhpur Airport taxi contact number and online platforms make it easy for you to book a cab within minutes.\n",
  easyBookingPoints: [
    "Affordable Jodhpur Airport Taxi Fare",
    "Quick & Reliable Airport Taxi Service",
    "Car Rental Services for Jodhpur Airport",
  ],
  bookingCallToAction:
    "Book your Jodhpur Airport cab now with Sewak Travels and enjoy comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Jodhpur Airport taxi booking. Let Sewak Travels make your airport commute effortless!",
  thingsToKnowHeading: "Things To Know About Jodhpur",
  thingsToKnowInfo:
    'Jodhpur, also known as the "Blue City" due to its blue-painted houses, is famous for its historical and cultural richness. Key attractions include the majestic Mehrangarh Fort, the stunning Umaid Bhawan Palace, and the vibrant markets selling handicrafts, textiles, and spices. Jodhpur is also renowned for its Marwari cuisine, including dishes like dal baati churma and mirchi vada. With its royal heritage, scenic landscapes, and desert vistas, Jodhpur is a destination that offers a unique blend of history and adventure.\n',
  faqHeading: "Frequently Asked Questions About Jodhpur Airport Cab Booking",
  faqs: [
    {
      question: "How can I book a Jodhpur Airport cab?",
      answer:
        "You can book a Jodhpur Airport taxi easily by visiting our website sewaktravels.com, selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Jodhpur?",
      answer:
        "Sewak Travels provides intra-city cab transfers that allow users to book cabs to and from Jodhpur Airport in Jodhpur.",
    },
    {
      question:
        "What types of vehicles do you offer for Jodhpur Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Jodhpur Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Jodhpur Airport cabs?",
      answer:
        "Our Jodhpur Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Absolutely! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question: "Can I book a cab for outstation travel from Jodhpur Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Jodhpur Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with your Jodhpur cab bookings.",
    },
    {
      question: "Do you provide local sightseeing cabs in Jodhpur?",
      answer:
        "Yes, we offer local taxi services for sightseeing and exploring the city.",
    },
    {
      question:
        "Can I schedule a cab in advance for early morning or late-night airport transfers?",
      answer:
        "Yes, you can book a cab in advance for early morning or late-night transfers, ensuring timely and reliable service regardless of the hour.",
    },
  ],
};
export const kangraTaxiData = {
  pageTitle: "Book Kangra Airport Cabs",
  mainHeading: "Book Kangra Airport Cabs on Sewak Travels",
  subHeading: "Enjoy smooth Airport Pickup & Drop Services in Kangra.",
  airportInfo:
    "Kangra Airport, also known as Gaggal Airport, is a key hub for both domestic and international travel in India. Established in 1932, this historic airport serves as a gateway to the scenic landscapes, temples, and cultural treasures of Kangra. Located just 13 km from the city center, Kangra Airport provides easy access for tourists looking to explore the region. Our commitment to excellence ensures that you receive prompt and reliable service at every step. From the moment you arrive at Kangra Airport, our drivers will be ready to assist you with your luggage and ensure a smooth ride to your destination. We understand that travel can be stressful, which is why we offer flexible booking options and real-time tracking for your cab. \n",
  serviceInfo:
    "At Sewak Travels, we make your travel experience smoother with our reliable Kangra Airport cab service.Choose Sewak Travels for a smooth airport transfer experience that allows you to focus on what truly matters—your journey and the experiences that await you in Kangra.Whether you are flying in for a quick visit or heading out, we ensure your journey to and from Kangra Airport is relaxed.\n",
  distanceHeading: "Kangra to Kangra Airport Cab Distance",
  distanceInfo:
    "The distance from Kangra Airport to the city center is approximately 13 km, depending on the route and any stops made. For a comfortable and stress-free journey, simply book a Kangra Airport taxi through Sewak Travel's user-friendly online platform. Whether you are heading to or from the airport, we ensure an amazing experience, making your travel convenient and reliable.\n",
  bookingHeading: "Online Kangra Airport Cab Booking with Sewak Travels",
  bookingInfo:
    "Welcome to Sewak Travels, where we offer reliable, comfortable, and on-time online Kangra Airport cab booking tailored to your needs. Whether you are arriving in or departing from Kangra, our Kangra Airport taxi service ensures a smooth travel experience from start to finish. With a fleet of modern vehicles and professional drivers, we provide the highest standard of airport cab services in Kangra.\n",
  img: "/assets/img/LocationDifferent/Jaipur-airport.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book a Kangra Cab?",
  whyChooseSubHeading:
    "Book your Kangra Cabs effortlessly: When you choose Sewak for your cab service in Kangra, you get:",
  whyChooseInfo: [
    {
      title: "24/7 Booking & Travel Assistance:",
      detail: "Our Kangra Airport taxi service operates 24/7",
    },
    {
      title: "Outstation Cabs:",
      detail:
        "Need to travel outside Kangra? We offer outstation cabs from Kangra Airport",
    },
    {
      title: "Competitive Rates:",
      detail: "With Kangra Airport taxi per km rates",
    },
    {
      title: "Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi Kangra Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "Professional Drivers:",
      detail:
        "Sewak's drivers are skilled professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title: "Diverse Kangra Taxi Options:",
      detail: "When selecting our Kangra cab service for your journey",
    },
  ],
  howToBookHeading: "How to Book a Kangra Airport Taxi?",
  howToBookInfo:
    "Sewak Travels solves the issue of booking a Kangra Cab by offering premium services at affordable Kangra taxi rates, complete with a pickup-drop facility and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit https://sewaktravels.com/, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, and proceed to payment.\n",
  taxiServicesHeading:
    "Various Taxi Services in Kangra Airport by Sewak Travels",
  taxiServices: [
    {
      title: "Outstation Cabs from Kangra Airport:",
      description:
        "Sewak Travels offers reliable outstation cabs from Kangra Airport",
    },
    {
      title: "Local Taxis from Kangra Airport:",
      description:
        "Need a ride within Kangra? We make it easy to book local taxis from Kangra Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "Corporate Airport Transfers:",
      description:
        "We also cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "Taxi Service in Kangra:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: "Easy Kangra Airport Cab Booking for Every Need",
  easyBookingInfo:
    "Whether you are visiting Kangra for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Kangra Airport taxi contact number and online platforms make it easy for you to book a cab within minutes.\n",
  easyBookingPoints: [
    "Affordable Kangra Airport Taxi Fare",
    "Quick & Reliable Airport Taxi Service",
    "Car Rental Services for Kangra Airport",
  ],
  bookingCallToAction:
    "Book your Kangra Airport cab now with Sewak Travels and enjoy comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Kangra Airport taxi booking. Let Sewak Travels make your airport commute effortless!",
  thingsToKnowHeading: "Things To Know About Kangra",
  thingsToKnowInfo:
    "Kangra, nestled in the foothills of the Himalayas, is known for its rich cultural heritage, scenic landscapes, and religious significance. Famous for its ancient temples, monasteries, and the Kangra Fort, the region attracts spiritual seekers and nature lovers alike. Kangra's culinary scene is highlighted by local Himachali delicacies, and the area offers a peaceful retreat with its lush greenery and serene environment.\n",
  faqHeading: "Frequently Asked Questions About Kangra Airport Cab Booking",
  faqs: [
    {
      question: "How can I book a Kangra Airport cab?",
      answer:
        "You can book a Kangra Airport taxi easily by visiting our website sewaktravels.com, selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Kangra?",
      answer:
        "Sewak Travels provides intra-city cab transfers that allow users to book cabs to and from Kangra Airport in Kangra.",
    },
    {
      question:
        "What types of vehicles do you offer for Kangra Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Kangra Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Kangra Airport cabs?",
      answer:
        "Our Kangra Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Absolutely! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question: "Can I book a cab for outstation travel from Kangra Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Kangra Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with your Kangra cab bookings.",
    },
    {
      question: "Do you provide local sightseeing cabs in Kangra?",
      answer:
        "Yes, we offer local taxi services for sightseeing and exploring the beautiful attractions in and around Kangra.",
    },
    {
      question: "What safety measures are in place for your cab services?",
      answer:
        "We prioritize passenger safety by following standard sanitization protocols, ensuring our vehicles are clean and safe for travel.",
    },
  ],
};
export const kannurTaxiData = {
  pageTitle: "Book Kannur Airport Cabs",
  mainHeading: "Book Kannur Airport Cabs on Sewak Travels",
  subHeading: "Enjoy smooth Airport Pickup & Drop Services in Kannur.",
  airportInfo:
    "Kannur Airport, also known as Kannur International Airport, is a key hub for both domestic and international travel in India. Established in 2018, this airport serves as a gateway to the beautiful beaches, lush landscapes, and rich culture of Kerala. Located just 25 km from the city center, Kannur Airport provides easy access for tourists looking to explore the region. Our commitment to excellence ensures that you receive prompt and reliable service at every step. From the moment you arrive at Kannur Airport, our drivers will be ready to assist you with your luggage and ensure a smooth ride to your destination. We understand that travel can be stressful, which is why we offer flexible booking options and real-time tracking for your cab. \n",
  serviceInfo:
    "At Sewak Travels, we make your travel experience smoother with our reliable Kannur Airport cab service.Choose Sewak Travels for a smooth airport transfer experience that allows you to focus on what truly matters—your journey and the experiences that await you in Kannur.Whether you are flying in for a quick visit or heading out, we ensure your journey to and from Kannur Airport is relaxed.\n",
  distanceHeading: "Kannur to Kannur Airport Cab Distance",
  distanceInfo:
    "The distance from Kannur Airport to the city center is approximately 25 km, depending on the route and any stops made. For a comfortable and stress-free journey, simply book a Kannur Airport taxi through Sewak Travel's user-friendly online platform. Whether you are heading to or from the airport, we ensure an amazing experience, making your travel convenient and reliable.\n",
  bookingHeading: "Online Kannur Airport Cab Booking with Sewak Travels",
  bookingInfo:
    "Welcome to Sewak Travels, where we offer reliable, comfortable, and on-time online Kannur Airport cab booking tailored to your needs. Whether you are arriving in or departing from Kannur, our Kannur Airport taxi service ensures a smooth travel experience from start to finish. With a fleet of modern vehicles and professional drivers, we provide the highest standard of airport cab services in Kannur.\n",
  img: "/assets/img/LocationDifferent/Kannur-airport.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book a Kannur Cab?",
  whyChooseSubHeading:
    "Book your Kannur Cabs effortlessly: When you choose Sewak for your cab service in Kannur, you get:",
  whyChooseInfo: [
    {
      title: "24/7 Booking & Travel Assistance:",
      detail: "Our Kannur Airport taxi service operates 24/7",
    },
    {
      title: "Outstation Cabs:",
      detail:
        "Need to travel outside Kannur? We offer outstation cabs from Kannur Airport",
    },
    {
      title: "Competitive Rates:",
      detail: "With Kannur Airport taxi per km rates",
    },
    {
      title: "Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi Kannur Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "Professional Drivers:",
      detail:
        "Sewak's drivers are skilled professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title: "Diverse Kannur Taxi Options:",
      detail: "When selecting our Kannur cab service for your journey",
    },
  ],
  howToBookHeading: "How to Book a Kannur Airport Taxi?",
  howToBookInfo:
    "Sewak Travels solves the issue of booking a Kannur Cab by offering premium services at affordable Kannur taxi rates, complete with a pickup-drop facility and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit https://sewaktravels.com/, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, and proceed to payment.\n",
  taxiServicesHeading:
    "Various Taxi Services in Kannur Airport by Sewak Travels",
  taxiServices: [
    {
      title: "Outstation Cabs from Kannur Airport:",
      description:
        "Sewak Travels offers reliable outstation cabs from Kannur Airport",
    },
    {
      title: "Local Taxis from Kannur Airport:",
      description:
        "Need a ride within Kannur? We make it easy to book local taxis from Kannur Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "Corporate Airport Transfers:",
      description:
        "We also cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "Taxi Service in Kannur:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: "Easy Kannur Airport Cab Booking for Every Need",
  easyBookingInfo:
    "Whether you are visiting Kannur for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Kannur Airport taxi contact number and online platforms make it easy for you to book a cab within minutes.\n",
  easyBookingPoints: [
    "Affordable Kannur Airport Taxi Fare",
    "Quick & Reliable Airport Taxi Service",
    "Car Rental Services for Kannur Airport",
  ],
  bookingCallToAction:
    "Book your Kannur Airport cab now with Sewak Travels and enjoy comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Kannur Airport taxi booking. Let Sewak Travels make your airport commute effortless!",
  thingsToKnowHeading: "Things To Know About Kannur",
  thingsToKnowInfo:
    "Kannur, located in the state of Kerala, is renowned for its historical significance, cultural richness, and scenic beauty. The city is home to beautiful beaches, like Payyambalam and Muzhappilangad, and is famous for traditional art forms like Theyyam. With colonial-era architecture, rich folklore, and vibrant festivals, Kannur is a captivating destination for travelers. The region’s cuisine, especially its seafood, is celebrated for its authentic Kerala flavors, featuring dishes like Malabar biryani and spicy prawn curry. Well-connected by road, rail, and air, Kannur is an ideal getaway for those seeking a unique blend of culture and natural beauty.\n",
  faqHeading: "Frequently Asked Questions About Kannur Airport Cab Booking",
  faqs: [
    {
      question: "How can I book a Kannur Airport cab?",
      answer:
        "You can book a Kannur Airport taxi easily by visiting our website sewaktravels.com, selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Kannur?",
      answer:
        "Sewak Travels provides intra-city cab transfers that allow users to book cabs to and from Kannur Airport.",
    },
    {
      question:
        "What types of vehicles do you offer for Kannur Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Kannur Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Kannur Airport cabs?",
      answer:
        "Our Kannur Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Absolutely! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question: "Can I book a cab for outstation travel from Kannur Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Kannur Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with your Kannur cab bookings.",
    },
    {
      question: "Do you provide local sightseeing cabs in Kannur?",
      answer:
        "Yes, we offer local taxi services for sightseeing and exploring the beautiful attractions in and around Kannur.",
    },
    {
      question: "What safety measures are in place for your cab services?",
      answer:
        "We prioritize passenger safety by following standard sanitization protocols, ensuring our vehicles are clean and safe for travel.",
    },
  ],
};
export const kanpurTaxiData = {
  pageTitle: "Book Kanpur Airport Cabs",
  mainHeading: "Book Kanpur Airport Cabs on Sewak Travels",
  subHeading: "Enjoy smooth Airport Pickup & Drop Services in Kanpur.",
  airportInfo:
    "Kanpur Airport, also known as Chakeri Airport, is a key hub for both domestic travel in India. It serves as a gateway to the rich industrial and cultural heritage of Kanpur. Located approximately 14 km from the city center, Kanpur Airport provides easy access for tourists and business travelers. Our commitment to excellence ensures that you receive prompt and reliable service at every step. From the moment you arrive at Kanpur Airport, our drivers will be ready to assist you with your luggage and ensure a smooth ride to your destination. We understand that travel can be stressful, which is why we offer flexible booking options and real-time tracking for your cab. \n",
  serviceInfo:
    "At Sewak Travels, we make your travel experience smoother with our reliable Kanpur Airport cab service.Choose Sewak Travels for a smooth airport transfer experience that allows you to focus on what truly matters—your journey and the experiences that await you in Kanpur.Whether you are flying in for a quick visit or heading out, we ensure your journey to and from Kanpur Airport is relaxed.\n",
  distanceHeading: "Kanpur to Kanpur Airport Cab Distance",
  distanceInfo:
    "The distance from Kanpur Airport to the city center is approximately 14 km, depending on the route and any stops made. For a comfortable and stress-free journey, simply book a Kanpur Airport taxi through Sewak Travel's user-friendly online platform. Whether you are heading to or from the airport, we ensure an amazing experience, making your travel convenient and reliable.\n",
  bookingHeading: "Online Kanpur Airport Cab Booking with Sewak Travels",
  bookingInfo:
    "Welcome to Sewak Travels, where we offer reliable, comfortable, and on-time Online Kanpur Airport cab booking tailored to your needs. Whether you are arriving in or departing from Kanpur, our Kanpur Airport taxi service ensures a smooth travel experience from start to finish. With a fleet of modern vehicles and professional drivers, we provide the highest standard of airport cab services in Kanpur.\n",
  img: "/assets/img/LocationDifferent/kanpur-airport.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book a Kanpur Cab?",
  whyChooseSubHeading:
    "Book your Kanpur Cabs effortlessly: When you choose Sewak for your cab service in Kanpur, you get:",
  whyChooseInfo: [
    {
      title: "24/7 Booking & Travel Assistance:",
      detail: "Our Kanpur Airport taxi service operates 24/7",
    },
    {
      title: "Outstation Cabs:",
      detail:
        "Need to travel outside Kanpur? We offer outstation cabs from Kanpur Airport",
    },
    {
      title: "Competitive Rates:",
      detail: "With Kanpur Airport taxi per km rates",
    },
    {
      title: "Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi Kanpur Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "Professional Drivers:",
      detail:
        "Sewak's drivers are skilled professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title: "Diverse Kanpur Taxi Options:",
      detail: "When selecting our Kanpur cab service for your journey",
    },
  ],
  howToBookHeading: "How to Book a Kanpur Airport Taxi?",
  howToBookInfo:
    "Sewak Travels solves the issue of booking a Kanpur Cab by offering premium services at affordable Kanpur taxi rates, complete with a pickup-drop facility and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit https://sewaktravels.com/, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, and proceed to payment.\n",
  taxiServicesHeading:
    "Various Taxi Services in Kanpur Airport by Sewak Travels",
  taxiServices: [
    {
      title: "Outstation Cabs from Kanpur Airport:",
      description:
        "Sewak Travels offers reliable outstation cabs from Kanpur Airport",
    },
    {
      title: "Local Taxis from Kanpur Airport:",
      description:
        "Need a ride within Kanpur? We make it easy to book local taxis from Kanpur Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "Corporate Airport Transfers:",
      description:
        "We also cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "Taxi Service in Kanpur:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: "Easy Kanpur Airport Cab Booking for Every Need",
  easyBookingInfo:
    "Whether you are visiting Kanpur for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Kanpur Airport taxi contact number and online platforms make it easy for you to book a cab within minutes.\n",
  easyBookingPoints: [
    "Affordable Kanpur Airport Taxi Fare",
    "Quick & Reliable Airport Taxi Service",
    "Car Rental Services for Kanpur Airport",
  ],
  bookingCallToAction:
    "Book your Kanpur Airport cab now with Sewak Travels and enjoy comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Kanpur Airport taxi booking. Let Sewak Travels make your airport commute effortless!",
  thingsToKnowHeading: "Things To Know About Kanpur",
  thingsToKnowInfo:
    "Kanpur is known for its industrial importance, as well as its cultural significance. The city is famous for its leather industry and also has beautiful parks, museums, and historical landmarks. Visitors can explore the Allen Forest Zoo, Nana Rao Park, or enjoy a boat ride on the Ganges. Kanpur’s bustling markets are also great for shopping, with leather goods being the star attraction.\n",
  faqHeading: "Frequently Asked Questions About Kanpur Airport Cab Booking",
  faqs: [
    {
      question: "How can I book a Kanpur Airport cab?",
      answer:
        "You can book a Kanpur Airport taxi easily by visiting our website sewaktravels.com, selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Kanpur?",
      answer:
        "Sewak Travels provides intra-city cab transfers that allow users to book cabs to and from Kanpur Airport in Kanpur.",
    },
    {
      question:
        "What types of vehicles do you offer for Kanpur Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Kanpur Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Kanpur Airport cabs?",
      answer:
        "Our Kanpur Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Absolutely! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question: "Can I book a cab for outstation travel from Kanpur Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Kanpur Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with your Kanpur cab bookings.",
    },
    {
      question: "Do you provide local sightseeing cabs in Kanpur?",
      answer:
        "Yes, we offer local taxi services for sightseeing and exploring the beautiful attractions in and around Kanpur.",
    },
    {
      question: "What safety measures are in place for your cab services?",
      answer:
        "We prioritize passenger safety by following standard sanitization protocols, ensuring our vehicles are clean and safe for travel.",
    },
  ],
};
export const kaziNazrulIslamTaxiData = {
  pageTitle: "Book Kazi Nazrul Islam Airport Cabs",
  mainHeading: "Book Kazi Nazrul Islam Airport Cabs on Sewak Travels",
  subHeading:
    "Enjoy smooth Airport Pickup & Drop Services in Kazi Nazrul Islam.",
  airportInfo:
    "Kazi Nazrul Islam Airport, also known as Durgapur Kazi Nazrul Islam International Airport, is an important gateway for both domestic and international travel in India. Established in 2013, this modern airport serves as a key entry point for travelers heading to the culturally rich region of West Bengal. Located just 14 km from the city center, the airport provides easy access for tourists eager to explore the area’s historical sites, cultural festivals, and natural beauty. \n",
  serviceInfo:
    "At Sewak Travels, we make your travel experience smoother with our reliable Kazi Nazrul Islam Airport cab service. Whether you are visiting for business or leisure, our drivers are ready to assist with your luggage and ensure a comfortable journey. Our flexible booking options and real-time tracking offer you a stress-free experience, allowing you to focus on your journey and the adventures that await in Kazi Nazrul Islam. Whether you are flying in for a quick visit or heading out, we ensure your journey to and from Kazi Nazrul Islam Airport is relaxed and smooth.\n",
  distanceHeading:
    "Kazi Nazrul Islam to Kazi Nazrul Islam Airport Cab Distance",
  distanceInfo:
    "The distance from Kazi Nazrul Islam Airport to the city center is approximately 14 km, depending on the route and any stops made. For a comfortable and stress-free journey, book a Kazi Nazrul Islam Airport taxi through Sewak Travels’ user-friendly online platform. Whether you are traveling to or from the airport, we ensure a fantastic experience, making your travel convenient and reliable.\n",
  bookingHeading:
    "Online Kazi Nazrul Islam Airport Cab Booking with Sewak Travels",
  bookingInfo:
    "Welcome to Sewak Travels, where we offer reliable, comfortable, and on-time Online Kazi Nazrul Islam Airport cab booking tailored to your needs. Whether you are arriving or departing, our Kazi Nazrul Islam Airport taxi service ensures a seamless travel experience from start to finish. With a fleet of modern vehicles and professional drivers, we provide the highest standard of airport cab services in the region.\n",
  img: "/assets/img/LocationDifferent/Kazi-Nazrul-Islam-airport.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book a Kazi Nazrul Islam Cab?",
  whyChooseSubHeading:
    "Book your Kazi Nazrul Islam Cabs effortlessly: When you choose Sewak for your cab service in Kazi Nazrul Islam, you get:",
  whyChooseInfo: [
    {
      title: "24/7 Booking & Travel Assistance:",
      detail: "Our Kazi Nazrul Islam Airport taxi service operates 24/7",
    },
    {
      title: "Outstation Cabs:",
      detail:
        "Need to travel outside Kazi Nazrul Islam? We offer outstation cabs from Kazi Nazrul Islam Airport",
    },
    {
      title: "Competitive Rates:",
      detail: "With Kazi Nazrul Islam Airport taxi per km rates",
    },
    {
      title: "Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi at Kazi Nazrul Islam Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "Professional Drivers:",
      detail:
        "Sewak's drivers are skilled professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title: "Diverse Kazi Nazrul Islam Taxi Options:",
      detail:
        "When selecting our Kazi Nazrul Islam cab service for your journey",
    },
  ],
  howToBookHeading: "How to Book a Kazi Nazrul Islam Airport Taxi?",
  howToBookInfo:
    "Sewak Travels solves the issue of booking a Kazi Nazrul Islam Cab by offering premium services at affordable Kazi Nazrul Islam taxi rates, complete with a pickup-drop facility and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit https://sewaktravels.com/, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, and proceed to payment.\n",
  taxiServicesHeading:
    "Various Taxi Services in Kazi Nazrul Islam Airport by Sewak Travels",
  taxiServices: [
    {
      title: "Outstation Cabs from Kazi Nazrul Islam Airport:",
      description:
        "Sewak Travels offers reliable outstation cabs from Kazi Nazrul Islam Airport",
    },
    {
      title: "Local Taxis from Kazi Nazrul Islam Airport:",
      description:
        "Need a ride within Kazi Nazrul Islam? We make it easy to book local taxis from Kazi Nazrul Islam Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "Corporate Airport Transfers:",
      description:
        "We also cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "Taxi Service in Kazi Nazrul Islam:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading:
    "Easy Kazi Nazrul Islam Airport Cab Booking for Every Need",
  easyBookingInfo:
    "Whether you are visiting Kazi Nazrul Islam for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Kazi Nazrul Islam Airport taxi contact number and online platforms make it easy for you to book a cab within minutes.\n",
  easyBookingPoints: [
    "Affordable Kazi Nazrul Islam Airport Taxi Fare",
    "Quick & Reliable Airport Taxi Service",
    "Car Rental Services for Kazi Nazrul Islam Airport",
  ],
  bookingCallToAction:
    "Book your Kazi Nazrul Islam Airport cab now with Sewak Travels and enjoy comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Kazi Nazrul Islam Airport taxi booking. Let Sewak Travels make your airport commute effortless!",
  thingsToKnowHeading: "Things To Know About Kazi Nazrul Islam",
  thingsToKnowInfo:
    "Kazi Nazrul Islam, in West Bengal, is a city rich in culture and history. Named after the famous Bengali poet, it has a mix of old temples, parks, and modern buildings. The city is close to Durgapur and is known for its tasty Bengali food. Kazi Nazrul Islam is easy to reach by road, rail, and air, making it a popular spot for both tourists and business travelers.\n",
  faqHeading:
    "Frequently Asked Questions About Kazi Nazrul Islam Airport Cab Booking",
  faqs: [
    {
      question: "How can I book a Kazi Nazrul Islam Airport cab?",
      answer:
        "Booking a Kazi Nazrul Islam Airport taxi is simple! Just visit our website sewaktravels.com, choose your preferred cab option, fill in your details, and complete the booking in a few easy steps.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide at Kazi Nazrul Islam Airport?",
      answer:
        "Sewak Travels offers comfortable and reliable intra-city airport transfers, ensuring you can conveniently travel to and from Kazi Nazrul Islam Airport without any hassle.",
    },
    {
      question:
        "What types of vehicles do you offer for Kazi Nazrul Islam Airport cab services?",
      answer:
        "We provide a variety of options including 4-seater sedans, 7-seater SUVs, and luxury vehicles. Our fleet ensures that you can choose a cab based on your group size and preferences.",
    },
    {
      question:
        "Is there a prepaid taxi option available for Kazi Nazrul Islam Airport?",
      answer:
        "Yes, we offer a prepaid taxi service at Kazi Nazrul Islam Airport, allowing you to secure your ride in advance and avoid any last-minute inconveniences.",
    },
    {
      question: "What are the rates for Kazi Nazrul Islam Airport cabs?",
      answer:
        "Our taxi fare at Kazi Nazrul Islam Airport is based on a per km rate, ensuring clear and competitive pricing with no hidden charges. The fare depends on your destination and type of vehicle chosen.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Yes! All our drivers are highly trained, professional, and experienced. They are well-versed in local routes and follow all necessary safety measures to ensure your comfort and security.",
    },
    {
      question: "What if I need a ride at a late hour?",
      answer:
        "Sewak Travels operates 24/7, so you can book a Kazi Nazrul Islam Airport cab any time, day or night. We’re here to meet your travel needs around the clock.",
    },
    {
      question: "Can I book an outstation cab from Kazi Nazrul Islam Airport?",
      answer:
        "Absolutely! Sewak Travels provides outstation cab services from Kazi Nazrul Islam Airport, allowing you to travel to nearby cities and destinations comfortably.",
    },
    {
      question: "How do I cancel or modify my booking?",
      answer:
        "You can easily cancel or modify your Kazi Nazrul Islam Airport cab booking by contacting our customer support or using our online booking platform, depending on your needs.",
    },
    {
      question: "Is there a discount for long-distance rides?",
      answer:
        "We often provide special offers and discounts for long-distance rides. Please contact our customer service team for the latest promotions and rates.",
    },
  ],
};
export const kempegowdaTaxiData = {
  pageTitle: "Book Kempegowda Airport Cabs",
  mainHeading: "Book Kempegowda Airport Cabs on Sewak Travels",
  subHeading: "Enjoy smooth Airport Pickup & Drop Services in Kempegowda.",
  airportInfo:
    "Kempegowda International Airport, also known as Bangalore International Airport, is a major hub for both domestic and international travel in India. Established in 2008, this modern airport serves as a gateway to the bustling tech city of Bengaluru, its vibrant culture, historic sites, and renowned cuisine. Located just 40 km from the city center, Kempegowda Airport provides easy access for tourists and business travelers alike. Our commitment to excellence ensures that you receive prompt and reliable service at every step. From the moment you arrive at Kempegowda Airport, our drivers will be ready to assist you with your luggage and ensure a smooth ride to your destination. We understand that travel can be stressful, which is why we offer flexible booking options and real-time tracking for your cab. \n",
  serviceInfo:
    "At Sewak Travels, we make your travel experience smoother with our reliable Kempegowda Airport cab service. Choose Sewak Travels for a smooth airport transfer experience that allows you to focus on what truly matters—your journey and the experiences that await you in Bengaluru. Whether you are flying in for a quick visit or heading out, we ensure your journey to and from Kempegowda Airport is relaxed.\n",
  distanceHeading: "Kempegowda to Kempegowda Airport Cab Distance",
  distanceInfo:
    "The distance from Kempegowda Airport to the city center is approximately 40 km, depending on the route and any stops made. For a comfortable and stress-free journey, simply book a Kempegowda Airport taxi through Sewak Travels’ user-friendly online platform. Whether you are heading to or from the airport, we ensure an amazing experience, making your travel convenient and reliable.\n",
  bookingHeading: "Online Kempegowda Airport Cab Booking with Sewak Travels",
  bookingInfo:
    "Welcome to Sewak Travels, where we offer reliable, comfortable, and on-time Online Kempegowda Airport cab booking tailored to your needs. Whether you are arriving in or departing from Kempegowda, our Kempegowda Airport taxi service ensures a smooth travel experience from start to finish. With a fleet of modern vehicles and professional drivers, we provide the highest standard of airport cab services in Bengaluru.\n",
  img: "/assets/img/LocationDifferent/Kempegowda-airport.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book a Kempegowda Cab?",
  whyChooseSubHeading:
    "Book your Kempegowda Cabs effortlessly: When you choose Sewak for your cab service in Bengaluru, you get:",
  whyChooseInfo: [
    {
      title: "24/7 Booking & Travel Assistance:",
      detail: "Our Kempegowda Airport taxi service operates 24/7",
    },
    {
      title: "Outstation Cabs:",
      detail:
        "Need to travel outside Bengaluru? We offer outstation cabs from Kempegowda Airport",
    },
    {
      title: "Competitive Rates:",
      detail: "With Kempegowda Airport taxi per km rates",
    },
    {
      title: "Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi Kempegowda Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "Professional Drivers:",
      detail:
        "Sewak's drivers are skilled professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title: "Diverse Kempegowda Taxi Options:",
      detail: "When selecting our Kempegowda cab service for your journey",
    },
  ],
  howToBookHeading: "How to Book a Kempegowda Airport Taxi?",
  howToBookInfo:
    "Sewak Travels solves the issue of booking a Kempegowda Cab by offering premium services at affordable Kempegowda taxi rates, complete with a pickup-drop facility and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit https://sewaktravels.com/, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, and proceed to payment.",
  taxiServicesHeading:
    "Various Taxi Services in Kempegowda Airport by Sewak Travels",
  taxiServices: [
    {
      title: "Outstation Cabs from Kempegowda Airport:",
      description:
        "Sewak Travels offers reliable outstation cabs from Kempegowda Airport",
    },
    {
      title: "Local Taxis from Kempegowda Airport:",
      description:
        "Need a ride within Bengaluru? We make it easy to book local taxis from Kempegowda Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "Corporate Airport Transfers:",
      description:
        "We also cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "Taxi Service in Bengaluru:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: "Easy Kempegowda Airport Cab Booking for Every Need",
  easyBookingInfo:
    "Whether you are visiting Bengaluru for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Kempegowda Airport taxi contact number and online platforms make it easy for you to book a cab within minutes.\n",
  easyBookingPoints: [
    "Affordable Kempegowda Airport Taxi Fare",
    "Quick & Reliable Airport Taxi Service",
    "Car Rental Services for Kempegowda Airport",
  ],
  bookingCallToAction:
    "Book your Kempegowda Airport cab now with Sewak Travels and enjoy comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Kempegowda Airport taxi booking. Let Sewak Travels make your airport commute effortless!",
  thingsToKnowHeading: "Things To Know About Kempegowda (Bengaluru)",
  thingsToKnowInfo:
    "Bengaluru, also called the Silicon Valley of India, is a busy city known for its tech industry, culture, and nightlife. It's home to many IT companies, schools, and young professionals. Bengaluru has famous places like the Bengaluru Palace, Cubbon Park, and Lalbagh Garden. The city mixes old traditions with modern life. You can enjoy a variety of food, from local South Indian dishes to international cuisines. Whether for work or fun, Bengaluru has something for everyone.\n",
  faqHeading: "Frequently Asked Questions About Kempegowda Airport Cab Booking",
  faqs: [
    {
      question: "How can I book a Kempegowda Airport cab?",
      answer:
        "You can book a Kempegowda Airport taxi easily by visiting our website sewaktravels.com, selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Bengaluru?",
      answer:
        "Sewak Travels provides intra-city cab transfers that allow users to book cabs to and from Kempegowda Airport in Bengaluru.",
    },
    {
      question:
        "What types of vehicles do you offer for Kempegowda Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Kempegowda Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Kempegowda Airport cabs?",
      answer:
        "Our Kempegowda Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Absolutely! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question:
        "Can I book a cab for outstation travel from Kempegowda Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Kempegowda Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with your Kempegowda cab bookings.",
    },
    {
      question: "Do you offer local sightseeing cab services in Bengaluru?",
      answer:
        "Yes, we provide local taxi services for sightseeing and exploring Bengaluru’s attractions, including popular spots like Cubbon Park, Lalbagh, and the Bangalore Palace.",
    },
    {
      question: "What safety measures are in place for your cab services?",
      answer:
        "We prioritize passenger safety by following strict sanitization protocols. All vehicles are regularly cleaned, and our drivers wear masks and maintain hygiene standards to ensure a safe journey.",
    },
  ],
};
export const kozhikodeTaxiData = {
  pageTitle: "Book Kozhikode Airport Cabs",
  mainHeading: "Book Kozhikode Airport Cabs on Sewak Travels",
  subHeading: "Enjoy easy Airport Pickup & Drop Services in Kozhikode.",
  airportInfo:
    "Kozhikode Airport, or Calicut International Airport, is a major airport in India for both domestic and international flights. Opened in 1988, it is close to the rich culture, beautiful beaches, and historic sites of Kozhikode, just 28 km from the city center. \n",
  serviceInfo:
    "At Sewak Travels, we make your travel experience smoother with our reliable Kozhikode Airport cab service. We know travel can be stressful, so we offer flexible booking options and real-time tracking for your cab. Choose Sewak Travels for a relaxing airport transfer experience, letting you focus on your journey and what awaits you in Kozhikode. Whether you're visiting briefly or heading out, we make sure your trip to and from Kozhikode Airport is comfortable.\n",
  distanceHeading: "Kozhikode to Kozhikode Airport Cab Distance",
  distanceInfo:
    "Kozhikode Airport is about 28 km from the city center, depending on the route and stops. For a comfortable and easy trip, book your Kozhikode Airport taxi on Sewak Travels' simple online platform. We make your travel convenient and reliable, whether you're going to or coming from the airport.\n",
  bookingHeading: "Online Kozhikode Airport Cab Booking with Sewak Travels",
  bookingInfo:
    "Welcome to Sewak Travels! We provide reliable and comfortable online cab booking for Kozhikode Airport. Whether you are arriving at or leaving from Kozhikode, our taxi service guarantees a smooth journey from start to finish. With modern vehicles and professional drivers, we ensure top-quality airport cab services in Kozhikode.\n",
  img: "/assets/img/LocationDifferent/Kozhikode-airport.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book a Kozhikode Cab?",
  whyChooseSubHeading:
    "Book your Kozhikode Cabs effortlessly: When you choose Sewak for your cab service in Kozhikode, you get:",
  whyChooseInfo: [
    {
      title: "24/7 Booking & Travel Assistance:",
      detail: "Our Kozhikode Airport taxi service operates 24/7",
    },
    {
      title: "Outstation Cabs:",
      detail:
        "Need to travel outside Kozhikode? We offer outstation cabs from Kozhikode Airport",
    },
    {
      title: "Competitive Rates:",
      detail: "With Kozhikode Airport taxi per km rates",
    },
    {
      title: "Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi at Kozhikode Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "Professional Drivers:",
      detail:
        "Sewak's drivers are skilled professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title: "Diverse Kozhikode Taxi Options:",
      detail: "When selecting our Kozhikode cab service for your journey",
    },
  ],
  howToBookHeading: "How to Book a Kozhikode Airport Taxi?",
  howToBookInfo:
    "Sewak Travels solves the issue of booking a Kozhikode Cab by offering premium services at affordable Kozhikode taxi rates, complete with a pickup-drop facility and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit sewaktravels.com, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, and proceed to payment.\n",
  taxiServicesHeading:
    "Various Taxi Services in Kozhikode Airport by Sewak Travels",
  taxiServices: [
    {
      title: "Outstation Cabs from Kozhikode Airport:",
      description:
        "Sewak Travels offers reliable outstation cabs from Kozhikode Airport",
    },
    {
      title: "Local Taxis from Kozhikode Airport:",
      description:
        "Need a ride within Kozhikode? We make it easy to book local taxis from Kozhikode Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "Corporate Airport Transfers:",
      description:
        "We also cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "Taxi Service in Kozhikode:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: "Easy Kozhikode Airport Cab Booking for Every Need",
  easyBookingInfo:
    "Whether you are visiting Kozhikode for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Kozhikode Airport taxi contact number and online platforms make it easy for you to book a cab within minutes.\n",
  easyBookingPoints: [
    "Affordable Kozhikode Airport Taxi Fare",
    "Quick & Reliable Airport Taxi Service",
    "Car Rental Services for Kozhikode Airport",
  ],
  bookingCallToAction:
    "Book your Kozhikode Airport cab now with Sewak Travels and enjoy comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Kozhikode Airport taxi booking. Let Sewak Travels make your airport commute effortless!",
  thingsToKnowHeading: "Things to Know About Kozhikode",
  thingsToKnowInfo:
    "Kozhikode, on the Malabar Coast, is known for its rich culture, beautiful beaches, and lovely scenery. The city features colonial buildings and celebrates many lively festivals. It has old churches and forts, making it a cultural spot in India. Kozhikode is famous for its relaxing beach resorts and is a great place for tourists wanting a break from city life. The food here, especially seafood like fish curry and prawn fry, is well-loved. Kozhikode is easy to reach by road, rail, and air, making it a perfect place for travelers wanting to explore its vibrant culture and heritage.\n",
  faqHeading: "Frequently Asked Questions About Kozhikode Airport Cab Booking",
  faqs: [
    {
      question: "How can I book a Kozhikode Airport cab?",
      answer:
        "You can easily book a Kozhikode Airport taxi by visiting our website, sewaktravels.com. Just select the cab option, choose what you need, and complete your booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Kozhikode?",
      answer:
        "Sewak Travels offers cabs for trips within the city, allowing you to book rides to and from Kozhikode Airport.",
    },
    {
      question:
        "What types of vehicles do you offer for Kozhikode Airport cab services?",
      answer:
        "We have different options, including 4-seater sedans, 7-seater SUVs, and luxury cars, ensuring a comfortable ride for everyone.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we provide a prepaid taxi service at Kozhikode Airport, so you can book your ride in advance without any last-minute stress.",
    },
    {
      question: "What are the rates for Kozhikode Airport cabs?",
      answer:
        "Our taxi fares for Kozhikode Airport are affordable and based on a per km rate, ensuring no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Yes! All our drivers are professional and have the experience to make sure you are safe and comfortable during your trip.",
    },
    {
      question:
        "Can I book a cab for outstation travel from Kozhikode Airport?",
      answer:
        "Yes, we offer reliable outstation cabs from Kozhikode Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to help you with your Kozhikode cab bookings.",
    },
    {
      question: "Do you provide local sightseeing cabs in Kozhikode?",
      answer:
        "Yes, we offer local taxi services for sightseeing and exploring the beautiful attractions in and around Kozhikode.",
    },
    {
      question: "What safety measures are in place for your cab services?",
      answer:
        "We focus on passenger safety by following cleaning protocols, ensuring our vehicles are clean and safe for your travel.",
    },
  ],
};
export const kolkataTaxiData = {
  pageTitle: "Book Kolkata Airport Cabs",
  mainHeading: "Book Kolkata Airport Cabs on Sewak Travels",
  subHeading: "Enjoy smooth Airport Pickup & Drop Services in Kolkata.",
  airportInfo:
    "Kolkata Airport, also known as Netaji Subhas Chandra Bose International Airport, is a major hub for domestic and international travel in India. Established in 1924, this bustling airport serves as a gateway to the rich culture, history, and vibrant life of Kolkata. Located about 17 km from the city center, Kolkata Airport offers convenient access for tourists eager to explore the city. Our commitment to excellence ensures you receive prompt and reliable service from the moment you land. From assisting with your luggage to providing a smooth ride to your destination, our drivers are ready to make your journey comfortable. We understand that travel can be stressful, so we offer flexible booking options and real-time tracking for your cab.\n",
  serviceInfo:
    "At Sewak Travels, we make your travel experience smoother with our reliable Kolkata Airport cab service. Choose Sewak Travels for a seamless airport transfer experience, allowing you to focus on what truly matters—your journey and the rich experiences that await you in Kolkata. Whether you’re flying in for a quick visit or heading out, we make your commute to and from Kolkata Airport relaxing and stress-free.\n",
  distanceHeading: "Kolkata to Kolkata Airport Cab Distance",
  distanceInfo:
    "The distance from Kolkata Airport to the city center is approximately 17 km, depending on the route and any stops. For a smooth, comfortable journey, book a Kolkata Airport taxi through Sewak Travel's user-friendly platform. Whether heading to or from the airport, we ensure a pleasant experience, making your travel reliable and convenient.\n",
  bookingHeading: "Online Kolkata Airport Cab Booking with Sewak Travels",
  bookingInfo:
    "\nWelcome to Sewak Travels, where we provide reliable, comfortable, and timely Kolkata Airport cab booking tailored to your needs. Whether arriving or departing from Kolkata, our Kolkata Airport taxi service guarantees a hassle-free experience from start to finish. With a fleet of modern vehicles and professional drivers, we deliver the highest standard of airport cab services in Kolkata.\n",
  img: "/assets/img/LocationDifferent/Kolkata -airport.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book a Kolkata Cab?",
  whyChooseSubHeading:
    " Book your Kolkata cabs effortlessly: When you choose Sewak for your cab service in Kolkata, you get:",
  whyChooseInfo: [
    {
      title: "24/7 Booking & Travel Assistance:",
      detail: "Our Kolkata Airport taxi service operates 24/7",
    },
    {
      title: "Outstation Cabs:",
      detail:
        "Need to travel outside Kolkata? We offer outstation cabs from Kolkata Airport",
    },
    {
      title: "Competitive Rates:",
      detail: "With Kolkata Airport taxi per km rates",
    },
    {
      title: "Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi at Kolkata Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "Professional Drivers:",
      detail:
        "Sewak’s drivers are skilled professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title: "Diverse Kolkata Taxi Options:",
      detail: "When selecting our Kolkata cab service for your journey",
    },
  ],
  howToBookHeading: "How to Book a Kolkata Airport Taxi?",
  howToBookInfo:
    "\nSewak Travels solves the issue of booking a Kolkata cab by offering premium services at affordable Kolkata taxi rates, complete with a pickup-drop facility and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit https://sewaktravels.com, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, and proceed to payment.\n",
  taxiServicesHeading:
    "Various Taxi Services at Kolkata Airport by Sewak Travels",
  taxiServices: [
    {
      title: "Outstation Cabs from Kolkata Airport:",
      description:
        "Sewak Travels offers reliable outstation cabs from Kolkata Airport",
    },
    {
      title: "Local Taxis from Kolkata Airport:",
      description:
        "Need a ride within Kolkata? We make it easy to book local taxis from Kolkata Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "Corporate Airport Transfers:",
      description:
        "We also cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "Taxi Service in Kolkata:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: "Easy Kolkata Airport Cab Booking for Every Need",
  easyBookingInfo:
    "\nWhether you are visiting Kolkata for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Kolkata Airport taxi contact number and online platforms make it easy for you to book a cab within minutes.\n",
  easyBookingPoints: [
    "Affordable Kolkata Airport Taxi Fare",
    "Quick & Reliable Airport Taxi Service",
    "Car Rental Services for Kolkata Airport",
  ],
  bookingCallToAction:
    "Book your Kolkata Airport cab now with Sewak Travels and enjoy comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Kolkata Airport taxi booking. Let Sewak Travels make your airport commute effortless!",
  thingsToKnowHeading: "Things To Know About Kolkata",
  thingsToKnowInfo:
    "\nKolkata, the capital of West Bengal, is known for its rich culture, historic sites, and lively festivals. The city is famous for its old colonial buildings, the Howrah Bridge, and busy street markets. Kolkata also has a variety of delicious food, including fish curry, biryani, and sweets like rasgulla and sandesh. The city combines both old traditions and modern life, making it an interesting place to visit. With good connections by road, train, and air, Kolkata is an important center for culture and business in eastern India.\n",
  faqHeading: "Frequently Asked Questions About Kolkata Airport Cab Booking",
  faqs: [
    {
      question: "How can I book a Kolkata Airport cab?",
      answer:
        "You can book a Kolkata Airport taxi easily by visiting our website sewaktravels.com, selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Kolkata?",
      answer:
        "Sewak Travels provides intra-city cab transfers that allow users to book cabs to and from Kolkata Airport.",
    },
    {
      question:
        "What types of vehicles do you offer for Kolkata Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Kolkata Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Kolkata Airport cabs?",
      answer:
        "Our Kolkata Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Absolutely! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question: "Can I book a cab for outstation travel from Kolkata Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Kolkata Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with your Kolkata cab bookings.",
    },
    {
      question: "Do you provide local sightseeing cabs in Kolkata?",
      answer:
        "Yes, we offer local taxi services for sightseeing and exploring the beautiful attractions in and around Kolkata.",
    },
    {
      question: "What safety measures are in place for your cab services?",
      answer:
        "We prioritize passenger safety by following standard sanitization protocols, ensuring our vehicles are clean and safe for travel.",
    },
  ],
};
export const cochinTaxiData = {
  pageTitle: "Book Cochin Airport Cabs",
  mainHeading: "Book Cochin Airport Cabs on Sewak Travels",
  subHeading: "Enjoy smooth Airport Pickup & Drop Services in Cochin.",
  airportInfo:
    "Cochin Airport, also known as Cochin International Airport, is a key hub for both domestic and international travel in India. Established in 1999, this modern airport serves as a gateway to the vibrant culture, backwaters, and historical sites of Cochin. Located just 30 km from the city center, Cochin Airport provides easy access for tourists looking to explore the region. Our commitment to excellence ensures that you receive prompt and reliable service at every step. From the moment you arrive at Cochin Airport, our drivers will be ready to assist you with your luggage and ensure a smooth ride to your destination. We understand that travel can be stressful, which is why we offer flexible booking options and real-time tracking for your cab.\n",
  serviceInfo:
    "At Sewak Travels, we make your travel experience smoother with our reliable Cochin Airport cab service. Choose Sewak Travels for a smooth airport transfer experience that allows you to focus on what truly matters—your journey and the experiences that await you in Cochin. Whether you are flying in for a quick visit or heading out, we ensure your journey to and from Cochin Airport is relaxed.",
  distanceHeading: "Cochin to Cochin Airport Cab Distance",
  distanceInfo:
    "The distance from Cochin Airport to the city center is approximately 30 km, depending on the route and any stops made. For a comfortable and stress-free journey, simply book a Cochin Airport taxi through Sewak Travels' user-friendly online platform. Whether you are heading to or from the airport, we ensure an amazing experience, making your travel convenient and reliable.\n",
  bookingHeading: "Online Cochin Airport Cab Booking with Sewak Travels",
  bookingInfo:
    "Welcome to Sewak Travels, where we offer reliable, comfortable, and on-time online Cochin Airport cab booking tailored to your needs. Whether you are arriving in or departing from Cochin, our Cochin Airport taxi service ensures a smooth travel experience from start to finish. With a fleet of modern vehicles and professional drivers, we provide the highest standard of airport cab services in Cochin.\n",
  img: "/assets/img/LocationDifferent/Cochin-airpor.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book a Cochin Cab?",
  whyChooseSubHeading:
    "Book your Cochin Cabs effortlessly: When you choose Sewak for your cab service in Cochin, you get:",
  whyChooseInfo: [
    {
      title: "24/7 Booking & Travel Assistance:",
      detail: "Our Cochin Airport taxi service operates 24/7",
    },
    {
      title: "Outstation Cabs:",
      detail:
        "Need to travel outside Cochin? We offer outstation cabs from Cochin Airport",
    },
    {
      title: "Competitive Rates:",
      detail: "With Cochin Airport taxi per km rates",
    },
    {
      title: "Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi at Cochin Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "Professional Drivers:",
      detail:
        "Sewak's drivers are skilled professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title: "Diverse Cochin Taxi Options:",
      detail: "When selecting our Cochin cab service for your journey",
    },
  ],
  howToBookHeading: "How to Book a Cochin Airport Taxi?",
  howToBookInfo:
    "Sewak Travels solves the issue of booking a Cochin Cab by offering premium services at affordable Cochin taxi rates, complete with a pickup-drop facility and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit https://sewaktravels.com/, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, and proceed to payment.\n",
  taxiServicesHeading:
    "Various Taxi Services in Cochin Airport by Sewak Travels",
  taxiServices: [
    {
      title: "Outstation Cabs from Cochin Airport:",
      description:
        "Sewak Travels offers reliable outstation cabs from Cochin Airport",
    },
    {
      title: "Local Taxis from Cochin Airport:",
      description:
        "Need a ride within Cochin? We make it easy to book local taxis from Cochin Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "Corporate Airport Transfers:",
      description:
        "We also cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "Taxi Service in Cochin:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: "Easy Cochin Airport Cab Booking for Every Need",
  easyBookingInfo:
    "Whether you are visiting Cochin for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Cochin Airport taxi contact number and online platforms make it easy for you to book a cab within minutes.\n",
  easyBookingPoints: [
    "Affordable Cochin Airport Taxi Fare",
    "Quick & Reliable Airport Taxi Service",
    "Car Rental Services for Cochin Airport",
  ],
  bookingCallToAction:
    "Book your Cochin Airport cab now with Sewak Travels and enjoy comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Cochin Airport taxi booking. Let Sewak Travels make your airport commute effortless!",
  thingsToKnowHeading: "Things to Know About Cochin",
  thingsToKnowInfo:
    "Cochin, in Kerala, is famous for its rich culture, beautiful backwaters, and lovely scenery. It has colonial buildings and vibrant festivals, with many old churches and forts that make it a cultural center in India. Cochin is known for its relaxing backwater resorts, attracting tourists looking to escape the city. The local food, especially seafood like fish curry and prawn fry, is very popular. Cochin is also easy to reach by road, rail, and air, making it a great place for travelers to explore the rich culture and heritage of this coastal paradise.\n",
  faqHeading: "Frequently Asked Questions About Cochin Airport Cab Booking",
  faqs: [
    {
      question: "How can I book a Cochin Airport cab?",
      answer:
        "You can book a Cochin Airport taxi easily by visiting our website sewaktravels.com and selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Cochin?",
      answer:
        "Sewak Travels provides intra-city cab transfers which allow users to book cabs to and from the Cochin Airport in Cochin.",
    },
    {
      question:
        "What types of vehicles do you offer for Cochin Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Cochin Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Cochin Airport cabs?",
      answer:
        "Our Cochin Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Absolutely! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question: "Can I book a cab for outstation travel from Cochin Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Cochin Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with your booking or any inquiries you may have.",
    },
    {
      question: "Do you offer local taxi services in Cochin?",
      answer:
        "Yes, we provide local taxi services in Agra, perfect for sightseeing and city tours.",
    },
    {
      question: "How do I contact you for more information?",
      answer:
        "You can reach us at +91-837-782-8828 for any inquiries regarding our Cochin Airport taxi service or bookings.",
    },
  ],
};
export const lucknowTaxiData = {
  pageTitle: "Book Lucknow Airport Cabs",
  mainHeading: "Book Lucknow Airport Cabs on Sewak Travels",
  subHeading: "Enjoy smooth Airport Pickup & Drop Services in Lucknow. ",
  airportInfo:
    "Lucknow Airport, also known as Chaudhary Charan Singh International Airport, is a key hub for both domestic and international travel in India. Established in 1986, this modern airport serves as a gateway to the rich heritage, vibrant culture, and culinary delights of Lucknow. Located just 18 km from the city center, Lucknow Airport provides easy access for tourists looking to explore the region. Our commitment to excellence ensures that you receive prompt and reliable service at every step. From the moment you arrive at Lucknow Airport, our drivers will be ready to assist you with your luggage and ensure a smooth ride to your destination. We understand that travel can be stressful, which is why we offer flexible booking options and real-time tracking for your cab.\n",
  serviceInfo:
    "At Sewak Travels, we make your travel experience smoother with our reliable Lucknow Airport cab service.Choose Sewak Travels for a smooth airport transfer experience that allows you to focus on what truly matters—your journey and the experiences that await you in Lucknow. Whether you are flying in for a quick visit or heading out, we ensure your journey to and from Lucknow Airport is relaxed.\n",
  distanceHeading: "Lucknow to Lucknow Airport Cab Distance",
  distanceInfo:
    "\nThe distance from Lucknow Airport to the city center is approximately 18 km, depending on the route and any stops made. For a comfortable and stress-free journey, simply book a Lucknow Airport taxi through Sewak Travel's user-friendly online platform. Whether you are heading to or from the airport, we ensure an amazing experience, making your travel convenient and reliable.\n",
  bookingHeading: "Online Lucknow Airport Cab Booking with Sewak Travels",
  bookingInfo:
    "\nWelcome to Sewak Travels, where we offer reliable, comfortable, and on-time online Lucknow Airport cab booking tailored to your needs. Whether you are arriving in or departing from Lucknow, our Lucknow Airport taxi service ensures a smooth travel experience from start to finish. With a fleet of modern vehicles and professional drivers, we provide the highest standard of airport cab services in Lucknow.\n",
  img: "/assets/img/LocationDifferent/Lucknow-airport.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book a Lucknow Cab?",
  whyChooseSubHeading:
    " Book your Lucknow Cabs effortlessly: When you choose Sewak for your cab service in Lucknow, you get:",
  whyChooseInfo: [
    {
      title: "24/7 Booking & Travel Assistance:",
      detail: "Our Lucknow Airport taxi service operates 24/7",
    },
    {
      title: "Outstation Cabs:",
      detail:
        "Need to travel outside Lucknow? We offer outstation cabs from Lucknow Airport",
    },
    {
      title: "Competitive Rates:",
      detail: "With Lucknow Airport taxi per km rates",
    },
    {
      title: "Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi at Lucknow Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "Professional Drivers:",
      detail:
        "Sewak's drivers are skilled professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title: "Diverse Lucknow Taxi Options:",
      detail: "When selecting our Lucknow cab service for your journey",
    },
  ],
  howToBookHeading: "How to Book a Lucknow Airport Taxi?",
  howToBookInfo:
    "\nSewak Travels solves the issue of booking a Lucknow Cab by offering premium services at affordable Lucknow taxi rates, complete with a pickup-drop facility and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit https://sewaktravels.com/, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, and proceed to payment.\n",
  taxiServicesHeading:
    "Various Taxi Services in Lucknow Airport by Sewak Travels",
  taxiServices: [
    {
      title: "Outstation Cabs from Lucknow Airport:",
      description:
        "Sewak Travels offers reliable outstation cabs from Lucknow Airport",
    },
    {
      title: "Local Taxis from Lucknow Airport:",
      description:
        "Need a ride within Lucknow? We make it easy to book local taxis from Lucknow Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "Corporate Airport Transfers:",
      description:
        "We also cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "Taxi Service in Lucknow:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: "Easy Lucknow Airport Cab Booking for Every Need",
  easyBookingInfo:
    "\nWhether you are visiting Lucknow for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Lucknow Airport taxi contact number and online platforms make it easy for you to book a cab within minutes.\n",
  easyBookingPoints: [
    "Affordable Lucknow Airport Taxi Fare",
    "Quick & Reliable Airport Taxi Service",
    "Car Rental Services for Lucknow Airport",
  ],
  bookingCallToAction:
    " Book your Lucknow Airport cab now with Sewak Travels and enjoy comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Lucknow Airport taxi booking. Let Sewak Travels make your airport commute effortless!",
  thingsToKnowHeading: "Things to Know About Lucknow",
  thingsToKnowInfo:
    'Lucknow, the capital of Uttar Pradesh, India, is a city steeped in history and cultural richness, known for its unique blend of Mughal and Awadhi heritage. Often referred to as the "City of Nawabs," Lucknow flourished during the 18th and 19th centuries under the rule of the Nawabs of Awadh, who were renowned for their lavish lifestyle, exquisite architecture, and culinary traditions.The city is famous for its stunning monuments, including the Bara Imambara, an architectural marvel built by Nawab Asaf-ud-Daula, featuring a grand hall that is one of the largest in India. Adjacent to it is the impressive Bhool Bhulaiya, a maze-like structure that adds to the allure of the site. Another iconic landmark is the Rumi Darwaza, a majestic gateway that exemplifies Indo-Islamic architecture and serves as a symbol of the city\'s rich heritage.Lucknow is also known for its vibrant culture, marked by traditional dance, music, and art forms. The local cuisine, especially its mouth-watering kebabs and biryanis, reflects the Nawabi influence and is a culinary delight for food enthusiasts. The city’s bustling markets, such as Hazratganj, offer a glimpse into its thriving craftsmanship, particularly in chikankari (embroidery) and intricate jewelry.Whether you are a history buff, a food lover, or an architecture enthusiast, Lucknow promises a memorable experience rich in heritage and charm.\n',
  faqHeading: "Frequently Asked Questions About Lucknow Airport Cab Booking",
  faqs: [
    {
      question: "How can I book a Lucknow Airport cab?",
      answer:
        "You can book a Lucknow Airport taxi easily by visiting our website sewaktravels.com, selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Lucknow?",
      answer:
        "Sewak Travels provides intra-city cab transfers that allow users to book cabs to and from Lucknow Airport in Lucknow.",
    },
    {
      question:
        "What types of vehicles do you offer for Lucknow Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Lucknow Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Lucknow Airport cabs?",
      answer:
        "Our Lucknow Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Absolutely! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question: "Can I book a cab for outstation travel from Lucknow Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Lucknow Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with your Lucknow cab bookings.",
    },
    {
      question: "Do you provide local sightseeing cabs in Lucknow?",
      answer:
        "Yes, we offer local taxi services for sightseeing and exploring the beautiful attractions in and around Lucknow.",
    },
    {
      question: "What safety measures are in place for your cab services?",
      answer:
        "We prioritize passenger safety by following standard sanitization protocols, ensuring our vehicles are clean and safe for travel.",
    },
  ],
};
export const ludhianaTaxiData1 = {
  pageTitle: "Book Ludhiana Airport Cabs",
  mainHeading: "Book Ludhiana Airport Cabs on Sewak Travels",
  subHeading: "Enjoy easy airport pickup and drop services in Ludhiana. ",
  airportInfo:
    "Ludhiana Airport, also known as Sahnewal Airport, is an important hub for domestic and international flights. Opened in 1991, it connects you to the culture, textile industry, and historical sites of Ludhiana. Located just 12 km from the city center, it’s convenient for tourists.\n",
  serviceInfo:
    "At Sewak Travels, we make your travel experience smoother with our reliable Agra Airport cab service. Choose Sewak Travels for a comfortable airport transfer, letting you focus on your journey and the experiences waiting for you in Ludhiana. Whether you're visiting for a short time or heading out, we make your trip to and from Ludhiana Airport easy.\n",
  distanceHeading: "Ludhiana to Ludhiana Airport Cab Distance",
  distanceInfo:
    "Ludhiana Airport is about 12 km from the city center, depending on the route and any stops. To enjoy a comfortable and easy journey, book your Ludhiana Airport taxi through Sewak Travels' simple online platform. Whether you’re going to or coming from the airport, we make your travel convenient and reliable.\n",
  bookingHeading: "Online Ludhiana Airport Cab Booking with Sewak Travels",
  bookingInfo:
    "Welcome to Sewak Travels, where we offer reliable, comfortable, and on-time online Ludhiana Airport cab booking tailored to your needs. Whether you are arriving in or departing from Ludhiana, our Ludhiana Airport taxi service ensures a smooth travel experience from start to finish. With a fleet of modern vehicles and professional drivers, we provide the highest standard of airport cab services in Ludhiana.\n",
  img: "/assets/img/LocationDifferent/Ludhiana-airport.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book a Ludhiana Cab?",
  whyChooseSubHeading:
    "Book your Ludhiana Cabs effortlessly: When you choose Sewak for your cab service in Ludhiana, you get:",
  whyChooseInfo: [
    {
      title: "24/7 Booking & Travel Assistance:",
      detail: "Our Ludhiana Airport taxi service operates 24/7",
    },
    {
      title: "Outstation Cabs:",
      detail:
        "Need to travel outside Ludhiana? We offer outstation cabs from Ludhiana Airport",
    },
    {
      title: "Competitive Rates:",
      detail: "With Ludhiana Airport taxi per km rates",
    },
    {
      title: "Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi Ludhiana Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "Professional Drivers:",
      detail:
        "Sewak's drivers are skilled professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title: "Diverse Ludhiana Taxi Options:",
      detail: "When selecting our Ludhiana cab service for your journey",
    },
  ],
  howToBookHeading: "How to Book a Ludhiana Airport Taxi?",
  howToBookInfo:
    "Sewak Travels solves the issue of booking a Ludhiana Cab by offering premium services at affordable Ludhiana taxi rates, complete with a pickup-drop facility and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit https://sewaktravels.com/, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, and proceed to payment.\n",
  taxiServicesHeading:
    "Various Taxi Services in Ludhiana Airport by Sewak Travels",
  taxiServices: [
    {
      title: "Outstation Cabs from Ludhiana Airport:",
      description:
        "Sewak Travels provides dependable outstation cabs from Ludhiana Airport for both tourists and locals. Our clean and well-maintained vehicles",
    },
    {
      title: "Local Taxis from Ludhiana Airport:",
      description:
        "Need a ride within Ludhiana? We make it easy to book local taxis from Ludhiana Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "Corporate Airport Transfers:",
      description:
        "We also cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "Taxi Service in Ludhiana:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: "Easy Ludhiana Airport Cab Booking for Every Need",
  easyBookingInfo:
    "Whether you are visiting Ludhiana for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Ludhiana Airport taxi contact number and online platforms make it easy for you to book a cab within minutes.\n",
  easyBookingPoints: [
    "Affordable Ludhiana Airport Taxi Fare",
    "Quick & Reliable Airport Taxi Service",
    "Car Rental Services for Ludhiana Airport",
  ],
  bookingCallToAction:
    " Book your Ludhiana Airport cab now with Sewak Travels and enjoy comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Ludhiana Airport taxi booking. Let Sewak Travels make your airport commute effortless!",
  thingsToKnowHeading: "Things To Know About Ludhiana",
  thingsToKnowInfo:
    'Ludhiana, called the "Manchester of India," is famous for its strong industrial base and lively culture. Known for its textile industry and busy markets, the city has both modern and traditional attractions. Key places to visit include the historic Lodhi Fort, the beautiful Rose Garden, and many Gurudwaras. Ludhiana\'s food scene is rich, with local dishes like butter chicken and parathas. The city is easy to reach by road and rail, making it a great spot for travelers wanting to experience its unique charm.\n',
  faqHeading: "Frequently Asked Questions About Ludhiana Airport Cab Booking",
  faqs: [
    {
      question: "How can I book a Ludhiana Airport cab?",
      answer:
        "You can book a Ludhiana Airport taxi easily by visiting our website sewaktravels.com, selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Ludhiana?",
      answer:
        "Sewak Travels provides intra-city cab transfers that allow users to book cabs to and from Ludhiana Airport in Ludhiana.",
    },
    {
      question:
        "What types of vehicles do you offer for Ludhiana Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Ludhiana Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Ludhiana Airport cabs?",
      answer:
        "Our Ludhiana Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Absolutely! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question: "Can I book a cab for outstation travel from Ludhiana Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Ludhiana Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with your Ludhiana cab bookings.",
    },
    {
      question: "Do you provide local sightseeing cabs in Ludhiana?",
      answer:
        "Yes, we offer local taxi services for sightseeing and exploring the beautiful attractions in and around Ludhiana.",
    },
    {
      question: "What safety measures are in place for your cab services?",
      answer:
        "We prioritize passenger safety by following standard sanitization protocols, ensuring our vehicles are clean and safe for travel.",
    },
  ],
};
export const hyderabadTaxiData = {
  pageTitle: "Book Hyderabad Airport Cabs",
  mainHeading: "Book Hyderabad Airport Cabs on Sewak Travels",
  subHeading: "Enjoy Seamless Airport Pickup & Drop Services in Hyderabad.",
  airportInfo:
    "Rajiv Gandhi International Airport, also known as Hyderabad Airport, is one of India's busiest and most well-equipped airports. Located around 24 km from the city center, Hyderabad Airport serves as a vital gateway for both domestic and international travelers visiting the vibrant city known for its rich history, technology hub, and famous landmarks such as the Charminar and Golconda Fort.\n",
  serviceInfo:
    "At Sewak Travels, we make your travel experience smooth and reliable with our Hyderabad Airport cab service. Whether you're flying into the city for business or heading out for leisure, we ensure a stress-free and timely journey to and from Hyderabad Airport.\n",
  distanceHeading: "Hyderabad to Hyderabad Airport Cab Distance",
  distanceInfo:
    "The distance from Hyderabad city center to the airport is approximately 24 km, depending on the route and traffic conditions. With Sewak Travel's user-friendly online platform, booking a Hyderabad Airport taxi is quick and easy. Whether you're arriving or departing, our service guarantees a hassle-free experience, ensuring your travel is convenient and reliable. Skip the wait and book your airport taxi in Hyderabad directly with Sewak Travels for a seamless ride!\n",
  bookingHeading: "Online Hyderabad Airport Cab Booking with Sewak Travels",
  bookingInfo:
    "Welcome to Sewak Travels, where we offer reliable, comfortable, and on-time Online Hyderabad Airport cab booking tailored to your needs. Whether you’re arriving in or departing from Hyderabad, our Hyderabad Airport taxi service ensures a seamless travel experience from start to finish. With a fleet of modern vehicles and professional drivers, we provide the highest standard of airport cab services in Hyderabad.\n",
  img: "/assets/img/LocationDifferent/Hyderabad-airport.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book a Hyderabad Cab?",
  whyChooseSubHeading:
    "Book your Hyderabad Cabs effortlessly: When you choose Sewak for your cab service in Hyderabad, you get:",
  whyChooseInfo: [
    {
      title: "24/7 Booking & Travel Assistance:",
      detail: "Our Hyderabad Airport taxi service operates 24/7",
    },
    {
      title: "Outstation Cabs:",
      detail:
        "Need to travel outside Hyderabad? We offer outstation cabs from Hyderabad Airport",
    },
    {
      title: "Competitive Rates:",
      detail: "With Hyderabad Airport taxi per km rates",
    },
    {
      title: "Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi at Hyderabad Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "Professional Drivers:",
      detail:
        "Sewak's drivers are skilled professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title: "Diverse Hyderabad Taxi Options:",
      detail: "When selecting our Hyderabad cab service for your journey",
    },
  ],
  howToBookHeading: "How to Book a Hyderabad Airport Taxi?",
  howToBookInfo:
    "Sewak Travels takes the hassle out of booking a Hyderabad Cab by offering premium services at affordable Hyderabad taxi rates, complete with a pickup-drop facility and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit https://sewaktravels.com, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, proceed to payment, and you're all set! Now that you're acquainted with our Hyderabad taxi service, why wait any longer?\n",
  taxiServicesHeading:
    "Various Taxi Services at Hyderabad Airport by Sewak Travels",
  taxiServices: [
    {
      title: "Outstation Cabs from Hyderabad Airport:",
      description:
        "Sewak Travels offers reliable outstation cabs from Hyderabad Airport",
    },
    {
      title: "Local Taxis from Hyderabad Airport:",
      description:
        "Need a ride within Hyderabad? We make it easy to book local taxis from Hyderabad Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "Corporate Airport Transfers:",
      description:
        "We also cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "Taxi Service in Hyderabad:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: "Easy Hyderabad Airport Cab Booking for Every Need",
  easyBookingInfo:
    "Whether you’re visiting Hyderabad for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Hyderabad Airport taxi contact number and online platforms make it easy for you to book a cab within minutes.\n",
  easyBookingPoints: [
    "Affordable Hyderabad Airport Taxi Fare",
    "Quick & Reliable Airport Taxi Service",
    "Car Rental Services for Hyderabad Airport",
  ],
  bookingCallToAction:
    "Book your Hyderabad Airport cab now with Sewak Travels and enjoy hassle-free, comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Hyderabad Airport taxi booking. Let Sewak Travels make your airport commute effortless!",
  thingsToKnowHeading: "Things To Know About Hyderabad",
  thingsToKnowInfo:
    'Hyderabad, the capital of Telangana, India, is celebrated for its unique blend of rich history and modernity. Known as the "City of Pearls," Hyderabad was once the seat of the powerful Qutb Shahi and Asaf Jahi dynasties, which have left a lasting legacy in the city’s architecture and culture. Nestled along the banks of the Musi River, the city boasts iconic landmarks such as the Charminar, a grand monument and mosque built in 1591 by Sultan Muhammad Quli Qutb Shah, which remains a symbol of Hyderabad’s heritage.\n\nIn addition to its historical sites, Hyderabad is a vibrant technology hub, often called "Cyberabad" due to its thriving IT sector. The city\'s rich cultural tapestry is also evident in its cuisine, with the world-famous Hyderabadi Biryani, a flavorful rice dish, as well as local delicacies like haleem and kebabs. Hyderabad’s markets, especially Laad Bazaar, are known for traditional bangles, pearls, and intricate jewelry.\n\nHyderabad is well-connected by road, rail, and the Rajiv Gandhi International Airport, making it an accessible destination for travelers. With its mix of heritage and innovation, Hyderabad offers a captivating experience for history enthusiasts, tech professionals, and food lovers alike.\n\nHyderabad is also home to the historic Golconda Fort, a majestic fortress famous for its acoustic design, and the opulent Chowmahalla Palace, once the residence of the Nizams, the city’s former rulers. The Salar Jung Museum, one of India’s largest museums, houses an impressive collection of art, artifacts, and antiques from around the world.\n',
  faqHeading: "Frequently Asked Questions About Hyderabad Airport Cab Booking",
  faqs: [
    {
      question: "How can I book a Hyderabad Airport cab?",
      answer:
        "You can book a Hyderabad Airport taxi easily by visiting our website sewaktravels.com, selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Hyderabad?",
      answer:
        "Sewak Travels provides intra-city cab transfers, allowing users to book cabs to and from Hyderabad Airport in a convenient and reliable manner.",
    },
    {
      question:
        "What types of vehicles do you offer for Hyderabad Airport cab services?",
      answer:
        "We offer a range of options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Hyderabad Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Hyderabad Airport cabs?",
      answer:
        "Our Hyderabad Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Absolutely! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question:
        "Can I book a cab for outstation travel from Hyderabad Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Hyderabad Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with your booking or any inquiries you may have.",
    },
    {
      question: "Do you offer local taxi services in Hyderabad?",
      answer:
        "Yes, we provide local taxi services in Hyderabad, perfect for sightseeing and city tours.",
    },
    {
      question: "How do I contact you for more information?",
      answer:
        "You can reach us at +91-837-782-8828 for any inquiries regarding our Hyderabad Airport taxi service or bookings.",
    },
  ],
};
export const pakyongTaxiData = {
  pageTitle: "Book Pakyong Airport Cabs",
  mainHeading: "Book Pakyong Airport Cabs on Sewak Travels  ",
  subHeading: "Enjoy smooth Airport Pickup & Drop Services in Pakyong.  ",
  airportInfo:
    "Pakyong Airport, also known as Pakyong International Airport, is a key hub for both domestic and international travel in India. Established in 2018, this modern airport serves as a gateway to the serene landscapes, monasteries, and cultural treasures of Sikkim. Located just 35 km from Gangtok, Pakyong Airport provides easy access for tourists looking to explore the region. Our commitment to excellence ensures that you receive prompt and reliable service at every step. From the moment you arrive at Pakyong Airport, our drivers will be ready to assist you with your luggage and ensure a smooth ride to your destination. We understand that travel can be stressful, which is why we offer flexible booking options and real-time tracking for your cab.  \n",
  serviceInfo:
    "Choose Sewak Travels for a smooth airport transfer experience that allows you to focus on what truly matters—your journey and the experiences that await you in Pakyong. Whether you are flying in for a quick visit or heading out, we ensure your journey to and from Pakyong Airport is relaxed.  \n",
  distanceHeading: "Pakyong to Pakyong Airport Cab Distance  ",
  distanceInfo:
    "The distance from Pakyong Airport to Gangtok city center is approximately 35 km, depending on the route and any stops made. For a comfortable and stress-free journey, simply book a Pakyong Airport taxi through Sewak Travel's user-friendly online platform. Whether you are heading to or from the airport, we ensure an amazing experience, making your travel convenient and reliable.  \n",
  bookingHeading: "Online Pakyong Airport Cab Booking with Sewak Travels  ",
  bookingInfo:
    "Welcome to Sewak Travels, where we offer reliable, comfortable, and on-time online Pakyong Airport cab booking tailored to your needs. Whether you are arriving in or departing from Pakyong, our Pakyong Airport taxi service ensures a smooth travel experience from start to finish. With a fleet of modern vehicles and professional drivers, we provide the highest standard of airport cab services in Pakyong.  \n",
  img: "/assets/img/LocationDifferent/Pakyong.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book a Pakyong Cab?  ",
  whyChooseSubHeading:
    "Book your Pakyong Cabs effortlessly: When you choose Sewak for your cab service in Pakyong, you get: ",
  whyChooseInfo: [
    {
      title: "- 24/7 Booking & Travel Assistance:",
      detail: "Our Pakyong Airport taxi service operates 24/7",
    },
    {
      title: "- Outstation Cabs:",
      detail:
        "Need to travel outside Pakyong? We offer outstation cabs from Pakyong Airport",
    },
    {
      title: "- Competitive Rates:",
      detail: "With Pakyong Airport taxi per km rates",
    },
    {
      title: "- Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi Pakyong Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "- Professional Drivers:",
      detail:
        "Sewak's drivers are skilled professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "- Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
  ],
  howToBookHeading: "How to Book a Pakyong Airport Taxi?  ",
  howToBookInfo:
    "Sewak Travels solves the issue of booking a Pakyong Cab by offering premium services at affordable Pakyong taxi rates, complete with a pickup-drop facility and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit https://sewaktravels.com, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, and proceed to payment.  \n",
  taxiServicesHeading:
    "Various Taxi Services in Pakyong Airport by Sewak Travels  ",
  taxiServices: [
    {
      title: "- Outstation Cabs from Pakyong Airport:",
      description:
        "Sewak Travels offers reliable outstation cabs from Pakyong Airport",
    },
    {
      title: "-Local Taxis from Pakyong Airport:",
      description:
        "Need a ride within Pakyong? We make it easy to book local taxis from Pakyong Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "- Corporate Airport Transfers:",
      description:
        "We also cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "- Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "-Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "- Taxi Service in Pakyong:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: "Easy Pakyong Airport Cab Booking for Every Need  ",
  easyBookingInfo:
    "Whether you are visiting Pakyong for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Pakyong Airport taxi contact number and online platforms make it easy for you to book a cab within minutes.  \n",
  easyBookingPoints: [
    "- Affordable Pakyong Airport Taxi Fare",
    "- Quick & Reliable Airport Taxi Service",
    "- Car Rental Services for Pakyong Airport",
  ],
  bookingCallToAction:
    "Book your Pakyong Airport cab now with Sewak Travels and enjoy comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Pakyong Airport taxi booking. Let Sewak Travels make your airport commute effortless!  ",
  thingsToKnowHeading: "Things to Know About Pakyong  ",
  thingsToKnowInfo:
    "Pakyong, located in Sikkim, is surrounded by lush greenery, scenic mountains, and a serene environment. Famous for its picturesque landscapes and Buddhist monasteries, Pakyong offers a tranquil escape from city life. The nearby attractions include the Rumtek Monastery, Nathula Pass, and the vibrant MG Marg in Gangtok. Known for its local delicacies like momo and thukpa, Pakyong is a paradise for food lovers. With excellent road connectivity, the region is perfect for exploring Sikkim's beauty while enjoying a peaceful getaway.  \n",
  faqHeading: " Frequently Asked Questions About Pakyong Airport Cab Booking  ",
  faqs: [
    {
      question: "How can I book a Pakyong Airport cab?",
      answer:
        "You can book a Pakyong Airport taxi easily by visiting our website sewaktravels.com, selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Pakyong?",
      answer:
        "Sewak Travels provides intra-city cab transfers that allow users to book cabs to and from Pakyong Airport in Pakyong.",
    },
    {
      question:
        "What types of vehicles do you offer for Pakyong Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Pakyong Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Pakyong Airport cabs?",
      answer:
        "Our Pakyong Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Absolutely! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question: "Can I book a cab for outstation travel from Pakyong Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Pakyong Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with your Pakyong cab bookings.",
    },
    {
      question: "Do you provide local sightseeing cabs in Pakyong?",
      answer:
        "Yes, we offer local taxi services for sightseeing and exploring the beautiful attractions in and around Pakyong.",
    },
    {
      question: "What safety measures are in place for your cab services?",
      answer:
        "We prioritize passenger safety by following standard sanitization protocols, ensuring our vehicles are clean and safe for travel.",
    },
  ],
};
export const pathankotTaxiData = {
  pageTitle: "Book Pathankot Airport Cabs",
  mainHeading: "Book Pathankot Airport Cabs on Sewak Travels  ",
  subHeading: "Enjoy smooth Airport Pickup & Drop Services in Pathankot.  ",
  airportInfo:
    "Pathankot Airport, also known as Pathankot International Airport, is a key hub for both domestic and international travel in India. Established in 1932, this historic airport serves as a gateway to the scenic beauty, cultural heritage, and serene landscapes of Pathankot. Located just 15 km from the city center, Pathankot Airport provides easy access for tourists eager to explore the region. Our commitment to excellence ensures that you receive prompt and reliable service at every step. From the moment you arrive at Pathankot Airport, our drivers will assist you with your luggage and ensure a smooth ride to your destination. We understand that travel can be stressful, which is why we offer flexible booking options and real-time tracking for your cab.  \n",
  serviceInfo:
    "Choose Sewak Travels for a smooth airport transfer experience that allows you to focus on what truly matters—your journey and the experiences that await you in Pathankot. Whether you are flying in for a quick visit or heading out, we ensure your journey to and from Pathankot Airport is relaxed.  \n",
  distanceHeading: "Pathankot to Pathankot Airport Cab Distance  ",
  distanceInfo:
    "The distance from Pathankot Airport to the city center is approximately 15 km, depending on the route and any stops made. For a comfortable and stress-free journey, simply book a Pathankot Airport taxi through Sewak Travels' user-friendly online platform. Whether you are heading to or from the airport, we ensure an amazing experience, making your travel convenient and reliable.  \n",
  bookingHeading: "Online Pathankot Airport Cab Booking with Sewak Travels",
  bookingInfo:
    "Welcome to Sewak Travels, where we offer reliable, comfortable, and on-time online Pathankot Airport cab booking tailored to your needs. Whether you are arriving in or departing from Pathankot, our Pathankot Airport taxi service ensures a smooth travel experience from start to finish. With a fleet of modern vehicles and professional drivers, we provide the highest standard of airport cab services in Pathankot.  \n",
  img: "/assets/img/LocationDifferent/Pathankot.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book a Pathankot Cab?  ",
  whyChooseSubHeading:
    "Book your Pathankot Cabs effortlessly: When you choose Sewak for your cab service in Pathankot, you get:  ",
  whyChooseInfo: [
    {
      title: "- 24/7 Booking & Travel Assistance:",
      detail: "Our Pathankot Airport taxi service operates 24/7",
    },
    {
      title: "- Outstation Cabs:",
      detail:
        "Need to travel outside Pathankot? We offer outstation cabs from Pathankot Airport",
    },
    {
      title: "- Competitive Rates:",
      detail: "With Pathankot Airport taxi per km rates",
    },
    {
      title: "- Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi Pathankot Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "- Professional Drivers:",
      detail:
        "Sewak's drivers are skilled professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "- Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title: "- Diverse Pathankot Taxi Options:",
      detail: "When selecting our Pathankot cab service for your journey",
    },
  ],
  howToBookHeading: "How to Book a Pathankot Airport Taxi?  ",
  howToBookInfo:
    "Sewak Travels solves the issue of booking a Pathankot Cab by offering premium services at affordable Pathankot taxi rates, complete with a pickup-drop facility and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit https://sewaktravels.com/, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, and proceed to payment.  \n",
  taxiServicesHeading:
    "Various Taxi Services in Pathankot Airport by Sewak Travels  ",
  taxiServices: [
    {
      title: "- Outstation Cabs from Pathankot Airport:",
      description:
        "Sewak Travels offers reliable outstation cabs from Pathankot Airport",
    },
    {
      title: "- Local Taxis from Pathankot Airport:",
      description:
        "Need a ride within Pathankot? We make it easy to book local taxis from Pathankot Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "- Corporate Airport Transfers:",
      description:
        "We also cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "- Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "- Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "- Taxi Service in Pathankot:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: "Easy Pathankot Airport Cab Booking for Every Need  ",
  easyBookingInfo:
    "Whether you are visiting Pathankot for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Pathankot Airport taxi contact number and online platforms make it easy for you to book a cab within minutes.  \n",
  easyBookingPoints: [
    "- Affordable Pathankot Airport Taxi Fare",
    "- Quick & Reliable Airport Taxi Service",
    "- Car Rental Services for Pathankot Airport",
  ],
  bookingCallToAction:
    "Book your Pathankot Airport cab now with Sewak Travels and enjoy comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Pathankot Airport taxi booking. Let Sewak Travels make your airport commute effortless!  ",
  thingsToKnowHeading: "Things To Know About Pathankot  ",
  thingsToKnowInfo:
    "Pathankot, located in Punjab, is known for its strategic location, connecting Himachal Pradesh, Jammu & Kashmir, and Punjab. Surrounded by lush green landscapes, this vibrant city is a gateway to the scenic hills of Dalhousie and Dharamshala. Famous for its historical sites, temples, and forts, Pathankot boasts a rich cultural heritage. The city is also a shopping haven for woolen clothes and traditional Punjabi artifacts. Additionally, Pathankot is renowned for its delectable Punjabi cuisine, featuring dishes like butter chicken, Amritsari kulcha, and lassi.  \n",
  faqHeading:
    " Frequently Asked Questions About Pathankot Airport Cab Booking  ",
  faqs: [
    {
      question: "How can I book a Pathankot Airport cab?",
      answer:
        "You can book a Pathankot Airport taxi easily by visiting our website sewaktravels.com, selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Pathankot?",
      answer:
        "Sewak Travels provides intra-city cab transfers that allow users to book cabs to and from Pathankot Airport in Pathankot.",
    },
    {
      question:
        "What types of vehicles do you offer for Pathankot Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Pathankot Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Pathankot Airport cabs?",
      answer:
        "Our Pathankot Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Absolutely! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question:
        "Can I book a cab for outstation travel from Pathankot Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Pathankot Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with your Pathankot cab bookings.",
    },
    {
      question: "Do you provide local sightseeing cabs in Pathankot?",
      answer:
        "Yes, we offer local taxi services for sightseeing and exploring the beautiful attractions in and around Pathankot.",
    },
    {
      question: "What safety measures are in place for your cab services?",
      answer:
        "We prioritize passenger safety by following standard sanitization protocols, ensuring our vehicles are clean and safe for travel.",
    },
  ],
};
export const patnaTaxiData = {
  pageTitle: "Book Patna Airport Cabs",
  mainHeading: "Book Patna Airport Cabs on Sewak Travels  ",
  subHeading: "Enjoy smooth Airport Pickup & Drop Services in Patna.  ",
  airportInfo:
    "Patna Airport, also known as Jay Prakash Narayan International Airport, is a key hub for both domestic and international travel in India. Established in 1973, this prominent airport serves as a gateway to the rich history, culture, and bustling city life of Patna. Located just 5 km from the city center, Patna Airport provides convenient access for travelers looking to explore the region. Our commitment to excellence ensures that you receive prompt and reliable service at every step. From the moment you arrive at Patna Airport, our drivers will assist you with your luggage and ensure a smooth ride to your destination. We understand that travel can be stressful, which is why we offer flexible booking options and real-time tracking for your cab.  \n",
  serviceInfo:
    "At Sewak Travels, we make your travel experience smoother with our reliable Patna Airport cab service. Choose Sewak Travels for a seamless airport transfer experience, letting you focus on what truly matters—your journey and the experiences that await you in Patna. Whether you are flying in for a quick visit or heading out, we ensure your journey to and from Patna Airport is relaxed.  \n",
  distanceHeading: "Patna to Patna Airport Cab Distance  ",
  distanceInfo:
    "The distance from Patna Airport to the city center is approximately 5 km, depending on the route and any stops made. For a comfortable and stress-free journey, simply book a Patna Airport taxi through Sewak Travel's user-friendly online platform. Whether you are heading to or from the airport, we ensure an amazing experience, making your travel convenient and reliable.  \n",
  bookingHeading: "Online Patna Airport Cab Booking with Sewak Travels  ",
  bookingInfo:
    "Welcome to Sewak Travels, where we offer reliable, comfortable, and on-time online Patna Airport cab booking tailored to your needs. Whether you are arriving in or departing from Patna, our Patna Airport taxi service ensures a smooth travel experience from start to finish. With a fleet of modern vehicles and professional drivers, we provide the highest standard of airport cab services in Patna.  \n",
  img: "/assets/img/LocationDifferent/Patna.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book a Patna Cab?  ",
  whyChooseSubHeading:
    "Book your Patna Cabs effortlessly: When you choose Sewak for your cab service in Patna, you get:  ",
  whyChooseInfo: [
    {
      title: "- 24/7 Booking & Travel Assistance:",
      detail: "Our Patna Airport taxi service operates 24/7",
    },
    {
      title: "- Outstation Cabs:",
      detail:
        "Need to travel outside Patna? We offer outstation cabs from Patna Airport",
    },
    {
      title: "- Competitive Rates:",
      detail: "With Patna Airport taxi per km rates",
    },
    {
      title: "- Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi Patna Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "- Professional Drivers:",
      detail:
        "Sewak's drivers are skilled professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "- Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title: "- Diverse Patna Taxi Options:",
      detail: "When selecting our Patna cab service for your journey",
    },
  ],
  howToBookHeading: "How to Book a Patna Airport Taxi?  ",
  howToBookInfo:
    "Sewak Travels solves the issue of booking a Patna Cab by offering premium services at affordable Patna taxi rates, complete with a pickup-drop facility and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit sewaktravels.com, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, and proceed to payment.  \n",
  taxiServicesHeading:
    "Various Taxi Services in Patna Airport by Sewak Travels",
  taxiServices: [
    {
      title: "- Outstation Cabs from Patna Airport:",
      description:
        "Sewak Travels offers reliable outstation cabs from Patna Airport",
    },
    {
      title: "- Local Taxis from Patna Airport:",
      description:
        "Need a ride within Patna? We make it easy to book local taxis from Patna Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "- Corporate Airport Transfers:",
      description:
        "We also cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "- Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "- Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "- Taxi Service in Patna:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: " Easy Patna Airport Cab Booking for Every Need  ",
  easyBookingInfo:
    "Whether you are visiting Patna for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Patna Airport taxi contact number and online platforms make it easy for you to book a cab within minutes.  \n",
  easyBookingPoints: [
    "- Affordable Patna Airport Taxi Fare",
    "- Quick & Reliable Airport Taxi Service",
    "- Car Rental Services for Patna Airport",
  ],
  bookingCallToAction:
    "Book your Patna Airport cab now with Sewak Travels and enjoy comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Patna Airport taxi booking. Let Sewak Travels make your airport commute effortless!  ",
  thingsToKnowHeading: "Things To Know About Patna  ",
  thingsToKnowInfo:
    "Patna, the capital of Bihar, is known for its rich history, cultural heritage, and vibrant urban life. Famous for its ancient monuments and landmarks such as Golghar and Mahavir Mandir, the city is a historical and cultural hub in India. Patna is also recognized for its delectable cuisine, including dishes like litti chokha and sattu paratha. Additionally, Patna is well-connected by road, rail, and air, making it an ideal destination for travelers eager to explore the region's vibrant culture and heritage.  \n",
  faqHeading: "Frequently Asked Questions About Patna Airport Cab Booking  ",
  faqs: [
    {
      question: "How can I book a Patna Airport cab?",
      answer:
        "You can book a Patna Airport taxi easily by visiting our website sewaktravels.com, selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Patna?",
      answer:
        "Sewak Travels provides intra-city cab transfers that allow users to book cabs to and from Patna Airport in Patna.",
    },
    {
      question:
        "What types of vehicles do you offer for Patna Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Patna Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Patna Airport cabs?",
      answer:
        "Our Patna Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Absolutely! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question: "Can I book a cab for outstation travel from Patna Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Patna Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with your Patna cab bookings.",
    },
    {
      question: "Do you provide local sightseeing cabs in Patna?",
      answer:
        "Yes, we offer local taxi services for sightseeing and exploring the beautiful attractions in and around Patna.",
    },
    {
      question: "How do I contact you for more information?",
      answer:
        "You can reach us at +91-837-782-8828 for any inquiries regarding our Patna Airport taxi service or bookings.",
    },
  ],
};
export const puducherryTaxiData = {
  pageTitle: "Book Puducherry Airport Cabs",
  mainHeading: "Book Puducherry Airport Cabs on Sewak Travels",
  subHeading: "Enjoy smooth Airport Pickup & Drop Services in Puducherry.  ",
  airportInfo:
    "Puducherry Airport, also known as Puducherry International Airport, is a key hub for both domestic and international travel in India. Established in 1989, this airport serves as a gateway to the serene beaches, French colonial charm, and cultural richness of Puducherry. Located just 6 km from the city center, Puducherry Airport offers convenient access for tourists eager to explore the region. Our commitment to excellence ensures you receive prompt and reliable service every step of the way. From the moment you arrive at Puducherry Airport, our drivers will assist with your luggage and provide a smooth ride to your destination. Understanding that travel can be stressful, we offer flexible booking options and real-time tracking for your cab.  \n",
  serviceInfo:
    "At Sewak Travels, we make your travel experience smoother with our reliable Puducherry Airport cab service. Choose Sewak Travels for a seamless airport transfer experience that lets you focus on your journey and the incredible experiences that await you in Puducherry. Whether you're flying in for a quick visit or heading out, we ensure your trip to and from Puducherry Airport is relaxing.  \n",
  distanceHeading: "Puducherry to Puducherry Airport Cab Distance",
  distanceInfo:
    "The distance from Puducherry Airport to the city center is approximately 6 km, depending on the route and any stops made. For a comfortable and stress-free journey, book a Puducherry\nAirport taxi through Sewak Travel's user-friendly online platform. Whether heading to or from the airport, we guarantee a delightful experience, making your travel convenient and reliable.  \n",
  bookingHeading: "Online Puducherry Airport Cab Booking with Sewak Travels",
  bookingInfo:
    "Welcome to Sewak Travels, where we provide reliable, comfortable, and on-time online Puducherry Airport cab booking tailored to your needs. Whether arriving in or departing from Puducherry, our Puducherry Airport taxi service ensures a smooth travel experience from start to finish. With a fleet of modern vehicles and professional drivers, we uphold the highest standards of airport cab services in Puducherry.  \n",
  img: "/assets/img/LocationDifferent/Pondicherry_airport.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book a Puducherry Cab?  ",
  whyChooseSubHeading:
    "Book your Puducherry cabs effortlessly: When you choose Sewak for your cab service in Puducherry, you get:  ",
  whyChooseInfo: [
    {
      title: "- 24/7 Booking & Travel Assistance:",
      detail: "Our Puducherry Airport taxi service operates 24/7",
    },
    {
      title: "- Outstation Cabs:",
      detail:
        "Need to travel outside Puducherry? We offer outstation cabs from Puducherry Airport",
    },
    {
      title: "- Competitive Rates:",
      detail: "With Puducherry Airport taxi per km rates",
    },
    {
      title: "- Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi Puducherry Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "- Professional Drivers:",
      detail:
        "Sewak's drivers are skilled professionals committed to passenger safety and comfort. Carefully selected",
    },
    {
      title: "- Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title: "- Diverse Puducherry Taxi Options:",
      detail: "With our Puducherry cab service",
    },
  ],
  howToBookHeading: "How to Book a Puducherry Airport Taxi?  ",
  howToBookInfo:
    "Sewak Travels simplifies booking at Puducherry cab with premium services at affordable taxi rates, offering a pickup-drop facility and prompt customer support. Visit [Sewak Travels](https://sewaktravels.com/), click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select your car and rates, fill in details, and proceed to payment.  \n",
  taxiServicesHeading:
    " Various Taxi Services at Puducherry Airport by Sewak Travels  ",
  taxiServices: [
    {
      title: "- Outstation Cabs from Puducherry Airport:",
      description: "Sewak Travels provides reliable outstation cabs",
    },
    {
      title: "- Local Taxis from Puducherry Airport:",
      description:
        "Need a ride within Puducherry? Easily book local taxis from Puducherry Airport with just a few clicks. Our local cab package is perfect for city tours and quick trips.",
    },
    {
      title: "- Corporate Airport Transfers:",
      description: "Catering to businesses",
    },
  ],
  highlights: [
    {
      title: "- Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "-Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "- Taxi Service in Puducherry:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: "Easy Puducherry Airport Cab Booking for Every Need  ",
  easyBookingInfo:
    "Whether visiting Puducherry for business, leisure, or an outstation trip, Sewak Travels offers convenient cab booking options. Our Puducherry Airport taxi contact number and online platforms make it easy for you to book a cab within minutes.\n",
  easyBookingPoints: [
    "- Affordable Puducherry Airport Taxi Fare",
    "- Quick & Reliable Airport Taxi Service",
    "- Car Rental Services for Puducherry Airport",
  ],
  bookingCallToAction:
    "Book your Agra Airport cab now with Sewak Travels and enjoy hassle-free, comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Puducherry Airport taxi booking. Let Sewak Travels make your airport commute effortless!",
  thingsToKnowHeading: "Things To Know About Puducherry",
  thingsToKnowInfo:
    "Puducherry, officially Puducherry, is a union territory in India known for its French colonial charm, tranquil beaches, and rich cultural heritage. Highlights include ancient churches, vibrant festivals, and iconic French-style architecture. Its culinary scene features unique dishes like bouillabaisse and seafood platters. Connected by road, rail, and air, Puducherry is an ideal destination for travelers seeking to immerse in its serene beauty and vibrant culture.  \n",
  faqHeading:
    "Frequently Asked Questions About Puducherry Airport Cab Booking  ",
  faqs: [
    {
      question: "How can I book a Puducherry Airport cab?",
      answer:
        "You can book a Puducherry Airport taxi easily by visiting our website sewaktravels.com and selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Puducherry?",
      answer:
        "Sewak Travels provides intra-city cab transfers which allow users to book cabs to and from the Puducherry Airport in Puducherry.",
    },
    {
      question:
        "What types of vehicles do you offer for Puducherry Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Puducherry Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Puducherry Airport cabs?",
      answer:
        "Our Puducherry Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Absolutely! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question:
        "Can I book a cab for outstation travel from Puducherry Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Puducherry Airport for your convenience.",
    },
    {
      question:
        "Yes, we provide reliable outstation cabs from Puducherry Airport for your convenience.",
      answer:
        "Our customer support is available 24/7 to assist you with your booking or any inquiries you may have.",
    },
    {
      question: "Do you offer local taxi services in Puducherry?",
      answer:
        "Yes, we provide local taxi services in Puducherry, perfect for sightseeing and city tours.",
    },
    {
      question: "How do I contact you for more information?",
      answer:
        "You can reach us at +91-837-782-8828 for any inquiries regarding our Puducherry Airport taxi service or bookings.",
    },
  ],
};
export const porbandarTaxiData = {
  pageTitle: "Book Porbandar Airport Cabs",
  mainHeading: "Book Porbandar Airport Cabs on Sewak Travels",
  subHeading: "Enjoy smooth Airport Pickup & Drop Services in Porbandar.  ",
  airportInfo:
    "Porbandar Airport, a key gateway for both domestic and international travelers, offers easy access to the vibrant culture, historical sites, and coastal beauty of Porbandar. Situated just 5 km from the city center, it provides tourists with a seamless transition into the region. With our commitment to excellent service, we ensure that you receive prompt and reliable assistance at every step. From the moment you land at Porbandar Airport, our drivers will be ready to assist with your luggage and provide a smooth, comfortable ride to your destination. We understand the stress that can accompany travel, so we offer flexible booking options and real-time tracking for your convenience.  \n",
  serviceInfo:
    "At Sewak Travels, we make your travel experience smoother with our reliable Porbandar Airport cab service. Choose Sewak Travels for a smooth airport transfer experience that allows you to focus on what matters most—your journey and the experiences awaiting you in Porbandar. Whether you're here for a short visit or heading out, we ensure your trip to and from Porbandar Airport is stress-free.  \n",
  distanceHeading: "Porbandar to Porbandar Airport Cab Distance",
  distanceInfo:
    "The distance from Porbandar Airport to the city center is approximately 5 km, depending on your route and any stops made. For a smooth and hassle-free journey, simply book a Porbandar Airport taxi via Sewak Travels' easy-to-use online platform. Whether you're headed to or from the airport, we ensure an outstanding travel experience, making your commute comfortable and reliable.  \n",
  bookingHeading: "Online Porbandar Airport Cab Booking with Sewak Travels  ",
  bookingInfo:
    "Welcome to Sewak Travels, your go-to provider for reliable, comfortable, and timely online Porbandar Airport cab booking. Whether arriving in or departing from Porbandar, our airport taxi service guarantees a seamless travel experience. With a fleet of modern vehicles and professional drivers, we offer the highest standards of airport taxi services in Porbandar.  \n",
  img: "/assets/img/LocationDifferent/Porbandar.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book a Porbandar Cab?",
  whyChooseSubHeading:
    "Book your Porbandar Cabs effortlessly: When you choose Sewak for your cab service, you enjoy:  ",
  whyChooseInfo: [
    {
      title: "- 24/7 Booking & Travel Assistance:",
      detail: "Our Porbandar Airport taxi service operates round-the-clock",
    },
    {
      title: "- Outstation Cabs:",
      detail:
        "Need to travel beyond Porbandar? We offer outstation cabs from Porbandar Airport",
    },
    {
      title: "- Competitive Rates:",
      detail:
        "Our per km rates for Porbandar Airport taxis offer transparent pricing with no hidden charges. Our budget-friendly prices guarantee you top-quality service without compromising comfort.",
    },
    {
      title: "- Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid Porbandar Airport taxi either in advance or upon arrival",
    },
    {
      title: "- Professional Drivers:",
      detail:
        "Sewak’s drivers are highly skilled professionals dedicated to your safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "- Comfortable Fleet:",
      detail:
        "Choose from various vehicle options to suit your travel preferences",
    },
    {
      title: "- Diverse Porbandar Taxi Options:",
      detail:
        "Select from three vehicle types for your Porbandar taxi service: 4-seater sedans (Dzire",
    },
  ],
  howToBookHeading: "How to Book a Porbandar Airport Taxi?",
  howToBookInfo:
    "Booking a Porbandar cab with Sewak Travels is simple and efficient, offering premium services at affordable rates. With just a few clicks, your cab booking and pickup-drop facility are taken care of. Simply visit [https://sewaktravels.com/](https://sewaktravels.com/), click the cabs icon, select your preferred service (outstation, local, hourly, corporate, or airport taxi), choose your destination and date, pick your vehicle based on your budget and needs, provide the necessary details, and proceed to payment.  \n",
  taxiServicesHeading:
    "Various Taxi Services in Porbandar Airport by Sewak Travels",
  taxiServices: [
    {
      title: "- Outstation Cabs from Porbandar Airport:",
      description:
        "Sewak Travels offers reliable outstation cabs from Porbandar Airport",
    },
    {
      title: "- Local Taxis from Porbandar Airport:",
      description:
        "Need a ride within Porbandar? We make it easy to book local taxis from Porbandar Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "- Corporate Airport Transfers:",
      description:
        "Sewak Travels also caters to corporate clients with dedicated airport transfer services for employees. Contact us for a custom quote and enjoy seamless service for your business needs.",
    },
  ],
  highlights: [
    {
      title: "- Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "- Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "- Taxi Service in Porbandar:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: "Easy Porbandar Airport Cab Booking for Every Need",
  easyBookingInfo:
    "Whether you’re visiting Porbandar for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Porbandar Airport taxi contact number and online platforms make it easy for you to book a cab within minutes.\n",
  easyBookingPoints: [
    "Affordable Porbandar Airport Taxi Fare",
    "Quick & Reliable Airport Taxi Service",
    "Car Rental Services for Porbandar Airport",
  ],
  bookingCallToAction:
    "Book your Porbandar Airport cab now with Sewak Travels for a comfortable ride at an affordable price. Call us at +91-837-782-8828 or visit our website for easy and quick Porbandar Airport taxi booking. Let Sewak Travels handle your airport commute effortlessly!  ",
  thingsToKnowHeading: "Things to Know About Porbandar",
  thingsToKnowInfo:
    "Porbandar, located in Gujarat, is renowned for its rich cultural heritage, beautiful beaches, and historical significance. As the birthplace of Mahatma Gandhi, the city is an important center for history and culture. Famous for its vibrant festivals and colonial architecture, Porbandar also boasts ancient temples and forts. The city's culinary scene, particularly its seafood, is a major attraction, with dishes like fish curry and prawn fry. Porbandar is well-connected by road, rail, and air, making it a popular destination for travelers exploring Gujarat's coast.  \n",
  faqHeading: "Frequently Asked Questions About Porbandar Airport Cab Booking",
  faqs: [
    {
      question: "How can I book a Porbandar Airport cab?",
      answer:
        "You can book a PorbandarAirport taxi easily by visiting our website sewaktravels.com and selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Porbandar?",
      answer:
        "Sewak Travels provides intra-city cab transfers which allow users to book cabs to and from the Porbandar Airport in Porbandar.",
    },
    {
      question:
        "What types of vehicles do you offer for Porbandar Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Porbandar Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Porbandar Airport cabs?",
      answer:
        "Our Porbandar Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Absolutely! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question:
        "Can I book a cab for outstation travel from Porbandar Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Porbandar Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with your booking or any inquiries you may have.",
    },
    {
      question: "Do you offer local taxi services in Porbandar?",
      answer:
        "Yes, we provide local taxi services in Porbandar, perfect for sightseeing and city tours.",
    },
    {
      question: "How do I contact you for more information?",
      answer:
        "You can reach us at +91-837-782-8828 for any inquiries regarding our Porbandar Airport taxi service or bookings.",
    },
  ],
};
export const rajahmundryTaxiData = {
  pageTitle: "Book Rajahmundry Airport Cabs",
  mainHeading: "Book Rajahmundry Airport Cabs on Sewak Travels  ",
  subHeading: "Enjoy smooth Airport Pickup & Drop Services in Rajahmundry.  ",
  airportInfo:
    "Rajahmundry Airport, also known as Rajahmundry International Airport, is a key hub for both domestic and international travel in India. Established in 1932, this historic airport serves as a gateway to the rich cultural heritage, temples, and scenic beauty of Rajahmundry. Located just 15 km from the city center, Rajahmundry Airport provides easy access for tourists looking to explore the region. Our commitment to excellence ensures that you receive prompt and reliable service at every step. From the moment you arrive at Rajahmundry Airport, our drivers will be ready to assist you with your luggage and ensure a smooth ride to your destination. We understand that travel can be stressful, which is why we offer flexible booking options and real-time tracking for your cab.  \n",
  serviceInfo:
    "At Sewak Travels, we make your travel experience smoother with our reliable Rajahmundry Airport cab service.Choose Sewak Travels for a smooth airport transfer experience that allows you to focus on what truly matters—your journey and the experiences that await you in Rajahmundry. Whether you are flying in for a quick visit or heading out, we ensure your journey to and from Rajahmundry Airport is relaxed.  \n",
  distanceHeading: "Rajahmundry to Rajahmundry Airport Cab Distance  ",
  distanceInfo:
    "The distance from Rajahmundry Airport to the city center is approximately 15 km, depending on the route and any stops made. For a comfortable and stress-free journey, simply book a Rajahmundry Airport taxi through Sewak Travels' user-friendly online platform. Whether you are heading to or from the airport, we ensure an amazing experience, making your travel convenient and reliable.  \n",
  bookingHeading: "Online Rajahmundry Airport Cab Booking with Sewak Travels  ",
  bookingInfo:
    "Welcome to Sewak Travels, where we offer reliable, comfortable, and on-time online Rajahmundry Airport cab booking tailored to your needs. Whether you are arriving in or departing from Rajahmundry, our Rajahmundry Airport taxi service ensures a smooth travel experience from start to finish. With a fleet of modern vehicles and professional drivers, we provide the highest standard of airport cab services in Rajahmundry.  \n",
  img: "/assets/img/LocationDifferent/Rajahmundry.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book a Rajahmundry Cab?  ",
  whyChooseSubHeading:
    "Book your Rajahmundry Cabs effortlessly: When you choose Sewak for your cab service in Rajahmundry, you get:  ",
  whyChooseInfo: [
    {
      title: "- 24/7 Booking & Travel Assistance:",
      detail: "Our Rajahmundry Airport taxi service operates 24/7",
    },
    {
      title: "- Outstation Cabs:",
      detail:
        "Need to travel outside Rajahmundry? We offer outstation cabs from Rajahmundry Airport",
    },
    {
      title: "- Competitive Rates:",
      detail: "With Rajahmundry Airport taxi per km rates",
    },
    {
      title: "- Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi Rajahmundry Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "- Professional Drivers:",
      detail:
        "Sewak's drivers are skilled professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "- Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title: "- Diverse Rajahmundry Taxi Options:",
      detail: "When selecting our Rajahmundry cab service for your journey",
    },
  ],
  howToBookHeading: "How to Book a Rajahmundry Airport Taxi?  ",
  howToBookInfo:
    "Sewak Travels solves the issue of booking a Rajahmundry Cab by offering premium services at affordable Rajahmundry taxi rates, complete with a pickup-drop facility and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit https://sewaktravels.com, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, and proceed to payment.  \n",
  taxiServicesHeading:
    "Various Taxi Services in Rajahmundry Airport by Sewak Travels  ",
  taxiServices: [
    {
      title: "- Outstation Cabs from Rajahmundry Airport:",
      description:
        "Sewak Travels offers reliable outstation cabs from Rajahmundry Airport",
    },
    {
      title: "- Local Taxis from Rajahmundry Airport:",
      description:
        "Need a ride within Rajahmundry? We make it easy to book local taxis from Rajahmundry Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "- Corporate Airport Transfers:",
      description:
        "We also cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "- Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "- Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "- Taxi Service in Rajahmundry:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: " Easy Rajahmundry Airport Cab Booking for Every Need  ",
  easyBookingInfo:
    "Whether you are visiting Rajahmundry for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Rajahmundry Airport taxi contact number and online platforms make it easy for you to book a cab within minutes.  \n",
  easyBookingPoints: [
    "- Affordable Rajahmundry Airport Taxi Fare",
    "- Quick & Reliable Airport Taxi Service",
    "- Car Rental Services for Rajahmundry Airport",
  ],
  bookingCallToAction:
    "Book your Rajahmundry Airport cab now with Sewak Travels and enjoy comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Rajahmundry Airport taxi booking. Let Sewak Travels make your airport commute effortless!  ",
  thingsToKnowHeading: "Things To Know About Rajahmundry  ",
  thingsToKnowInfo:
    'Rajahmundry, nestled in Andhra Pradesh, is known for its historical and cultural significance, picturesque Godavari River, and ancient temples. The city is often called the "Cultural Capital of Andhra Pradesh" and boasts attractions like the iconic Godavari Bridge and serene Pushkar Ghat. Rajahmundry is famous for its spicy Andhra cuisine and offers delectable dishes like pappu charu and gongura pachadi. Additionally, Rajahmundry is well-connected by road, rail, and air, making it an ideal destination for travelers eager to explore the vibrant traditions and beauty of this region.  \n',
  faqHeading:
    "Frequently Asked Questions About Rajahmundry Airport Cab Booking  ",
  faqs: [
    {
      question: "How can I book a Rajahmundry Airport cab?",
      answer:
        "You can book a Rajahmundry Airport taxi easily by visiting our website sewaktravels.com and selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Rajahmundry?",
      answer:
        "Sewak Travels provides intra-city cab transfers which allow users to book cabs to and from the Rajahmundry Airport in Rajahmundry.",
    },
    {
      question:
        "What types of vehicles do you offer for Rajahmundry Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Rajahmundry Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Rajahmundry Airport cabs?",
      answer:
        "Our Rajahmundry Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Absolutely! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question:
        "Can I book a cab for outstation travel from Rajahmundry Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Rajahmundry Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with your booking or any inquiries you may have.",
    },
    {
      question: "Do you offer local taxi services in Rajahmundry?",
      answer:
        "Yes, we provide local taxi services in Rajahmundry, perfect for sightseeing and city tours.",
    },
    {
      question: "How do I contact you for more information?",
      answer:
        "You can reach us at +91-837-782-8828 for any inquiries regarding our Rajahmundry Airport taxi service or bookings.",
    },
  ],
};
export const rajkotTaxiData = {
  pageTitle: "Book Rajkot Airport Cabs",
  mainHeading: "Book Rajkot Airport Cabs on Sewak Travels",
  subHeading: "Enjoy smooth Airport Pickup & Drop Services in Rajkot.  ",
  airportInfo:
    "Rajkot Airport, also known as Rajkot Civil Airport, is an important hub for both domestic and international travel in Gujarat, India. Established in 1991, this bustling airport serves as a gateway to the rich culture, historic sites, and vibrant lifestyle of Rajkot. Located just 5 km from the city center, Rajkot Airport provides easy access for tourists and business travelers looking to explore the region. At Sewak Travels, we are committed to providing you with prompt, reliable, and seamless services throughout your journey. From the moment you land at Rajkot Airport, our experienced drivers will assist you with your luggage and ensure a smooth, hassle-free ride to your destination. We understand that travel can be stressful, which is why we offer flexible booking options and real-time tracking for your cab.  \n",
  serviceInfo:
    "At Sewak Travels, we make your travel experience smoother with our reliable Rajkot Airport cab service.Choose Sewak Travels for a smooth airport transfer experience that allows you to focus on what truly matters—your journey and the experiences that await you in Rajkot. Whether you are arriving for a short visit or departing, we ensure that your travel to and from Rajkot Airport is comfortable and stress-free.  \n",
  distanceHeading: "Rajkot to Rajkot Airport Cab Distance  ",
  distanceInfo:
    "The distance from Rajkot Airport to the city center is approximately 5 km, depending on the route and any stops made. For a smooth and stress-free journey, simply book a Rajkot Airport taxi through Sewak Travel's easy-to-use online platform. Whether you are heading to or from the airport, we ensure a convenient and reliable experience, making your travel seamless.  \n",
  bookingHeading: "Online Rajkot Airport Cab Booking with Sewak Travels",
  bookingInfo:
    "Welcome to Sewak Travels, where we provide reliable, comfortable, and punctual online Rajkot Airport cab booking tailored to your needs. Whether you are arriving at or departing from Rajkot, our airport taxi service guarantees a smooth travel experience from start to finish. With a fleet of modern vehicles and professional drivers, we offer the highest standard of airport cab services in Rajkot.  \n",
  img: "/assets/img/LocationDifferent/Rajkot.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book a Rajkot Cab?",
  whyChooseSubHeading:
    "Book your Rajkot Cabs effortlessly: When you choose Sewak for your cab service in Rajkot, you get:  ",
  whyChooseInfo: [
    {
      title: "- 24/7 Booking & Travel Assistance:",
      detail: "Our Rajkot Airport taxi service operates 24/7",
    },
    {
      title: "- Outstation Cabs:",
      detail:
        "Need to travel outside Rajkot? We offer outstation cabs from Rajkot Airport",
    },
    {
      title: "- Competitive Rates:",
      detail: "With Rajkot Airport taxi per km rates",
    },
    {
      title: "- Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi from Rajkot Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "- Professional Drivers:",
      detail:
        "Sewak's drivers are highly trained professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "- Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title: "- Diverse Rajkot Taxi Options:",
      detail: "When selecting our Rajkot cab service for your journey",
    },
  ],
  howToBookHeading: "How to Book a Rajkot Airport Taxi?",
  howToBookInfo:
    "Sewak Travels solves the issue of booking a Rajkot cab by offering premium services at affordable Rajkot taxi rates, complete with a pickup-drop facility and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit https://sewaktravels.com/, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, and proceed to payment.  \n",
  taxiServicesHeading:
    "Various Taxi Services in Rajkot Airport by Sewak Travels",
  taxiServices: [
    {
      title: "- Outstation Cabs from Rajkot Airport:",
      description:
        "Sewak Travels offers reliable outstation cabs from Rajkot Airport",
    },
    {
      title: "- Local Taxis from Rajkot Airport:",
      description:
        "Need a ride within Rajkot? We make it easy to book local taxis from Rajkot Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "- Corporate Airport Transfers:",
      description:
        "We also cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "- Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "- Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "- Taxi Service in Rajkot:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: "Easy Rajkot Airport Cab Booking for Every Need",
  easyBookingInfo:
    "Whether you are visiting Rajkot for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Rajkot Airport taxi contact number and online platforms make it easy for you to book a cab within minutes.  \n",
  easyBookingPoints: [
    "- Affordable Rajkot Airport Taxi Fare",
    "- Quick & Reliable Airport Taxi Service",
    "- Car Rental Services for Rajkot Airport",
  ],
  bookingCallToAction:
    "Book your Rajkot Airport cab now with Sewak Travels and enjoy comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Rajkot Airport taxi booking. Let Sewak Travels make your airport commute effortless!  ",
  thingsToKnowHeading: "Things To Know About Rajkot",
  thingsToKnowInfo:
    "Rajkot, located in the Saurashtra region of Gujarat, is a city known for its rich cultural heritage, vibrant markets, and thriving industries. Famous for its unique blend of traditional and modern elements, Rajkot offers a perfect combination of historic landmarks and contemporary charm. With its well-preserved palaces, temples, and a bustling street food culture, Rajkot has something for everyone. The city is also known for its contributions to art, textiles, and metalwork, with several local industries continuing to thrive.  \n",
  faqHeading: "Frequently Asked Questions About Rajkot Airport Cab Booking",
  faqs: [
    {
      question: "How can I book a Rajkot Airport cab?",
      answer:
        "You can book a Rajkot Airport taxi easily by visiting our website sewaktravels.com, selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Rajkot?",
      answer:
        "Sewak Travels provides intra-city cab transfers, allowing users to book cabs to and from Rajkot Airport in Rajkot.",
    },
    {
      question:
        "What types of vehicles do you offer for Rajkot Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Rajkot Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Rajkot Airport cabs?",
      answer:
        "Our Rajkot Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Absolutely! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question: "Can I book a cab for outstation travel from Rajkot Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Rajkot Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with your Rajkot cab bookings.",
    },
    {
      question: "Do you provide local sightseeing cabs in Rajkot?",
      answer:
        "Yes, we offer local taxi services for sightseeing and exploring the beautiful attractions in and around Rajkot.",
    },
    {
      question: "How do I contact you for more information?",
      answer:
        "You can reach us at +91-837-782-8828 for any inquiries regarding our Agra Airport taxi service or bookings.",
    },
  ],
};
export const suratTaxiData = {
  pageTitle: "Book Surat Airport Cabs",
  mainHeading: "Book Surat Airport Cabs on Sewak Travels",
  subHeading: "Enjoy smooth Airport Pickup & Drop Services in Surat.",
  airportInfo:
    "Surat Airport, also known as Surat International Airport, serves as an important hub for domestic and international travel in India. Strategically located near the city, Surat Airport connects visitors to the vibrant culture, historical sites, and bustling commercial centers of Surat. Our commitment to excellence ensures you receive prompt and reliable service at every step. From the moment you arrive at Surat Airport, our drivers will assist with your luggage and ensure a smooth ride to your destination. We understand that travel can be stressful, which is why we offer flexible booking options and real-time tracking for your cab. \n",
  serviceInfo:
    "At Sewak Travels, we make your travel experience smoother with our reliable Surat Airport cab service.Choose Sewak Travels for a smooth airport transfer experience, allowing you to focus on what truly matters—your journey and the experiences that await you in Surat. Whether you're flying in for a quick visit or heading out, we ensure your journey to and from Surat Airport is relaxed and comfortable.\n",
  distanceHeading: "Surat to Surat Airport Cab Distance",
  distanceInfo:
    "The distance from Surat Airport to the city center is approximately 14 km, depending on the route and any stops made. For a comfortable and stress-free journey, simply book a Surat Airport taxi through Sewak Travels' user-friendly online platform. Whether you're heading to or from the airport, we ensure an amazing experience, making your travel convenient and reliable.\n",
  bookingHeading: "Online Surat Airport Cab Booking with Sewak Travels",
  bookingInfo:
    "Welcome to Sewak Travels, where we offer reliable, comfortable, and on-time online Surat Airport cab booking tailored to your needs. Whether you are arriving in or departing from Surat, our Surat Airport taxi service ensures a smooth travel experience from start to finish. With a fleet of modern vehicles and professional drivers, we provide the highest standard of airport cab services in Surat.\n",
  img: "/assets/img/LocationDifferent/Surat_airport.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book a Surat Cab?",
  whyChooseSubHeading:
    "Book your Surat Cabs effortlessly: When you choose Sewak for your cab service in Surat, you get:",
  whyChooseInfo: [
    {
      title: "24/7 Booking & Travel Assistance:",
      detail: "Our Surat Airport taxi service operates 24/7",
    },
    {
      title: "- Outstation Cabs:",
      detail:
        "Need to travel outside Surat? We offer outstation cabs from Surat Airport",
    },
    {
      title: "- Competitive Rates:",
      detail: "With Surat Airport taxi per km rates",
    },
    {
      title: "- Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi at Surat Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "- Professional Drivers:",
      detail:
        "Sewak's drivers are skilled professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "- Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title: "- Diverse Surat Taxi Options:",
      detail: "When selecting our Surat cab service for your journey",
    },
  ],
  howToBookHeading: "How to Book a Surat Airport Taxi?",
  howToBookInfo:
    "Sewak Travels solves the issue of booking a Surat Cab by offering premium services at affordable Surat taxi rates, complete with a pickup-drop facility and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit https://sewaktravels.com/, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, and proceed to payment.\n",
  taxiServicesHeading:
    "Various Taxi Services in Surat Airport by Sewak Travels",
  taxiServices: [
    {
      title: "- Outstation Cabs from Surat Airport:",
      description:
        "Sewak Travels offers reliable outstation cabs from Surat Airport",
    },
    {
      title: "- Local Taxis from Surat Airport:",
      description:
        "Need a ride within Surat? We make it easy to book local taxis from Surat Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "- Corporate Airport Transfers:",
      description:
        "We also cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "- Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "- Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "- Taxi Service in Surat:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: "Easy Surat Airport Cab Booking for Every Need",
  easyBookingInfo:
    "Whether you are visiting Surat for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Surat Airport taxi contact number and online platforms make it easy for you to book a cab within minutes.\n",
  easyBookingPoints: [
    "Affordable Surat Airport Taxi Fare\nQuick & Reliable Airport Taxi Service  \nCar Rental Services for Surat Airport",
  ],
  bookingCallToAction:
    "Book your Surat Airport cab now with Sewak Travels and enjoy comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Surat Airport taxi booking. Let Sewak Travels make your airport commute effortless!",
  thingsToKnowHeading: "Things To Know About Surat",
  thingsToKnowInfo:
    "Surat, one of the major cities in Gujarat, is known for its rich cultural heritage, textile industry, and vibrant festivals. Famous for its silk and diamond markets, Surat also offers a variety of attractions such as its historical sites, lakes, and the beautiful Dumas Beach. The city is also home to several temples, making it a popular destination for spiritual tourism. Surat's culinary scene is diverse, featuring famous dishes like surti undhiyu and a variety of seafood. Well-connected by air, rail, and road, Surat is an ideal gateway for those wanting to explore the cultural richness and commercial vibrancy of Gujarat.",
  faqHeading: "Frequently Asked Questions About Surat Airport Cab Booking",
  faqs: [
    {
      question: "How can I book a Surat Airport cab?",
      answer:
        "You can book a Surat Airport taxi easily by visiting our website sewaktravels.com, selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Surat?",
      answer:
        "Sewak Travels provides intra-city cab transfers that allow users to book cabs to and from Surat Airport in Surat.",
    },
    {
      question:
        "What types of vehicles do you offer for Surat Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Surat Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Surat Airport cabs?",
      answer:
        "Our Surat Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Absolutely! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question: "Can I book a cab for outstation travel from Surat Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Surat Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with your Surat cab bookings.",
    },
    {
      question: "Do you provide local sightseeing cabs in Surat?",
      answer:
        "Yes, we offer local taxi services for sightseeing and exploring the beautiful attractions in and around Surat.",
    },
    {
      question: "How do I contact you for more information?",
      answer:
        "You can reach us at +91-837-782-8828 for any inquiries regarding our Surat Airport taxi service or bookings.",
    },
  ],
};
export const shirdiTaxiData = {
  pageTitle: "Book Shirdi Airport Cabs",
  mainHeading: "Book Shirdi Airport Cabs on Sewak Travels ",
  subHeading: "Enjoy smooth Airport Pickup & Drop Services in Shirdi.  ",
  airportInfo:
    "Shirdi Airport, also known as Shirdi International Airport, is an important hub for both domestic and international travel to this spiritual destination. Established to facilitate easy access for pilgrims and tourists, it serves as a gateway to the divine experience of Shirdi and its iconic Sai Baba Temple. Located just 15 km from the city center, Shirdi Airport provides seamless connectivity for visitors. Our commitment to excellence ensures that you receive prompt and reliable service every time. From the moment you land at Shirdi Airport, our drivers will be ready to assist you with your luggage and ensure a smooth ride to your destination. We understand that travel can be stressful, so we offer flexible booking options and real-time tracking for your cab.  \n",
  serviceInfo:
    "At Sewak Travels, we make your travel experience smoother with our reliable Shirdi Airport cab service.Choose Sewak Travels for a seamless airport transfer experience, letting you focus on what truly matters—your journey and the spiritual experiences that await you in Shirdi. Whether you're visiting for a quick trip or heading out, we ensure your journey to and from Shirdi Airport is relaxing.\n",
  distanceHeading: "Shirdi to Shirdi Airport Cab Distance",
  distanceInfo:
    "The distance from Shirdi Airport to the city center is approximately 15 km, depending on the route and any stops made. For a comfortable and stress-free journey, simply book a Shirdi Airport taxi through Sewak Travel's user-friendly online platform. Whether you are heading to or from the airport, we ensure an amazing experience, making your travel convenient and reliable.\n",
  bookingHeading: "Online Shirdi Airport Cab Booking with Sewak Travels",
  bookingInfo:
    "Welcome to Sewak Travels, where we offer reliable, comfortable, and on-time online Shirdi Airport cab booking tailored to your needs. Whether you are arriving in or departing from Shirdi, our Shirdi Airport taxi service ensures a smooth travel experience from start to finish. With a fleet of modern vehicles and professional drivers, we provide the highest standard of airport cab services in Shirdi.\n",
  img: "/assets/img/LocationDifferent/Shirdi_airport.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book a Shirdi Cab?",
  whyChooseSubHeading:
    "Book your Shirdi Cabs effortlessly: When you choose Sewak for your cab service in Shirdi, you get:  ",
  whyChooseInfo: [
    {
      title: "- 24/7 Booking & Travel Assistance:",
      detail: "Our Shirdi Airport taxi service operates 24/7",
    },
    {
      title: "- Outstation Cabs:",
      detail:
        "Need to travel outside Shirdi? We offer outstation cabs from Shirdi Airport",
    },
    {
      title: "- Competitive Rates:",
      detail: "With Shirdi Airport taxi per km rates",
    },
    {
      title: "- Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi at Shirdi Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "- Professional Drivers:",
      detail:
        "Sewak's drivers are skilled professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "- Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title: "- Diverse Shirdi Taxi Options:",
      detail: "When selecting our Shirdi cab service for your journey",
    },
  ],
  howToBookHeading: "How to Book a Shirdi Airport Taxi? ",
  howToBookInfo:
    "Sewak Travels solves the issue of booking a Shirdi Cab by offering premium services at affordable Shirdi taxi rates, complete with a pickup-drop facility and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit https://sewaktravels.com/, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, and proceed to payment.\n",
  taxiServicesHeading:
    "Various Taxi Services in Shirdi Airport by Sewak Travels",
  taxiServices: [
    {
      title: "- Outstation Cabs from Shirdi Airport:",
      description:
        "Sewak Travels offers reliable outstation cabs from Shirdi Airport",
    },
    {
      title: "- Local Taxis from Shirdi Airport:",
      description:
        "Need a ride within Shirdi? We make it easy to book local taxis from Shirdi Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "- Corporate Airport Transfers:",
      description:
        "We also cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "- Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "- Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "- Taxi Service in Shirdi:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: "Easy Shirdi Airport Cab Booking for Every Need",
  easyBookingInfo:
    "Whether you are visiting Shirdi for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Shirdi Airport taxi contact number and online platforms make it easy for you to book a cab within minutes.\n",
  easyBookingPoints: [
    "Affordable Shirdi Airport Taxi Fare",
    "Quick & Reliable Airport Taxi Service",
    "Car Rental Services for Shirdi Airport",
  ],
  bookingCallToAction:
    "Book your Shirdi Airport cab now with Sewak Travels and enjoy comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Shirdi Airport taxi booking. Let Sewak Travels make your airport commute effortless!",
  thingsToKnowHeading: "Things To Know About Shirdi",
  thingsToKnowInfo:
    "Shirdi is a significant religious town in Maharashtra, known for its association with the revered Sai Baba. The town draws millions of pilgrims every year who visit the famous Sai Baba Temple. In addition to spiritual significance, Shirdi is also a destination for relaxation, offering beautiful surroundings and a peaceful atmosphere. The town is well-connected by road, rail, and air, making it an easy destination for travelers from all parts of the country. Shirdi's local markets and eateries offer a variety of traditional Maharashtrian delicacies, further enhancing the cultural experience of the visitors.\n",
  faqHeading: "Frequently Asked Questions About Shirdi Airport Cab Booking",
  faqs: [
    {
      question: "How can I book a Shirdi Airport cab?",
      answer:
        "You can book a Shirdi Airport taxi easily by visiting our website sewaktravels.com, selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Shirdi?",
      answer:
        "Sewak Travels provides intra-city cab transfers that allow users to book cabs to and from Shirdi Airport in Shirdi.",
    },
    {
      question:
        "What types of vehicles do you offer for Shirdi Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Shirdi Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Shirdi Airport cabs?",
      answer:
        "Our Shirdi Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Absolutely! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question: "Can I book a cab for outstation travel from Shirdi Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Shirdi Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with your Shirdi cab bookings.",
    },
    {
      question: "Do you provide local sightseeing cabs in Shirdi?",
      answer:
        "Yes, we offer local taxi services for sightseeing and exploring the beautiful attractions in and around Shirdi.",
    },
    {
      question: " How do I contact you for more information?",
      answer:
        "You can reach us at +91-837-782-8828 for any inquiries regarding our Shirdi Airport taxi service or bookings.",
    },
  ],
};
export const tiruchirappalliTaxiData = {
  pageTitle: "Book Tiruchirappalli Airport Cabs",
  mainHeading: "Book Tiruchirappalli Airport Cabs on Sewak Travels  ",
  subHeading:
    "Enjoy smooth Airport Pickup & Drop Services in Tiruchirappalli.  ",
  airportInfo:
    "Tiruchirappalli Airport, also known as Tiruchirappalli International Airport, is a key hub for both domestic and international travel in India. Established in 1957, this historic airport serves as a gateway to the vibrant culture, temples, and rich heritage of Tiruchirappalli. Located just 15 km from the city center, Tiruchirappalli Airport provides easy access for tourists looking to explore the region. Our commitment to excellence ensures that you receive prompt and reliable service at every step. From the moment you arrive at Tiruchirappalli Airport, our drivers will be ready to assist you with your luggage and ensure a smooth ride to your destination. We understand that travel can be stressful, which is why we offer flexible booking options and real-time tracking for your cab.  \n",
  serviceInfo:
    "At Sewak Travels, we make your travel experience smoother with our reliable Tiruchirappalli Airport cab service. Choose Sewak Travels for a smooth airport transfer experience that allows you to focus on what truly matters—your journey and the experiences that await you in Tiruchirappalli. Whether you are flying in for a quick visit or heading out, we ensure your journey to and from Tiruchirappalli Airport is relaxed.  \n",
  distanceHeading: "Tiruchirappalli to Tiruchirappalli Airport Cab Distance  ",
  distanceInfo:
    "The distance from Tiruchirappalli Airport to the city center is approximately 15 km, depending on the route and any stops made. For a comfortable and stress-free journey, simply book a Tiruchirappalli Airport taxi through Sewak Travel's user-friendly online platform. Whether you are heading to or from the airport, we ensure an amazing experience, making your travel convenient and reliable.  \n",
  bookingHeading:
    "Online Tiruchirappalli Airport Cab Booking with Sewak Travels  ",
  bookingInfo:
    "Welcome to Sewak Travels, where we offer reliable, comfortable, and on-time online Tiruchirappalli Airport cab booking tailored to your needs. Whether you are arriving in or departing from Tiruchirappalli, our Tiruchirappalli Airport taxi service ensures a smooth travel experience from start to finish. With a fleet of modern vehicles and professional drivers, we provide the highest standard of airport cab services in Tiruchirappalli.  \n",
  img: "/assets/img/LocationDifferent/Tiruchirappalli.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book a Tiruchirappalli Cab?  ",
  whyChooseSubHeading:
    "Book your Tiruchirappalli Cabs effortlessly: When you choose Sewak for your cab service in Tiruchirappalli, you get: ",
  whyChooseInfo: [
    {
      title: "- 24/7 Booking & Travel Assistance:",
      detail: "Our Tiruchirappalli Airport taxi service operates 24/7",
    },
    {
      title: "- Outstation Cabs:",
      detail:
        "Need to travel outside Tiruchirappalli? We offer outstation cabs from Tiruchirappalli Airport",
    },
    {
      title: "- Competitive Rates:",
      detail: "With Tiruchirappalli Airport taxi per km rates",
    },
    {
      title: "- Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi Tiruchirappalli Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "- Professional Drivers:",
      detail:
        "Sewak's drivers are skilled professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "- Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title: "- Diverse Tiruchirappalli Taxi Options:",
      detail: "When selecting our Tiruchirappalli cab service for your journey",
    },
  ],
  howToBookHeading: "How to Book a Tiruchirappalli Airport Taxi?  ",
  howToBookInfo:
    "Sewak Travels solves the issue of booking a Tiruchirappalli Cab by offering premium services at affordable Tiruchirappalli taxi rates, complete with a pickup-drop facility and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit https://sewaktravels.com/], click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, and proceed to payment.  \n",
  taxiServicesHeading:
    "Various Taxi Services in Tiruchirappalli Airport by Sewak Travels  ",
  taxiServices: [
    {
      title: "- Outstation Cabs from Tiruchirappalli Airport:",
      description:
        "Sewak Travels offers reliable outstation cabs from Tiruchirappalli Airport",
    },
    {
      title: "- Local Taxis from Tiruchirappalli Airport:",
      description:
        "Need a ride within Tiruchirappalli? We make it easy to book local taxis from Tiruchirappalli Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "- Corporate Airport Transfers:",
      description:
        "We also cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "- Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "- Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "- Taxi Service in Tiruchirappalli:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading:
    "Easy Tiruchirappalli Airport Cab Booking for Every Need  ",
  easyBookingInfo:
    "Whether you are visiting Tiruchirappalli for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Tiruchirappalli Airport taxi contact number and online platforms make it easy for you to book a cab within minutes.  \n",
  easyBookingPoints: [
    "Affordable Tiruchirappalli Airport Taxi Fare",
    "Quick & Reliable Airport Taxi Service",
    "Car Rental Services for Tiruchirappalli Airport",
  ],
  bookingCallToAction:
    "Book your Tiruchirappalli Airport cab now with Sewak Travels and enjoy comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Tiruchirappalli Airport taxi booking. Let Sewak Travels make your airport commute effortless!  ",
  thingsToKnowHeading: "Things To Know About Tiruchirappalli  ",
  thingsToKnowInfo:
    "Tiruchirappalli, located in Tamil Nadu, is known for its rich cultural heritage, temples, and scenic landscapes. Famous for its ancient temples and vibrant festivals, the city boasts several historic landmarks, including the famous Rockfort Temple and Sri Ranganathaswamy Temple. Tiruchirappalli is also a prominent educational hub and offers visitors a blend of cultural experiences, natural beauty, and historical sites. The city’s culinary scene is renowned for its South Indian cuisine, particularly its local delicacies. Additionally, Tiruchirappalli is well-connected by road, rail, and air, making it an ideal destination for travelers eager to explore its rich cultural and historical significance.  \n",
  faqHeading:
    "Frequently Asked Questions About Tiruchirappalli Airport Cab Booking  ",
  faqs: [
    {
      question: "How can I book a Tiruchirappalli Airport cab?",
      answer:
        "You can book a Tiruchirappalli Airport taxi easily by visiting our website sewaktravels.com, selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Tiruchirappalli?",
      answer:
        "Sewak Travels provides intra-city cab transfers that allow users to book cabs to and from Tiruchirappalli Airport in Tiruchirappalli.",
    },
    {
      question:
        "What types of vehicles do you offer for Tiruchirappalli Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Tiruchirappalli Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Tiruchirappalli Airport cabs?",
      answer:
        "Our Tiruchirappalli Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Absolutely! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question:
        "Can I book a cab for outstation travel from Tiruchirappalli Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Tiruchirappalli Airport for your convenience.",
    },
    {
      question:
        "What are your customer support hours for Tiruchirappalli Airport taxi service?",
      answer:
        "Our customer support is available 24/7, ensuring you can book your Tiruchirappalli Airport taxi at any time.",
    },
    {
      question: "Do you offer local taxi services in Tiruchirappalli ?",
      answer:
        "Yes, we provide local taxi services in Agra, perfect for sightseeing and city tours.",
    },
    {
      question: "How do I contact you for more information?",
      answer:
        "You can reach us at +91-837-782-8828 for any inquiries regarding our Tiruchirappalli Airport taxi service or bookings.",
    },
  ],
};
export const udaipurTaxiData = {
  pageTitle: "Book Udaipur Airport Cabs",
  mainHeading: "Book Udaipur Airport Cabs on Sewak Travels",
  subHeading: "Enjoy smooth Airport Pickup & Drop Services in Udaipur.  ",
  airportInfo:
    "Udaipur Airport, also known as Maharana Pratap Airport, is a major hub for domestic travel in Rajasthan, India. Established in 2009, this well-connected airport serves as a gateway to the royal city of Udaipur, known for its lakes, palaces, and rich cultural heritage. Located just 22 km from the city center, Udaipur Airport offers easy access for travelers looking to explore this beautiful destination. At Sewak Travels, we are committed to providing prompt and reliable airport transfer services. From the moment you land at Udaipur Airport, our professional drivers will assist you with your luggage and ensure a smooth, comfortable ride to your destination. We understand that travel can be stressful, which is why we offer flexible booking options and real-time tracking for your cab.  \n",
  serviceInfo:
    "At Sewak Travels, we make your travel experience smoother with our reliable Udaipur Airport cab service. Choose Sewak Travels for an efficient and hassle-free airport transfer experience that allows you to focus on your journey and the experiences that await you in Udaipur. Whether you’re flying in for a short visit or heading out, we ensure your travel to and from Udaipur Airport is smooth and stress-free.  \n",
  distanceHeading: "Udaipur to Udaipur Airport Cab Distance",
  distanceInfo:
    "The distance from Udaipur Airport to the city center is approximately 22 km, depending on the route and any stops made. To ensure a comfortable and stress-free journey, simply book your Udaipur Airport taxi through Sewak Travel's user-friendly online platform. Whether heading to or from the airport, we guarantee a seamless travel experience that makes your journey more convenient and reliable.\n",
  bookingHeading: "Online Udaipur Airport Cab Booking with Sewak Travels  ",
  bookingInfo:
    "Welcome to Sewak Travels, where we offer reliable, comfortable, and on-time online Udaipur Airport cab booking tailored to your needs. Whether arriving in or departing from Udaipur, our Udaipur Airport taxi service ensures a smooth travel experience from start to finish. With a fleet of modern vehicles and professional drivers, we provide the highest standard of airport cab services in Udaipur.\n",
  img: "/assets/img/LocationDifferent/Udaipur.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book a Udaipur Cab?  ",
  whyChooseSubHeading:
    "Book your Udaipur Cabs effortlessly: When you choose Sewak for your cab service in Udaipur, you get:  ",
  whyChooseInfo: [
    {
      title: "- 24/7 Booking & Travel Assistance:",
      detail: "Our Udaipur Airport taxi service operates 24/7",
    },
    {
      title: "- Outstation Cabs:",
      detail:
        "Need to travel outside Udaipur? We offer outstation cabs from Udaipur Airport",
    },
    {
      title: "- Competitive Rates:",
      detail: "With Udaipur Airport taxi per km rates",
    },
    {
      title: "- Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi for Udaipur Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "- Professional Drivers:",
      detail:
        "Sewak’s drivers are skilled professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "- Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title: "- Diverse Udaipur Taxi Options:",
      detail: "When selecting our Udaipur cab service for your journey",
    },
  ],
  howToBookHeading: "How to Book a Udaipur Airport Taxi?",
  howToBookInfo:
    "Sewak Travels makes booking a Udaipur cab simple by offering premium services at affordable Udaipur taxi rates, complete with pickup-drop facilities and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit https://sewaktravels.com/, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, and proceed to payment.",
  taxiServicesHeading:
    "Various Taxi Services in Udaipur Airport by Sewak Travels",
  taxiServices: [
    {
      title: "- Outstation Cabs from Udaipur Airport:",
      description:
        "Sewak Travels offers reliable outstation cabs from Udaipur Airport",
    },
    {
      title: "- Local Taxis from Udaipur Airport:",
      description:
        "Need a ride within Udaipur? We make it easy to book local taxis from Udaipur Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "- Corporate Airport Transfers:",
      description:
        "We also cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "- Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "- Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "- Taxi Service in Udaipur:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: "Easy Udaipur Airport Cab Booking for Every Need",
  easyBookingInfo:
    "Whether you are visiting Udaipur for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Udaipur Airport taxi contact number and online platforms make it easy for you to book a cab within minutes.\n",
  easyBookingPoints: [
    "Affordable Udaipur Airport Taxi Fare",
    "Quick & Reliable Airport Taxi Service",
    "Car Rental Services for Udaipur Airport",
  ],
  bookingCallToAction:
    "Book your Udaipur Airport cab now with Sewak Travels and enjoy comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Udaipur Airport taxi booking. Let Sewak Travels make your airport commute effortless!  ",
  thingsToKnowHeading: "Things To Know About Udaipur",
  thingsToKnowInfo:
    'Udaipur, also known as the "City of Lakes," is a beautiful city located in Rajasthan, India. Known for its royal palaces, serene lakes, and rich cultural history, Udaipur is a must-visit destination for travelers. Famous for attractions such as the City Palace, Lake Pichola, and Jag Mandir, Udaipur is perfect for both leisure and business travelers. Udaipur is also renowned for its vibrant festivals, stunning architecture, and delicious cuisine, including Rajasthani delicacies like dal bati churma and gatte ki sabzi. The city\'s well-preserved historical sites make it a cultural hub in India, while its luxurious resorts offer the perfect place for relaxation. Udaipur is well-connected by road, rail, and air, making it easy for travelers to visit and explore this picturesque city.\n',
  faqHeading: "Frequently Asked Questions About Udaipur Airport Cab Booking",
  faqs: [
    {
      question: "How can I book a Udaipur Airport cab?",
      answer:
        "You can book a Udaipur Airport taxi easily by visiting our website sewaktravels.com, selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Udaipur?",
      answer:
        "Sewak Travels provides intra-city cab transfers that allow users to book cabs to and from Udaipur Airport in Udaipur.",
    },
    {
      question:
        "What types of vehicles do you offer for Udaipur Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Udaipur Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Udaipur Airport cabs?",
      answer:
        "Our Udaipur Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Absolutely! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question:
        "Can I book a cab for outstation travel from Udaipur Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Udaipur Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with your Udaipur cab bookings.",
    },
    {
      question: "Do you provide local sightseeing cabs in Udaipur?",
      answer:
        "Yes, we offer local taxi services for sightseeing and exploring the beautiful attractions in and around Udaipur.",
    },
    {
      question: "How do I contact you for more information?",
      answer:
        "You can reach us at +91-837-782-8828 for any inquiries regarding our Udaipur Airport taxi service or bookings.",
    },
  ],
};
export const varanasiTaxiData = {
  pageTitle: "Book Varanasi Airport Cabs",
  mainHeading: "Book Varanasi Airport Cabs on Sewak Travels ",
  subHeading: "Enjoy smooth Airport Pickup & Drop Services in Varanasi.  ",
  airportInfo:
    "Varanasi Airport, officially known as Lal Bahadur Shastri International Airport, is a vital gateway to the holy city of Varanasi, one of India’s oldest and most spiritually significant cities. Located about 26 km from the city center, this well-connected airport serves both domestic and international travelers. Established in 1930, Varanasi Airport is the entry point for those looking to experience the rich culture, ghats, temples, and vibrant atmosphere of the city. Sewak Travels ensures that you have a seamless travel experience, with our reliable and prompt airport pickup and drop services. From the moment you arrive at Varanasi Airport, our experienced drivers will assist you with your luggage and offer a comfortable ride to your destination. We understand the importance of stress-free travel, so we offer flexible booking options and real-time tracking for your cab.  \n",
  serviceInfo:
    "At Sewak Travels, we make your travel experience smoother with our reliable Varanasi Airport cab service. Choose Sewak Travels for a smooth airport transfer experience and focus on what truly matters—your journey and the experiences awaiting you in Varanasi. Whether you're arriving for a spiritual retreat or departing, we ensure a hassle-free and relaxing ride to and from Varanasi Airport.\n",
  distanceHeading: "Varanasi to Varanasi Airport Cab Distance",
  distanceInfo:
    "The distance from Varanasi Airport to the city center is approximately 26 km, depending on the route taken and any stops along the way. For a smooth, hassle-free journey, simply book your Varanasi Airport taxi through Sewak Travels' easy-to-use online platform. Whether you're traveling to or from the airport, we ensure a comfortable experience, making your travel convenient and reliable.\n",
  bookingHeading: "Online Varanasi Airport Cab Booking with Sewak Travels ",
  bookingInfo:
    "Welcome to Sewak Travels, where we offer reliable, comfortable, and punctual online Varanasi Airport cab booking services tailored to your needs. Whether you're arriving in or departing from Varanasi, our Varanasi Airport taxi service ensures a smooth and stress-free travel experience from start to finish. With a fleet of modern vehicles and professional drivers, we provide the highest standard of airport cab services in Varanasi.\n",
  img: "/assets/img/LocationDifferent/Varanasi.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book a Varanasi Cab?",
  whyChooseSubHeading:
    "Book your Varanasi Cabs effortlessly: When you choose Sewak for your cab service in Varanasi, you get:  ",
  whyChooseInfo: [
    {
      title:
        "- 24/7 Booking & Travel Assistance: Our Varanasi Airport taxi service operates 24/7",
      detail: "ensuring you can book a ride at any hour.",
    },
    {
      title:
        "- Outstation Cabs: Need to travel outside Varanasi? We offer outstation cabs from Varanasi Airport",
      detail: "making us your ideal choice for longer journeys.",
    },
    {
      title: "- Competitive Rates: With Varanasi Airport taxi per km rates",
      detail:
        "enjoy transparent pricing with no hidden charges. Our affordable prices ensure the best cab service in Varanasi without compromising comfort or quality.",
    },
    {
      title:
        "- Professional Drivers:** Sewak's drivers are highly skilled and dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
      detail:
        "they possess extensive route knowledge and excel in navigation. Across India",
    },
    {
      title:
        "- Comfortable Fleet: Choose from a wide selection of vehicles to suit your travel preferences",
      detail: "from economy to luxury options.",
    },
    {
      title:
        "- Diverse Varanasi Taxi Options: When selecting our Varanasi cab service for your journey",
      detail: "you can choose from three types of cabs: 4-seater sedans (Dzire",
    },
  ],
  howToBookHeading: "How to Book a Varanasi Airport Taxi?",
  howToBookInfo:
    "Sewak Travels makes booking a Varanasi cab easy by offering premium services at affordable Varanasi taxi rates, complete with a pickup-drop facility and excellent customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit https://sewaktravels.com/, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, pick the car and rates according to your needs and budget, fill in the required details, and proceed to payment.\n",
  taxiServicesHeading:
    "Various Taxi Services in Varanasi Airport by Sewak Travels",
  taxiServices: [
    {
      title: "- Outstation Cabs from Varanasi Airport:",
      description:
        "Sewak Travels offers reliable outstation cabs from Varanasi Airport",
    },
    {
      title: "- Local Taxis from Varanasi Airport:",
      description:
        "Need a ride within Varanasi? We make it simple to book local taxis from Varanasi Airport with just a few clicks. Our local cab packages offer great value",
    },
    {
      title: "- Corporate Airport Transfers:",
      description:
        "We also cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "- Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "- Prepaid Taxi Service:",
      detail:
        "Avoid last-minute hassles by booking your prepaid taxi at Varanasi Airport in advance for peace of mind.",
    },
    {
      title: "- Taxi Service in Varanasi:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: "Easy Varanasi Airport Cab Booking for Every Need",
  easyBookingInfo:
    "Whether you're visiting Varanasi for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Varanasi Airport taxi contact number and online platforms make it easy for you to book a cab within minutes.\n",
  easyBookingPoints: [
    "Affordable Varanasi Airport Taxi Fare",
    "Quick & Reliable Airport Taxi Service",
    "Car Rental Services for Varanasi Airport",
  ],
  bookingCallToAction:
    "Book your Varanasi Airport cab now with Sewak Travels and enjoy comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Varanasi Airport taxi booking. Let Sewak Travels make your airport commute effortless!",
  thingsToKnowHeading: "Things To Know About Varanasi",
  thingsToKnowInfo:
    "Varanasi, also known as Banaras or Kashi, is one of the world’s oldest continuously inhabited cities. Renowned for its spiritual significance, the city is considered a major pilgrimage destination for Hindus. With its historic ghats, temples, and vibrant culture, Varanasi offers visitors a unique cultural and spiritual experience. The city's cuisine, particularly its street food like chaat and kachori, is famous worldwide. Varanasi is well-connected by road, rail, and air, making it an ideal destination for travelers eager to explore the essence of India's religious heritage and ancient culture.\n",
  faqHeading: "Frequently Asked Questions About Varanasi Airport Cab Booking",
  faqs: [
    {
      question: "How can I book a Varanasi Airport cab?",
      answer:
        "You can easily book a Varanasi Airport taxi by visiting our website sewaktravels.com, selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Varanasi?",
      answer:
        "Sewak Travels provides both intra-city and intercity cab transfers, allowing users to book cabs to and from Varanasi Airport.",
    },
    {
      question:
        "What types of vehicles do you offer for Varanasi Airport cab services?",
      answer:
        "We offer various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Varanasi Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Varanasi Airport cabs?",
      answer:
        "Our Varanasi Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Yes! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question:
        "Can I book a cab for outstation travel from Varanasi Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Varanasi Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with your Varanasi cab bookings.",
    },
    {
      question: "Do you provide local sightseeing cabs in Varanasi?",
      answer:
        "Yes, we offer local taxi services for sightseeing and exploring the beautiful attractions in and around Varanasi.",
    },
    {
      question: "How do I contact you for more information?",
      answer:
        "You can reach us at +91-837-782-8828 for any inquiries regarding our Varanasi Airport taxi service or bookings.",
    },
  ],
};
export const visakhapatnamTaxiData = {
  pageTitle: "Book Visakhapatnam Airport Cabs",
  mainHeading: "Book Visakhapatnam Airport Cabs on Sewak Travels  ",
  subHeading: "Enjoy smooth Airport Pickup & Drop Services in Visakhapatnam.  ",
  airportInfo:
    "Visakhapatnam Airport, also known as Visakhapatnam International Airport, is a key hub for both domestic and international travel in India. Established in 1981, this modern airport serves as a gateway to the bustling port city and its scenic beaches, historical sites, and vibrant culture. Located just 12 km from the city center, Visakhapatnam Airport provides easy access for tourists eager to explore the region. Our commitment to excellence ensures that you receive prompt and reliable service at every step. From the moment you arrive at Visakhapatnam Airport, our drivers will be ready to assist you with your luggage and ensure a smooth ride to your destination. We understand that travel can be stressful, which is why we offer flexible booking options and real-time tracking for your cab.  \n",
  serviceInfo:
    "At Sewak Travels, we make your travel experience smoother with our reliable Visakhapatnam Airport cab service. Choose Sewak Travels for a smooth airport transfer experience that allows you to focus on what truly matters—your journey and the experiences that await you in Visakhapatnam. Whether you are flying in for a quick visit or heading out, we ensure your journey to and from Visakhapatnam Airport is relaxed.  \n",
  distanceHeading: "Visakhapatnam to Visakhapatnam Airport Cab Distance  ",
  distanceInfo:
    "The distance from Visakhapatnam Airport to the city center is approximately 12 km, depending on the route and any stops made. For a comfortable and stress-free journey, simply book a Visakhapatnam Airport taxi through Sewak Travel's user-friendly online platform. Whether you are heading to or from the airport, we ensure an amazing experience, making your travel convenient and reliable.  \n",
  bookingHeading:
    "Online Visakhapatnam Airport Cab Booking with Sewak Travels  ",
  bookingInfo:
    "Welcome to Sewak Travels, where we offer reliable, comfortable, and on-time online Visakhapatnam Airport cab booking tailored to your needs. Whether you are arriving in or departing from Visakhapatnam, our Visakhapatnam Airport taxi service ensures a smooth travel experience from start to finish. With a fleet of modern vehicles and professional drivers, we provide the highest standard of airport cab services in Visakhapatnam.  \n",
  img: "/assets/img/LocationDifferent/Vishakhapatnam.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book a Visakhapatnam Cab?  ",
  whyChooseSubHeading:
    "Book your Visakhapatnam Cabs effortlessly: When you choose Sewak for your cab service in Visakhapatnam, you get:  ",
  whyChooseInfo: [
    {
      title: "- 24/7 Booking & Travel Assistance:",
      detail: "Our Visakhapatnam Airport taxi service operates 24/7",
    },
    {
      title: "- Outstation Cabs:",
      detail:
        "Need to travel outside Visakhapatnam? We offer outstation cabs from Visakhapatnam Airport",
    },
    {
      title: "- Competitive Rates:",
      detail: "With Visakhapatnam Airport taxi per km rates",
    },
    {
      title: "- Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi Visakhapatnam Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "- Professional Drivers:",
      detail:
        "Sewak's drivers are skilled professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "- Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title: "- Diverse Visakhapatnam Taxi Options:",
      detail: "When selecting our Visakhapatnam cab service for your journey",
    },
  ],
  howToBookHeading: "How to Book a Visakhapatnam Airport Taxi?  ",
  howToBookInfo:
    "Sewak Travels solves the issue of booking a Visakhapatnam Cab by offering premium services at affordable Visakhapatnam taxi rates, complete with a pickup-drop facility and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit https://sewaktravels.com/, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, and proceed to payment.  \n",
  taxiServicesHeading:
    "Various Taxi Services in Visakhapatnam Airport by Sewak Travels  ",
  taxiServices: [
    {
      title: "- Outstation Cabs from Visakhapatnam Airport:",
      description:
        "Sewak Travels offers reliable outstation cabs from Visakhapatnam Airport",
    },
    {
      title: "- Local Taxis from Visakhapatnam Airport:",
      description:
        "Need a ride within Visakhapatnam? We make it easy to book local taxis from Visakhapatnam Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "- Corporate Airport Transfers:",
      description:
        "We also cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "- Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "- Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "- Taxi Service in Visakhapatnam:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: "Easy Visakhapatnam Airport Cab Booking for Every Need  ",
  easyBookingInfo:
    "Whether you are visiting Visakhapatnam for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Visakhapatnam Airport taxi contact number and online platforms make it easy for you to book a cab within minutes.  \n",
  easyBookingPoints: [
    "Affordable Visakhapatnam Airport Taxi Fare",
    "Quick & Reliable Airport Taxi Service",
    "Car Rental Services for Visakhapatnam Airport",
  ],
  bookingCallToAction:
    "Book your Visakhapatnam Airport cab now with Sewak Travels and enjoy comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Visakhapatnam Airport taxi booking. Let Sewak Travels make your airport commute effortless!  ",
  thingsToKnowHeading: "Things To Know About Visakhapatnam  ",
  thingsToKnowInfo:
    "Visakhapatnam, often called the “City of Destiny,” is a major port city on the southeastern coast of India, known for its beautiful beaches, scenic landscapes, and rich cultural history. Famous for its ancient temples, British-era architecture, and vibrant festivals, Visakhapatnam is also home to iconic landmarks like the Kailasagiri Hill and Submarine Museum. With its culinary scene featuring fresh seafood, including dishes like fish curry and prawn fry, the city offers an unforgettable experience. Visakhapatnam is well-connected by road, rail, and air, making it an ideal destination for travelers eager to explore its unique blend of modernity and tradition.  \n",
  faqHeading:
    "Frequently Asked Questions About Visakhapatnam Airport Cab Booking  ",
  faqs: [
    {
      question: "How can I book a Visakhapatnam Airport cab?",
      answer:
        "You can book a Visakhapatnam Airport taxi easily by visiting our website sewaktravels.com, selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Visakhapatnam?",
      answer:
        "Sewak Travels provides intra-city cab transfers that allow users to book cabs to and from Visakhapatnam Airport in Visakhapatnam.",
    },
    {
      question:
        "What types of vehicles do you offer for Visakhapatnam Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Visakhapatnam Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Visakhapatnam Airport cabs?",
      answer:
        "Our Visakhapatnam Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Absolutely! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question:
        "Can I book a cab for outstation travel from Visakhapatnam Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Visakhapatnam Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with your Visakhapatnam cab bookings.",
    },
    {
      question: "Do you provide local sightseeing cabs in Visakhapatnam?",
      answer:
        "Yes, we offer local taxi services for sightseeing and exploring the beautiful attractions in Visakhapatnam.",
    },
    {
      question: "How do I contact you for more information?",
      answer:
        "You can reach us at +91-837-782-8828 for any inquiries regarding our Agra Airport taxi service or bookings.",
    },
  ],
};
export const puneTaxiData = {
  pageTitle: "Book Pune Airport Cabs",
  mainHeading: "Book Pune Airport Cabs on Sewak Travels  ",
  subHeading: "Enjoy smooth Airport Pickup & Drop Services in Pune.  ",
  airportInfo:
    "Pune Airport, also known as Pune International Airport, is a key hub for both domestic and international travel in India. Established in 1939, this bustling airport serves as a gateway to Pune's vibrant culture, educational institutions, IT hubs, and historical landmarks. Located approximately 10 km from the city center, Pune Airport provides easy access for travelers eager to explore this dynamic city. Our commitment to excellence ensures that you receive prompt and reliable service at every step. From the moment you arrive at Pune Airport, our drivers will be ready to assist you with your luggage and ensure a smooth ride to your destination. We understand that travel can be stressful, which is why we offer flexible booking options and real-time tracking for your cab.  \n",
  serviceInfo:
    "At Sewak Travels, we make your travel experience smoother with our reliable Pune Airport cab service. Choose Sewak Travels for a smooth airport transfer experience that allows you to focus on what truly matters—your journey and the experiences that await you in Pune. Whether you are flying in for a quick visit or heading out, we ensure your journey to and from Pune Airport is relaxed.  \n",
  distanceHeading: "Pune to Pune Airport Cab Distance  ",
  distanceInfo:
    "The distance from Pune Airport to the city center is approximately 10 km, depending on the route and any stops made. For a comfortable and stress-free journey, simply book a Pune Airport taxi through Sewak Travels' user-friendly online platform. Whether you are heading to or from the airport, we ensure an amazing experience, making your travel convenient and reliable.  Skip the wait and book your airport taxi in Pune directly with Sewak Travels for a seamless ride!\n",
  bookingHeading: "Online Pune Airport Cab Booking with Sewak Travels  ",
  bookingInfo:
    "Welcome to Sewak Travels, where we offer reliable, comfortable, and on-time online Pune Airport cab booking tailored to your needs. Whether you are arriving in or departing from Pune, our Pune Airport taxi service ensures a smooth travel experience from start to finish. With a fleet of modern vehicles and professional drivers, we provide the highest standard of airport cab services in Pune.  \n",
  img: "/assets/img/LocationDifferent/Pune.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book a Pune Cab? ",
  whyChooseSubHeading:
    "Book your Pune Cabs effortlessly: When you choose Sewak for your cab service in Pune, you get:  ",
  whyChooseInfo: [
    {
      title: "24/7 Booking & Travel Assistance:",
      detail: "Our Pune Airport taxi service operates 24/7",
    },
    {
      title: "- Outstation Cabs:",
      detail:
        "Need to travel outside Pune? We offer outstation cabs from Pune Airport",
    },
    {
      title: "- Competitive Rates:",
      detail: "With Pune Airport taxi per km rates",
    },
    {
      title: "- Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi at Pune Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "- Professional Drivers:",
      detail:
        "Sewak's drivers are skilled professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "- Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title: "- Diverse Pune Taxi Options:",
      detail: "When selecting our Pune cab service for your journey",
    },
  ],
  howToBookHeading: "How to Book a Pune Airport Taxi?  ",
  howToBookInfo:
    "Sewak Travels solves the issue of booking a Pune Cab by offering premium services at affordable Pune taxi rates, complete with a pickup-drop facility and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit https://sewaktravels.com/, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, and proceed to payment, and you're all set! Now that you're acquainted with our Agra taxi service, why wait any longer?\n",
  taxiServicesHeading:
    "Various Taxi Services in Pune Airport by Sewak Travels  ",
  taxiServices: [
    {
      title: "- Outstation Cabs from Pune Airport:",
      description:
        "Sewak Travels offers reliable outstation cabs from Pune Airport",
    },
    {
      title: "- Local Taxis from Pune Airport:",
      description:
        "Need a ride within Pune? We make it easy to book local taxis from Pune Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "- Corporate Airport Transfers:",
      description:
        "We also cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "- Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "- Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "- Taxi Service in Pune:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: "Easy Pune Airport Cab Booking for Every Need  ",
  easyBookingInfo:
    "Whether you are visiting Pune for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Pune Airport taxi contact number and online platforms make it easy for you to book a cab within minutes.  \n",
  easyBookingPoints: [
    "-  Affordable Pune Airport Taxi Fare",
    "- Quick & Reliable Airport Taxi Service",
    "- Car Rental Services for Pune Airport",
  ],
  bookingCallToAction:
    "Book your Pune Airport cab now with Sewak Travels and enjoy comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Pune Airport taxi booking. Let Sewak Travels make your airport commute effortless!  ",
  thingsToKnowHeading: "Things to Know About Pune  ",
  thingsToKnowInfo:
    "Pune, known as the “Oxford of the East,” is renowned for its educational institutions, IT hubs, and rich history. Famous for its forts, temples, and bustling markets, Pune offers a blend of modernity and tradition. The city’s culinary delights include iconic dishes like misal pav and bhakarwadi. Well-connected by road, rail, and air, Pune serves as an excellent gateway to the cultural and historical wonders of Maharashtra.  \n",
  faqHeading: " Frequently Asked Questions About Pune Airport Cab Booking  ",
  faqs: [
    {
      question: "How can I book a Pune Airport cab?",
      answer:
        "You can book a Pune Airport taxi easily by visiting our website sewaktravels.com, selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Pune?",
      answer:
        "Sewak Travels provides intra-city cab transfers that allow users to book cabs to and from Pune Airport in Pune.",
    },
    {
      question:
        "What types of vehicles do you offer for Pune Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Pune Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Pune Airport cabs?",
      answer:
        "Our Pune Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Absolutely! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question: "Can I book a cab for outstation travel from Pune Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Pune Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with your Pune cab bookings.",
    },
    {
      question: "Do you provide local sightseeing cabs in Pune?",
      answer:
        "Yes, we offer local taxi services for sightseeing and exploring the beautiful attractions in and around Pune.",
    },
    {
      question: "How do I contact you for more information?",
      answer:
        "You can reach us at +91-837-782-8828 for any inquiries regarding our Agra Airport taxi service or bookings.",
    },
  ],
};
export const shimlaTaxiData = {
  pageTitle: "Book Shimla Airport Cabs",
  mainHeading: "Book Shimla Airport Cabs on Sewak Travels",
  subHeading: " Enjoy smooth Airport Pickup & Drop Services in Shimla.",
  airportInfo:
    "Shimla Airport, also known as Jubbarhatti Airport, is a key hub for domestic travel in India. Established in 1987, this scenic airport serves as a gateway to the breathtaking landscapes, lush greenery, and charming colonial heritage of Shimla. Located approximately 22 km from the city center, Shimla Airport provides convenient access for tourists eager to explore this popular hill station. Our commitment to excellence ensures that you receive prompt and reliable service at every step. From the moment you arrive at Shimla Airport, our drivers will assist you with your luggage and ensure a smooth ride to your destination. We understand that travel can be stressful, which is why we offer flexible booking options and real-time tracking for your cab.\n",
  serviceInfo:
    "At Sewak Travels, we make your travel experience smoother with our reliable Shimla Airport cab service. Choose Sewak Travels for a seamless airport transfer experience that allows you to focus on what truly matters—your journey and the experiences that await you in Shimla. Whether you are flying in for a short getaway or heading out, we ensure your journey to and from Shimla Airport is comfortable and relaxed.\n",
  distanceHeading: "Shimla to Shimla Airport Cab Distance",
  distanceInfo:
    "\nThe distance from Shimla Airport to the city center is approximately 22 km, depending on the route and any stops made. For a comfortable and stress-free journey, simply book a Shimla Airport taxi through Sewak Travel's user-friendly online platform. Whether you are heading to or from the airport, we ensure an amazing experience, making your travel convenient and reliable.\n",
  bookingHeading: "Online Shimla Airport Cab Booking with Sewak Travels",
  bookingInfo:
    "\nWelcome to Sewak Travels, where we offer reliable, comfortable, and on-time online Shimla Airport cab booking tailored to your needs. Whether you are arriving in or departing from Shimla, our Shimla Airport taxi service ensures a smooth travel experience from start to finish. With a fleet of modern vehicles and professional drivers, we provide the highest standard of airport cab services in Shimla.\n",
  img: "/assets/img/LocationDifferent/Shimla.webp",
  whyChooseHeading: "Why Choose Sewak Travels to Book a Shimla Cab? ",
  whyChooseSubHeading:
    "Book your Shimla Cabs effortlessly: When you choose Sewak for your cab service in Shimla, you get:",
  whyChooseInfo: [
    {
      title: "24/7 Booking & Travel Assistance:",
      detail: "Our Shimla Airport taxi service operates 24/7",
    },
    {
      title: "Outstation Cabs:",
      detail:
        "Need to travel outside Shimla? We offer outstation cabs from Shimla Airport",
    },
    {
      title: "Competitive Rates:",
      detail: "With Shimla Airport taxi per km rates",
    },
    {
      title: "Prepaid and Hassle-Free:",
      detail:
        "Book your prepaid taxi Shimla Airport in advance or on arrival with flexible payment options.",
    },
    {
      title: "Professional Drivers:",
      detail:
        "Sewak’s drivers are skilled professionals dedicated to passenger safety and comfort. Carefully selected through a rigorous process",
    },
    {
      title: "Comfortable Fleet:",
      detail: "Choose from a range of vehicles to suit your travel preferences",
    },
    {
      title: "Diverse Shimla Taxi Options:",
      detail: "When selecting our Shimla cab service for your journey",
    },
  ],
  howToBookHeading: "How to Book a Shimla Airport Taxi?",
  howToBookInfo:
    "\nSewak Travels solves the issue of booking a Shimla Cab by offering premium services at affordable Shimla taxi rates, complete with a pickup-drop facility and prompt customer support. With just a click on Sewak Travels, everything you need is at your fingertips. Simply visit https://sewaktravels.com, click on the cabs icon, select your preference (Outstation, local or hourly, corporate, and airport cab service), choose your destination and date, select the car and rates according to your needs and budget, fill in the required details, and proceed to payment.\n",
  taxiServicesHeading:
    "Various Taxi Services in Shimla Airport by Sewak Travels",
  taxiServices: [
    {
      title: "Outstation Cabs from Shimla Airport:",
      description:
        "Sewak Travels offers reliable outstation cabs from Shimla Airport",
    },
    {
      title: "Local Taxis from Shimla Airport:",
      description:
        "Need a ride within Shimla? We make it easy to book local taxis from Shimla Airport with just a few clicks. Our local cab package offers great value",
    },
    {
      title: "Corporate Airport Transfers:",
      description:
        "We also cater to businesses with corporate airport transfer services and employee transport. Get in touch for a custom quote and enjoy reliable service for your company’s needs.",
    },
  ],
  highlights: [
    {
      title: "Airport Taxi at Per Km Rates:",
      detail: "With our transparent pricing model",
    },
    {
      title: "Prepaid Taxi Service:",
      detail: "To avoid any last-minute hassles",
    },
    {
      title: "Taxi Service in Shimla:",
      detail: "In addition to airport transfers",
    },
  ],
  easyBookingHeading: "Easy Shimla Airport Cab Booking for Every Need",
  easyBookingInfo:
    "\nWhether you are visiting Shimla for business, leisure, or heading for an outstation trip, Sewak Travels offers you the most convenient and reliable cab booking options. Our Shimla Airport taxi contact number and online platforms make it easy for you to book a cab within minutes.\n",
  easyBookingPoints: [
    "Affordable Shimla Airport Taxi Fare\nQuick & Reliable Airport Taxi Service\nCar Rental Services for Shimla Airport",
  ],
  bookingCallToAction:
    "Book your Shimla Airport cab now with Sewak Travels and enjoy comfortable rides at affordable prices. Call us at +91-837-782-8828 or visit our website for quick and easy Shimla Airport taxi booking. Let Sewak Travels make your airport commute effortless!",
  thingsToKnowHeading: "Things To Know About Shimla",
  thingsToKnowInfo:
    "\nShimla, the capital of Himachal Pradesh, is known for its colonial architecture, lush greenery, and snow-capped mountains. Famous for attractions like The Ridge, Mall Road, and Jakhoo Temple, Shimla also boasts serene trekking trails and vibrant bazaars. The region’s culinary delights, such as Channa Madra and Sidu, are a treat for food enthusiasts. Well-connected by road, rail, and air, Shimla is a top choice for travelers seeking to immerse themselves in natural beauty and a rich cultural experience.\n",
  faqHeading: "Frequently Asked Questions About Shimla Airport Cab Booking",
  faqs: [
    {
      question: "How can I book a Shimla Airport cab?",
      answer:
        "You can book a Shimla Airport taxi easily by visiting our website sewaktravels.com, selecting the cab option, choosing your preferences, and proceeding with the booking.",
    },
    {
      question:
        "What kind of airport cab services does Sewak Travels provide in Shimla?",
      answer:
        "Sewak Travels provides intra-city cab transfers that allow users to book cabs to and from Shimla Airport in Shimla.",
    },
    {
      question:
        "What types of vehicles do you offer for Shimla Airport cab services?",
      answer:
        "We provide various options, including 4-seater sedans, 7-seater SUVs, and luxury vehicles, ensuring a comfortable ride for all group sizes.",
    },
    {
      question: "Is there a prepaid taxi option available?",
      answer:
        "Yes, we offer a prepaid taxi service at Shimla Airport, allowing you to secure your ride in advance without any last-minute hassles.",
    },
    {
      question: "What are the rates for Shimla Airport cabs?",
      answer:
        "Our Shimla Airport taxi fare is competitive and based on a per km rate, ensuring transparency with no hidden charges.",
    },
    {
      question: "Are your drivers experienced?",
      answer:
        "Absolutely! All our drivers are professional and experienced, ensuring your safety and comfort throughout your journey.",
    },
    {
      question: "Can I book a cab for outstation travel from Shimla Airport?",
      answer:
        "Yes, we provide reliable outstation cabs from Shimla Airport for your convenience.",
    },
    {
      question: "What are your customer support hours?",
      answer:
        "Our customer support is available 24/7 to assist you with your Shimla cab bookings.",
    },
    {
      question: "Do you provide local sightseeing cabs in Shimla?",
      answer:
        "Yes, we offer local taxi services for sightseeing and exploring the beautiful attractions in and around Shimla.",
    },
    {
      question: "What safety measures are in place for your cab services?",
      answer:
        "We prioritize passenger safety by following standard sanitization protocols, ensuring our vehicles are clean and safe for travel.",
    },
  ],
};
